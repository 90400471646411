import React from 'react'
import { apiErrorToMessage } from 'modules/api/functions'
import { getConfiguration } from 'modules/api'
import { useCallApi } from 'modules/common/hooks'
import { uniqueToastError } from 'modules/common/functions'

interface Props {
	surveyId: string
	accountId: string
}

const DownloadAnonymisedReport: React.FC<Props> = (props) => {
	const { accountId, surveyId } = props
	const callApi = useCallApi()

	function doDownload(evt: React.MouseEvent) {
		evt.preventDefault()
		
		async function doDownload() {
			const tokenResponse = await callApi(api => api.accountApi.requestAnonymisedSurveyReportDownload(accountId, surveyId))
			const token = tokenResponse.token
			const frontend = `${window.location.protocol}//${window.location.host}`
			const url = `${getConfiguration().basePath}/account/${accountId}/survey/${surveyId}/report/download?token=${encodeURIComponent(token)}&frontend=${encodeURIComponent(frontend)}`
			window.location.assign(url)
		}

		doDownload().catch(error => {
			uniqueToastError(`Couldn’t download data.\n\n${apiErrorToMessage(error)}`)
		})
	}

	return (
		<div className="download-report">
			<h4 className="subheading-text -small">Download anonymised report data</h4>
			<div className="form-field">
				<button className="icon-text -download" onClick={doDownload}>Download XLS</button>
			</div>
		</div>
	)
}

export default DownloadAnonymisedReport
