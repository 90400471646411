import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import Header from 'modules/common/components/Header'
import Footer from 'modules/common/components/Footer'
import Debug from 'modules/common/components/Debug'

export interface Props {
	title: string
	class?: string
	additionalClass?: string
	includePageHeader?: boolean
	type?: string
	avatar?: string
	backPath?: string
	currentQuestionId?: string
	additionalHeader?: React.ReactNode
	breadcrumbLink?: string
	breadcrumbText?: string
}

const Screen: React.FC<Props> = (props) => {
	return (
		<>
			<Helmet>
				<title>{props.title} - Umbrella</title>
			</Helmet>
			<Header breadcrumbLink={props.breadcrumbLink} breadcrumbText={props.breadcrumbText}/>
			{props.additionalHeader}
			<article className="site-body">
				<div className={'width-limit ' + (props.class ? props.class : '-content') + (props.additionalClass ? (' ' + props.additionalClass) : '')}>
					{props.children}
				</div>
			</article>
			<Footer />
			<Debug params={window.location.search}/>
		</>
	)
}

export default Screen
