import { initApiConfiguration } from 'modules/api'
import { createStore, compose, applyMiddleware, StoreEnhancer, Middleware, Store } from 'redux'
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction'
import createSagaMiddleware from 'redux-saga'
import { offline } from '@redux-offline/redux-offline'
import { Config } from '@redux-offline/redux-offline/lib/types'
import defaultOfflineConfig from '@redux-offline/redux-offline/lib/defaults'

import rootSaga from './sagas'
import { setAuthConfig } from 'modules/auth'
import platform from 'modules/platform'

import { readyAction, storeReadyAction } from './actions'
import { reducer } from './reducer'

/* API handling */
import { handleDiscard, handleEffect } from 'modules/api/offline'
import { RootStoreState } from './'
import { logoutRequest, LoginIntent } from 'modules/auth/actions'
import { apiErrorToMessage } from 'modules/api/functions'
import { programmaticLogin } from 'modules/auth/actions'
import { Capacitor } from '@capacitor/core'

const PERSIST_KEY_PREFIX = 'umbrella'

export async function init(): Promise<Store<RootStoreState>> {
	/**
	 * Create the redux-saga middleware.
	 */
	const sagaMiddleware = createSagaMiddleware({
		onError: function(error) {
			console.error('Saga error', error)
			alert(`An unexpected error has ocurred, please reload the window.\n${apiErrorToMessage(error)}`)
		},
	})

	let store: Store<RootStoreState> | undefined = undefined

	/**
	 * Create the redux-offline configuration, based on the default configuration.
	 */
	const offlineConfig: Config = {
		...defaultOfflineConfig,

		/**
		 * This callback occurs after redux-persist has rehydrated our Redux state.
		 */
		persistCallback: () => {
			/* Let our app know that the store has been rehydrated and is ready to be used */
			store?.dispatch(storeReadyAction())
			
			/* Access token login hack for printing */
			if (window.location.search && window.location.search.indexOf('at=') !== -1) {
				const i = window.location.search.indexOf('at=')
				let at = window.location.search.substring(i + 3)
				const j = at.indexOf('&')
				if (j !== -1) {
					at = at.substring(0, j)
				}

				store?.dispatch(logoutRequest({}))

				store?.dispatch(programmaticLogin({
					accessToken: {
						access_token: at,
						expires_in: 60,
						refresh_token: undefined,
						token_type: 'Bearer',
					},
					intent: LoginIntent.PRINT,
					rememberMe: false,
				}))
			}

			/* Let our app know that everything is ready */
			store?.dispatch(readyAction())
		},

		persistOptions: {
			/* Don't persist the ready key, as we want to always set it when we dispatch the readyAction */
			whitelist: Capacitor.isNativePlatform() ? ['admin', 'participant'] : ['auth', 'admin', 'participant'],
			/**
			 * We use a blacklist as well to ensure that content that must not be persisted is not used,
			 * such as if whitelist settings are changed during development.
			 */
			blacklist: Capacitor.isNativePlatform() ? ['ready', 'router', 'auth'] : ['ready', 'router'],
			keyPrefix: PERSIST_KEY_PREFIX,
		},

		/**
		 * This function is used to handle actions tagged for redux-offline to handle.
		 */
		effect: (effect, action) => handleEffect(effect, action, store!),

		/**
		 * This function determines whether to discard a request, or to retry in, in the event
		 * of an error.
		 */
		discard: handleDiscard,

		/* The commented out function below hard-codes the app to function as if it is offline. */
		// detectNetwork: function(callback: (online: boolean) => void) {
		// 	callback(false)
		// },
	}

	const middlewares: Middleware[] = [sagaMiddleware]

	/**
	 * Enhancers for the store.
	 */
	const enhancers = compose(
		/* Add the redux-offline store enhancer */
		offline(offlineConfig),
		/* Add the middlewares */
		applyMiddleware(...middlewares),
		/* Include the devtools. Comment this out if you don't want to use the dev tools. */
		devToolsEnhancer({}),
	) as StoreEnhancer<RootStoreState>

	/**
	 * Create the store. We do not include an initial state, as each of the module / duck
	 * reducers includes its own initial state.
	 */
	store = createStore(reducer, enhancers)

	/* Init the platform */
	platform.init(store)

	/* Init the API */
	initApiConfiguration(platform.createApiConfigurationParams())

	/* Create the authentication config */
	setAuthConfig(platform.createAuthConfiguration())

	/* Run the root saga */
	sagaMiddleware.run(rootSaga, store)
	return store
}
