import { toast, ToastOptions } from 'react-toastify'

export function getDegreesFromScore(score: number): number {
	if (score < 0.0 || score > 1.0) {
		throw new Error(`Invalid score: ${score}`)
	}
	return 180 * score - 90
}

export function uniqueToastError(message: string) {
	toast.error(message, { toastId: message })
}

export function uniqueToastSuccess(message: string) {
	toast.success(message, { toastId: message })
}

export function uniqueToastWarn(message: string) {
	toast.warn(message, { toastId: message })
}

export function uniqueToast(message: string, options: ToastOptions) {
	toast(message, options)
}
