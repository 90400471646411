import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from './actions'
import * as auth from 'modules/auth/actions'
import produce from 'immer'
import { Api } from 'typescript-fetch-api'
import { CurrentMembership } from './types'

/** The store state for the admin module. */
export type StoreState = DeepReadonly<MutableStoreState>

interface MutableStoreState {
	personDetails?: Api.PersonDetails
	currentMembership?: CurrentMembership
	/** Whether the admin membership details are currently loading */
	loading: boolean
}

const INITIAL_STATE: StoreState = {
	loading: false,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(actions.setPersonDetails, (state, payload) => produce(state, draft => {
	draft.personDetails = payload
}))

reducer.case(actions.setCurrentMembership, (state, payload) => produce(state, draft => {
	draft.currentMembership = payload
}))

/* The user has been logged out remove our stored access token from the state. */
reducer.case(auth.loggedOut, (state) => produce(state, draft => {
	return {
		...INITIAL_STATE,
	}
}))

reducer.case(actions.adminLoading, (state, payload) => produce(state, draft => {
	draft.loading = payload
}))
