import * as s from './selectors'
import * as t from './types'
import { capitalCase } from 'change-case'

import { refreshAuthentication } from 'modules/auth/functions'
import api, { ApiCollection } from './'
import { Store } from 'redux'
import { RootStoreState } from 'modules/root'

export type CallApiFunction<R> = (api: ApiCollection) => Promise<R>

export async function callApi<R>(func: CallApiFunction<R>, store: Store<RootStoreState>): Promise<R> {
	return await _callApi(func, store, true)
}

async function _callApi<R>(func: (api: ApiCollection) => Promise<R>, store: Store<RootStoreState>, retry: boolean): Promise<R> {
	/* Check if there are any offline changes queued, and don't allow GET requests through, as we
	   might load information that would replace our pending changes in the state, such as resetting
	   a job state back to the server state, before we've had a chance to POST the change.
	 */
	const queueLength = s.selectOfflineOutboxQueueLength(store.getState())
	if (queueLength > 0) {
		throw new Error('Offline changes are queued')
	}

	try {
		return await func(api())
	} catch (error) {
		if (error instanceof Response) {
			/* Check for an authorisation failed and try to refresh our access token */
			if (error.status === 401) {
				if (retry) {
					await refreshAuthentication(store)
					return await _callApi(func, store, false)
				}
			}

			if (error.status >= 200 && error.status < 500) {
				let errorResponse
				try {
					const contentType = error.headers.get('Content-Type')
					if (contentType && contentType.startsWith('application/json')) {
						/* We try to parse the server response as JSON, which will contain information that our code can use */
						errorResponse = await error.json()
					} else if (contentType && contentType.startsWith('text/plain')) {
						/* A built-in validation error */
						errorResponse = await error.text()
					} else {
						errorResponse = new Error(`Unexpected response from server (${error.status}) with content type: ${contentType}`)
					}
				} catch {
					/* We couldn't parse the response as JSON so we report a generic error */
					switch (error.status) {
						case 403:
							throw new Error('Forbidden')
						case 404:
							throw new Error('Not Found')
					}
					throw new Error(`Unexpected response from server (${error.status})`)
				}

				throw errorResponse
			} else {
				throw new Error(`Unexpected response from server (${error.status})`)
			}
		} else if (error instanceof Error) {
			throw error
		} else {
			/* Unknown error type, let's just throw it on */
			throw error
		}
	}
}

/** Takes an API error and returns a message to be displayed to the user. */
export function apiErrorToMessage(error: unknown): string {
	return apiErrorToMessages(error as t.ApiError).join('\n\n')
}

export function isApiErrorWithMessages(ob: unknown): ob is t.ApiErrorWithMessages {
	const api: t.ApiErrorWithMessages = ob as t.ApiErrorWithMessages
	if (typeof api === 'object' && api.messages) {
		return true
	} else {
		return false
	}
}

export function apiErrorToMessages(error: unknown): string[] {
	if (error instanceof Error) {
		return [error.message]
	} else if (isApiErrorWithMessages(error)) {
		return error.messages.map(m => {
			if (m.property) {
				return `${capitalCase(m.property)}: ${m.message}`
			} else {
				return m.message
			}
		})
	} else if (typeof error === 'string') {
		return error.split('\n')
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} else if ((error as any).error) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return [(error as any).error]
	} else {
		return [JSON.stringify(error)]
	}
}
