import * as React from 'react'
import Screen from 'modules/common/components/Screen'
import { useCurrentPerson } from 'modules/admin/hooks'
import { useCallApi } from 'modules/common/hooks'
import { useController, Formalities } from 'formalities'
import { Api } from 'typescript-fetch-api'
import { apiErrorToMessage } from 'modules/api/functions'
import { setPersonDetails } from 'modules/admin/actions'
import { useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'

const YourProfile: React.FC = function() {
	const person = useCurrentPerson()
	const callApi = useCallApi()
	const controller = useController<Api.UpdateMyDetailsRequest>({
		givenName: person.givenName,
		familyName: person.familyName,
	})
	const dispatch = useDispatch()
	const history = useHistory()
	const { url } = useRouteMatch()

	async function onUpdateProfile(evt: React.FormEvent) {
		evt.preventDefault()

		const request = { ...controller.snapshot().value }
		if (request.givenName === person.givenName) {
			delete request.givenName
		}
		if (request.familyName === person.familyName) {
			delete request.familyName
		}
		
		try {
			const updatedPerson = await callApi(api => api.personApi.updateMyDetails(request))
			dispatch(setPersonDetails(updatedPerson))

			if (request.newPassword) {
				uniqueToastSuccess('Your changes has been saved. Please login again with your new password.')
				history.push('/')
			} else {
				uniqueToastSuccess('Your changes have been saved.')
			}
		} catch (error) {
			uniqueToastError(`Unable to save changes.\n${apiErrorToMessage(error)}`)
		}
	}

	function setup2fA(evt: React.FormEvent) {
		evt.preventDefault()
		history.push(`${url}/setup-2fa`)
	}

	return (
		<Screen title="Your profile" class="-narrow" includePageHeader={false}>
			<div className="content-header">
				{person.name ? (
					<h1 className="headline-text">{person.name}'s profile</h1>
				) : (
					<h1 className="headline-text">Your profile</h1>
				)}
			</div>

			<form>
				<div className="form-field -adjacent">
					<label className="label">Given name</label>
					<div className="form-input -text">
						<Formalities.Text className="field" controller={controller} prop="givenName" />
					</div>
				</div>
				<div className="form-field -adjacent">
					<label className="label">Family name</label>
					<div className="form-input -text">
						<Formalities.Text className="field" controller={controller} prop="familyName" />
					</div>
				</div>
				<div className="form-field -adjacent">
					<label className="label">Email address</label>
					<div className="form-input -textonly">
						<p>{person.emailAddress}</p>
					</div>
				</div>
				<>
					<div className="form-field -text">
						<h2 className="subheading-text">Change password</h2>
						<p className="body-text">You can change your Umbrella account password by entering your current password, and then entering a new password.</p>
					</div>
					<div className="form-field -adjacent">
						<label className="label">Current Password</label>
						<div className="form-input -text">
							<Formalities.Text className="field" type="password" controller={controller} prop="currentPassword" autoComplete="current-password" />
						</div>
					</div>
					<div className="form-field -adjacent">
						<label className="label">New Password</label>
						<div className="form-input -text">
							<Formalities.Text className="field" type="password" controller={controller} prop="newPassword" autoComplete="new-password" />
						</div>
					</div>
					<div className="form-field -adjacent">
						<label className="label">Confirm Password</label>
						<div className="form-input -text">
							<Formalities.Text className="field" type="password" controller={controller} prop="confirmPassword" autoComplete="new-password" />
						</div>
					</div>
					<div className="form-field -adjacent -guttered two-factor">
						<h2 className="subheading-text">2 Factor Authentication</h2>
						{
							person.totp ?
								<p>You have 2FA enabled.</p> : 
								<div className="button-group -right">
									<input type="submit" className="button-link -secondary" value="Setup 2FA on your Umbrella account" onClick={setup2fA}/>
								</div>
						}
					</div>
				</>
				<div className="footer-actions">
					<div className="button-group -right">
						<input type="submit" className="button-link -action" value="Save changes" onClick={onUpdateProfile}/>
					</div>
				</div>
			</form>
		</Screen>
	)
}

export default YourProfile
