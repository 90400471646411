import * as React from 'react'
import Screen from 'modules/common/components/Screen'
import { Api } from 'typescript-fetch-api'
import { useController, Formalities } from 'formalities'
import DatePicker from 'modules/common/components/DatePicker'
import TimePicker from 'modules/common/components/TimePicker'
import { Link } from 'react-router-dom'
import { pathToSurveyParticipants, pathToSurveyEditSection, pathToSurvey } from '../navigation'
import { useCallApi } from 'modules/common/hooks'
import { useCurrentPerson } from 'modules/admin/hooks'
import moment from 'moment'
import { apiErrorToMessage } from 'modules/api/functions'
import MyReactQuill from 'modules/common/components/MyReactQuill'
import ReactMarkdown from 'react-markdown'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'

interface Props {
	survey: Api.SurveyDetails
	onChangeSurvey: () => void
}

interface FormScheduling {
	openAtDate?: string
	openAtTime?: string
	closeAtDate?: string
	closeAtTime?: string
}

interface FormDefaults {
	defaultWelcome: boolean
	defaultInvitation: boolean
	defaultThanks: boolean
	defaultThanksContact: boolean
	defaultRiskThanks: boolean
	defaultPrivacyStatement: boolean
	defaultSupportArticle: boolean
}

const EditSurvey: React.FC<Props> = function(props) {
	const { survey } = props
	const [surveyChanged, setSurveyChanged] = React.useState(false)
	const schedulingController = useController<FormScheduling>({
		openAtDate: survey.scheduling?.openAt,
		openAtTime: survey.scheduling?.openAt,
		closeAtDate: survey.scheduling?.closeAt,
		closeAtTime: survey.scheduling?.closeAt,
	})
	const person = useCurrentPerson()
	const controller = useController(survey)
	const callApi = useCallApi()

	const welcomeSnapshot = controller.snapshot('welcome')
	const invitationMessageSnapshot = controller.snapshot('invitationMessage')
	const thanksMessageSnapshot = controller.snapshot('thanksMessage')
	const thanksContactMessageSnapshot = controller.snapshot('thanksContactMessage')
	const riskThanksMessageSnapshot = controller.snapshot('riskThanksMessage')
	const privacyStatementSnapshot = controller.snapshot('privacyStatement')
	const supportArticleSnapshot = controller.snapshot('supportArticle')

	const testEmailsController = useController<Api.SurveyDetailsUpdate.TestEmails>({
		invitation: false,
		riskThanks: false,
		thanks: false,
	})

	const defaultsController = useController<FormDefaults>({
		defaultWelcome: !welcomeSnapshot.value,
		defaultInvitation: !invitationMessageSnapshot.value,
		defaultThanks: !thanksMessageSnapshot.value,
		defaultThanksContact: !thanksContactMessageSnapshot.value,
		defaultRiskThanks: !riskThanksMessageSnapshot.value,
		defaultPrivacyStatement: !privacyStatementSnapshot.value,
		defaultSupportArticle: !supportArticleSnapshot.value,
	})

	defaultsController.addChangeListener(surveyHasChanged)
	testEmailsController.addChangeListener(surveyHasChanged)
	schedulingController.addChangeListener(surveyHasChanged)
	controller.addChangeListener(surveyHasChanged)

	function surveyHasChanged() {
		setSurveyChanged(true)
	}

	async function saveSurvey(evt: React.FormEvent) {
		evt.preventDefault()
		const form = controller.snapshot().value
		const scheduling = schedulingController.snapshot().value

		const schedulingValues: Api.Scheduling = {}

		if (scheduling.openAtDate && survey.status !== Api.SurveySummary.StatusEnum.OPEN) {
			if (scheduling.openAtTime) {
				schedulingValues.openAt = moment(moment(scheduling.openAtDate).format('YYYY-MM-DD') + moment(scheduling.openAtTime).format('HH:mm'), 'YYYY-MM-DDHH:mm').toISOString()
			} else {
				schedulingValues.openAt = moment(moment(scheduling.openAtDate).format('YYYY-MM-DD'), 'YYYY-MM-DD').toISOString()
			}
		}

		if (scheduling.closeAtDate) {
			if (scheduling.closeAtTime) {
				schedulingValues.closeAt = moment(moment(scheduling.closeAtDate).format('YYYY-MM-DD') + moment(scheduling.closeAtTime).format('HH:mm'), 'YYYY-MM-DDHH:mm').toISOString()
			} else {
				schedulingValues.closeAt = moment(moment(scheduling.closeAtDate).format('YYYY-MM-DD'), 'YYYY-MM-DD').toISOString()
			}
		}

		const defaults = defaultsController.snapshot().value
			
		try {
			await callApi(api => api.surveyApi.patchSurvey(survey.id, {
				name: form.name || undefined,
				scheduling: schedulingValues,
				contactName: form.contactName || undefined,
				contactEmail: form.contactEmail || undefined,
				invitationMessage: defaults.defaultInvitation ? null : form.invitationMessage || undefined,
				welcome: defaults.defaultWelcome ? null : form.welcome || undefined,
				thanksMessage: defaults.defaultThanks ? null : form.thanksMessage || undefined,
				thanksContactMessage: defaults.defaultThanksContact ? null : form.thanksContactMessage || undefined,
				riskThanksMessage: defaults.defaultRiskThanks ? null : form.riskThanksMessage || undefined,
				privacyStatement: defaults.defaultPrivacyStatement ? null : form.privacyStatement || undefined,
				supportArticle: defaults.defaultSupportArticle ? null : form.supportArticle || undefined,
				testEmails: testEmailsController.snapshot().value,
			}))
			uniqueToastSuccess('Updated survey')

			props.onChangeSurvey()
		} catch (error) {
			uniqueToastError(`Failed to update survey.\n${apiErrorToMessage(error)}`)
		}
	}

	const defaults = defaultsController.snapshot().value

	return (
		<Screen title="Edit survey" includePageHeader={false} breadcrumbText="view survey" breadcrumbLink={pathToSurvey(survey)}>
			<div className="content-header">
				<h1 className="headline-text">Edit survey</h1>
			</div>
			<div className="content-cols">
				<div className="body">
					<form>
						<div className="form-field -adjacent">
							<label className="label">Survey name</label>
							<div className="form-input -text"><Formalities.Text controller={controller} prop="name" className="field" /></div>
						</div>
						{
							survey.template &&
							<div className="form-field -adjacent">
								<label className="label">Survey type</label>
								<div className="form-input -text"><input type="text" className="field" value={survey.template.name} disabled/></div>
							</div>
						}
						{
							survey.editableSections.length > 0 &&
							<div className="form-row">
								<h2 className="label">Editable sections</h2>
								<div className="fields">
									{
										survey.editableSections.map((section, index) =>
											<div key={index} className="editable-section">
												<h3 className="ui-heading -dark">{section.title}</h3>
												<div className="questions">
													<div className="questions">
														{
															section.questions.length > 0 &&
																<ul className="list">
																	{
																		section.questions.map((question, index) => (
																			<li key={index}><ReactMarkdown children={question.title} /></li>
																		))
																	}
																</ul>
														}
														{
															survey.status !== Api.SurveySummary.StatusEnum.OPEN &&
															<Link to={pathToSurveyEditSection(survey, section.id)} className="button-link -small -secondary">Edit questions</Link>
														}
													</div>
												</div>
											</div>,
										)
									}
									<p className="note">Editable sections are unable to be changed once the survey is open.</p>
								</div>
							</div>
						}

						<div className="form-row">
							<h2 className="label">Participants</h2>
							<div className="fields">
								<div className="participant-summary body-text">
									{survey.participants.totalCount > 0 ?
										<>
											<p className="total"><Link to={pathToSurveyParticipants(survey)}>{survey.participants.totalCount} {survey.participants.totalCount > 1 ? 'people' : 'person'}</Link></p>
											<Link to={pathToSurveyParticipants(survey)} className="button-link -small -secondary">Add or Manage</Link> 
										</> :
										<Link to={pathToSurveyParticipants(survey)} className="button-link -small -secondary">Add participants</Link>
									}
								</div>
								<div className="description body-text">
									<p><strong className="inlinetitle">Note:</strong> Once saved, you will also have the option of sharing a link to the survey in addition to any participants added above.</p>
								</div>
							</div>
						</div>
						
						<div className="form-row">
							<h2 className="label -padtop">Scheduling</h2>
							<div className="fields">
								{
									survey.status === Api.SurveySummary.StatusEnum.OPEN ? 
										survey.scheduling?.openAt &&
										<div className="schedule-date">
											<label className="label">Start on</label>
											<DatePicker controller={schedulingController} prop="openAtDate" disabled/>
											{schedulingController.snapshot().value.openAtDate && <TimePicker controller={schedulingController} prop="openAtTime" disabled/>}
											<p className="note">(optional)</p>
										</div> :
										<div className="schedule-date">
											<label className="label">Start on</label>
											<DatePicker controller={schedulingController} prop="openAtDate"/>
											{schedulingController.snapshot().value.openAtDate && <TimePicker controller={schedulingController} prop="openAtTime"/>}
											<p className="note">(optional)</p>
										</div>
								}
								
								<div className="schedule-date">
									<label className="label">End on</label>
									<DatePicker controller={schedulingController} prop="closeAtDate" startDate={schedulingController.snapshot().value.openAtDate}/>
									{schedulingController.snapshot().value.closeAtDate && <TimePicker controller={schedulingController} prop="closeAtTime"/>}
									<p className="note">(optional)</p>
								</div>
							</div>
						</div>

						<div className="form-row">
							<h2 className="label -padtop">Contact</h2>
							<div className="fields">
								<div className="adjacent">
									<label className="label">Name</label>
									<div className="form-input -text"><Formalities.Text controller={controller} prop="contactName" className="field" /></div>
								</div>
								<div className="adjacent">
									<label className="label">Email</label>
									<div className="form-input -text"><Formalities.Text controller={controller} prop="contactEmail" className="field" placeholder="hello@example.com" /></div>
								</div>
								{person.superAdmin && (
									<>
										<h2 className="label -subheading">High Psychological Distress Score Notification</h2>
										<div className="adjacent">
											<label className="label">Email</label>
											<div className="form-input -text"><Formalities.Text controller={controller} prop="riskContactEmail" className="field" placeholder={survey.defaultRiskContactEmail || 'hello@example.com'} /></div>
										</div>
										<p className="note">The email address to which notifications of high psychological distress scores will be sent. Only the super administrator can see or set this email address.</p>
									</>
								)}
							</div>
						</div>

						<div className="form-field -adjacent">
							<label className="label">Welcome message</label>
							<div className="form-input -text">
								<ul className="option-inputs -small">
									<li className="option -checkbox">
										<label className="label">
											<Formalities.Checkable type="checkbox" className="checkbox" controller={defaultsController} prop="defaultWelcome" checkedValue={true} /><span className="substitute"></span>
											Use default
										</label>
									</li>
								</ul>
								<MyReactQuill
									theme="bubble" 
									className="field body-text"
									readOnly={defaults.defaultWelcome}
									value={defaults.defaultWelcome ? survey.defaultWelcome || '' : welcomeSnapshot.value || survey.defaultWelcome || ''}
									onChange={(content, delta, sources, editor) => {
										if (!defaults.defaultWelcome) {
											welcomeSnapshot.setValue(editor.getHTML())
										}
									}} />
								<p className="note">The welcome message will be shown to your participants before they start the survey.</p>
							</div>
						</div>

						<div className="form-field -adjacent">
							<label className="label">Invitation message</label>
							<div className="form-input -text">
								<ul className="option-inputs -small">
									<li className="option -checkbox">
										<label className="label">
											<Formalities.Checkable type="checkbox" className="checkbox" controller={defaultsController} prop="defaultInvitation" checkedValue={true} /><span className="substitute"></span>
											Use default
										</label>
									</li>
								</ul>
								<MyReactQuill
									theme="bubble" 
									className="field body-text"
									readOnly={defaults.defaultInvitation}
									value={defaults.defaultInvitation ? survey.defaultInvitationMessage || '' : invitationMessageSnapshot.value || survey.defaultInvitationMessage || ''}
									onChange={(content, delta, sources, editor) => {
										if (!defaults.defaultInvitation) {
											invitationMessageSnapshot.setValue(editor.getHTML())
										}
									}} />
								<p className="note">The invitation message will be included in the invitation email for this survey.</p>
								<ul className="option-inputs -small">
									<li className="option -checkbox">
										<label className="label">
											<Formalities.Checkable type="checkbox" className="checkbox" controller={testEmailsController} prop="invitation" checkedValue={true} /><span className="substitute"></span>
											Send me a test email
										</label>
									</li>
								</ul>
							</div>
						</div>

						<div className="form-field -adjacent">
							<label className="label">Thanks message</label>
							<div className="form-input -text">
								<ul className="option-inputs -small">
									<li className="option -checkbox">
										<label className="label">
											<Formalities.Checkable type="checkbox" className="checkbox" controller={defaultsController} prop="defaultThanks" checkedValue={true} /><span className="substitute"></span>
											Use default
										</label>
									</li>
								</ul>
								<MyReactQuill
									theme="bubble" 
									className="field body-text"
									readOnly={defaults.defaultThanks}
									value={defaults.defaultThanks ? survey.defaultThanksMessage || '' : thanksMessageSnapshot.value || survey.defaultThanksMessage || ''}
									onChange={(content, delta, sources, editor) => {
										if (!defaults.defaultThanks) {
											thanksMessageSnapshot.setValue(editor.getHTML())
										}
									}} />
								<p className="note">The thanks message will be sent to participants when they complete the survey.</p>
								<ul className="option-inputs -small">
									<li className="option -checkbox">
										<label className="label">
											<Formalities.Checkable className="checkbox" type="checkbox" controller={testEmailsController} prop="thanks" checkedValue={true} /><span className="substitute"></span>
											Send me a test email
										</label>
									</li>
								</ul>
							</div>
						</div>

						<div className="form-field -adjacent">
							<label className="label">Thanks questions contact message</label>
							<div className="form-input -text">
								<ul className="option-inputs -small">
									<li className="option -checkbox">
										<label className="label">
											<Formalities.Checkable type="checkbox" className="checkbox" controller={defaultsController} prop="defaultThanksContact" checkedValue={true} /><span className="substitute"></span>
											Use default
										</label>
									</li>
								</ul>
								<MyReactQuill
									theme="bubble" 
									className="field body-text"
									readOnly={defaults.defaultThanksContact}
									value={defaults.defaultThanksContact ? survey.defaultThanksContactMessage || '' : thanksContactMessageSnapshot.value || survey.defaultThanksContactMessage || ''}
									onChange={(content, delta, sources, editor) => {
										if (!defaults.defaultThanksContact) {
											thanksContactMessageSnapshot.setValue(editor.getHTML())
										}
									}} />
								<p className="note">The thanks questions contact message appears at the end of the thanks email to provide information to participants about who they should contact if they have questions.</p>
							</div>
						</div>

						<div className="form-field -adjacent">
							<label className="label">Thanks message for high psychological distress score participants</label>
							<div className="form-input -text">
								<ul className="option-inputs -small">
									<li className="option -checkbox">
										<label className="label">
											<Formalities.Checkable type="checkbox" className="checkbox" controller={defaultsController} prop="defaultRiskThanks" checkedValue={true} /><span className="substitute"></span>
											Use default
										</label>
									</li>
								</ul>
								<MyReactQuill
									theme="bubble"
									className="field body-text"
									readOnly={defaults.defaultRiskThanks}
									value={defaults.defaultRiskThanks ? survey.defaultRiskThanksMessage || '' : riskThanksMessageSnapshot.value || survey.defaultRiskThanksMessage || ''}
									onChange={(content, delta, sources, editor) => {
										if (!defaults.defaultRiskThanks) {
											riskThanksMessageSnapshot.setValue(editor.getHTML())
										}
									}} />
								<p className="note">The thanks message for high psychological distress score participants will be sent to participants whose answers to the survey yield a high psychological distress score.</p>
								<ul className="option-inputs -small">
									<li className="option -checkbox">
										<label className="label">
											<Formalities.Checkable className="checkbox" type="checkbox" controller={testEmailsController} prop="riskThanks" checkedValue={true} /><span className="substitute"></span>
											Send me a test email
										</label>
									</li>
								</ul>
							</div>
						</div>

						{person.superAdmin && (
							<div className="form-field -adjacent">
								<label className="label">Privacy statement</label>
								<div className="form-input -text">
									<ul className="option-inputs -small">
										<li className="option -checkbox">
											<label className="label">
												<Formalities.Checkable type="checkbox" className="checkbox" controller={defaultsController} prop="defaultPrivacyStatement" checkedValue={true} /><span className="substitute"></span>
												Use default
											</label>
										</li>
									</ul>
									<MyReactQuill
										theme="bubble" 
										className="field body-text"
										readOnly={defaults.defaultPrivacyStatement}
										value={defaults.defaultPrivacyStatement ? survey.defaultPrivacyStatement || '' : privacyStatementSnapshot.value || survey.defaultPrivacyStatement || ''}
										onChange={(content, delta, sources, editor) => {
											if (!defaults.defaultPrivacyStatement) {
												privacyStatementSnapshot.setValue(editor.getHTML())
											}
										}} />
									<p className="note">The privacy statement will be shown to the participants before they start the survey. Only the super administrator can see or set the privacy statement.</p>
								</div>
							</div>
						)}

						{person.superAdmin && (
							<div className="form-field -adjacent">
								<label className="label">Support article</label>
								<div className="form-input -text">
									<ul className="option-inputs -small">
										<li className="option -checkbox">
											<label className="label">
												<Formalities.Checkable type="checkbox" className="checkbox" controller={defaultsController} prop="defaultSupportArticle" checkedValue={true} /><span className="substitute"></span>
												Use default
											</label>
										</li>
									</ul>
									<MyReactQuill
										theme="bubble" 
										className="field body-text"
										readOnly={defaults.defaultSupportArticle}
										value={defaults.defaultSupportArticle ? survey.defaultSupportArticle || '' : supportArticleSnapshot.value || survey.defaultSupportArticle || ''}
										onChange={(content, delta, sources, editor) => {
											if (!defaults.defaultSupportArticle) {
												supportArticleSnapshot.setValue(editor.getHTML())
											}
										}} />
									<p className="note">The support article will be shown to the participants if they are looking for additional help when they view their individual report. Only the super administrator can see or set the support article.</p>
								</div>
							</div>
						)}
						
						<div className="footer-actions">
							<div className="button-group -right">
								{
									surveyChanged ? 
										<>
											<Link className="button-link -secondary" to={pathToSurvey(survey)}>Discard changes</Link>
											<input type="submit" className="button-link -action" value="Save changes" onClick={saveSurvey}/> 
										</> :
										<Link className="button-link -action" to={pathToSurvey(survey)}>Done</Link>
								}
							</div>
						</div>
					</form>		
				</div>
				
				{/* <aside className="aside">
					<div className="aside-text">
						<h4 className="icon-text -uppercase -smallicon -flag">Tip</h4>
						<div className="body-text -small">
							<p>If you change the survey type or add/edit participants or editable sections using the options provided, a copy of the survey will be saved so that you can return to complete it.</p>
						</div>
					</div>
				</aside> */}
			</div>
		</Screen>
	)
}

export default EditSurvey
