import React from 'react'
import { getDegreesFromScore } from '../functions'

interface Props {
	/** A score from 0.0 to 1.0 */
	score?: number
	label1?: string
	label2?: string
	label3?: string
	variant?: 'small' | 'narrow'
	compareToScore?: number
	showComparativeKeys?: boolean
	comparativeName?: string
	reportName?: string
}

/**
 * Render an arc chart for showing survey results.
 */
const ArcChart: React.FC<Props> = function(props) {
	return (
		<div className="chart -narrow">
			<div className={`arc-chart${props.variant ? ` -${props.variant}` : ''}`}>
				<div className="arc"></div>
				{props.compareToScore && <div className="indicator -compare" style={{ transform: `rotate(${getDegreesFromScore(props.compareToScore)}deg)` }}></div>}
				{props.score !== undefined && <div className="indicator" style={{ transform: `rotate(${getDegreesFromScore(props.score)}deg)` }}></div>}
			</div>
			{(props.label1 || props.label2 || props.label3) &&
				<div className="chart-key">
					<ul className="values">
						{props.label1 &&
						<li className="value -poor"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>{props.label1}</li>
						}
						{props.label2 &&
						<li className="value -good"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>{props.label2}</li>
						}
						{props.label3 &&
						<li className="value -great"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>{props.label3}</li>
						}
					</ul>
				</div>
			}
			{props.showComparativeKeys && props.comparativeName && props.reportName &&
				<div className="chart-key">
					<ul className="values">
						<li className="value -current"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>{props.reportName}</li>
						<li className="value -comparison"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>{props.comparativeName}</li>
					</ul>
				</div>
			}
		</div>
	)
}

export default ArcChart
