import { Api } from 'typescript-fetch-api'

export interface QuestionProps<T extends Api.Question> {
	question: DeepReadonly<T>
	questionIndex: number
	section: DeepReadonly<Api.ParticipationSurvey.Section>
	onNext: () => void
	onPrev: () => void
	onExit: () => void
	onAnswerQuestion: (answer: Api.AnswerRequest) => Promise<boolean>
}

export enum SurveyState {
	Normal = 'normal',
	Completed = 'completed',
	Expired = 'expired',
	Pending = 'pending',
	Partial = 'partial',
}
