import React from 'react'
import { Api } from 'typescript-fetch-api'
import { Formalities, useController } from 'formalities'
import ImportParticipants from './ImportParticipants'
import { uniqueToastError } from 'modules/common/functions'

interface Props {
	onAddParticipants: (request: Api.AddParticipantsRequest) => Promise<boolean>
}

interface Form {
	givenName?: string
	familyName?: string
	email?: string
}

const AddParticipants: React.FC<Props> = (props) => {
	const controller = useController<Form>({})
	React.useEffect(function() {
		const form = document.getElementById('addParticipantForm')
		if (form) {
			form.addEventListener('keyup', detectEnterButton)
		}
		return () => {
			if (form) {
				form.removeEventListener('keyup', detectEnterButton)
			}
		}
	})

	function onAddParticipant() {
		const form = controller.snapshot().value

		if (!form.email) {
			uniqueToastError('Please include email address')
			return
		}

		const request: Api.AddParticipantsRequest = {
			participants: [{
				givenName: form.givenName && form.givenName.trim(),
				familyName: form.familyName && form.familyName.trim(),
				emailAddress: form.email && form.email.trim(),
			}],
		}
		props.onAddParticipants(request).then(success => {
			if (success) {
				controller.snapshot().setValue({})
			}
		})
	}

	function detectEnterButton(evt: KeyboardEvent) {
		if (evt.keyCode === 13) {
			onAddParticipant()
		}
	}
	
	return (
		<>
			<div className="narrow-form" id="addParticipantForm">
				<h4 className="subheading-text -small">Add individuals</h4>
				<div className="form-field -stacked">
					<label className="label">Given name</label>
					<div className="form-input -text"><Formalities.Text controller={controller} prop="givenName" className="field" /></div>
				</div>
				<div className="form-field -stacked">
					<label className="label">Family name</label>
					<div className="form-input -text"><Formalities.Text controller={controller} prop="familyName" className="field" /></div>
				</div>
				<div className="form-field -stacked">
					<label className="label">Email address</label>
					<div className="form-input -text"><Formalities.Text controller={controller} prop="email" className="field" type="email" /></div>
				</div>
				<input type="submit" className="button-link -secondary" value="Add individual" onClick={onAddParticipant}/>
			</div>
					
			<div className="narrow-form -csvupload">
				<ImportParticipants onAddParticipants={props.onAddParticipants} />
			</div>
		</>
	)
}

export default AddParticipants