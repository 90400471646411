/**
 * Reducer for authentication.
 * 
 * Responsible for updating our authentication status in the Redux state.
 */

import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from './actions'
import { AccessToken } from './types'
import { storeReadyAction } from 'modules/root/actions'
import produce from 'immer'

/** The store state for the auth module. */
export type StoreState = DeepReadonly<MutableStoreState>

interface MutableStoreState {
	username?: string
	accessToken?: AccessToken
	/** The timestamp (ms) at which we intend to refresh our access token. */
	refreshAt?: number
	errorName?: string
	errorMessage?: string
	loggingIn: boolean
	refreshFailedAt?: number
	rememberMe: boolean
}

const INITIAL_STATE: StoreState = {
	accessToken: undefined,
	loggingIn: false,
	rememberMe: false,
}

/** How many seconds before the access token expires do we refresh it */
const REFRESH_TOKEN_WINDOW = 60

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(actions.refreshedToken, (state, accessToken) => produce(state, draft => {
	draft.accessToken = accessToken
	draft.refreshFailedAt = undefined
	draft.refreshAt = Date.now() + (accessToken.expires_in - REFRESH_TOKEN_WINDOW) * 1000
}))

reducer.case(actions.refreshTokenFailed, (state, payload) => produce(state, draft => {
	/* Only consider refresh token failures if it's from our current refresh token, not from one that we've already replaced */
	if (draft.accessToken && payload.refreshToken === draft.accessToken.refresh_token) {
		/* When the refresh token fails we blank the accessToken, so the app knows we need to re-auth, but we do not
			do the loggedOut action, so we retain our username property, so we know we need to re-auth as that user
			in order to preserve our offline queue.
			*/
		draft.accessToken = undefined
		draft.refreshFailedAt = payload.date
		draft.refreshAt = undefined
	}
}))

reducer.case(actions.loggedIn, (state, result) => produce(state, draft => {
	draft.username = result.username
	draft.accessToken = result.accessToken
	draft.loggingIn = false
	draft.refreshFailedAt = undefined
	draft.rememberMe = result.rememberMe
	draft.refreshAt = Date.now() + (result.accessToken.expires_in - REFRESH_TOKEN_WINDOW) * 1000
}))

reducer.case(actions.login.failed, (state, { error }) => produce(state, draft => {
	draft.errorName = error.name
	draft.errorMessage = error.message
	draft.loggingIn = false
	draft.refreshAt = undefined
}))

reducer.case(actions.login.started, (state) => produce(state, draft => {
	draft.errorName = undefined
	draft.errorMessage = undefined
	draft.loggingIn = true
}))

reducer.case(storeReadyAction, (state) => produce(state, draft => {
	draft.loggingIn = false
}))

reducer.case(actions.loggedInError, (state, error) => produce(state, draft => {
	draft.errorMessage = error.message
	draft.errorName = error.name
}))

/* The user has been logged out remove our stored access token from the state. */
reducer.case(actions.loggedOut, (state) => produce(state, draft => {
	return {
		...INITIAL_STATE,
		/* Preserve any errors resulting from loggedInError */
		errorName: state.errorName,
		errorMessage: state.errorMessage,
	}
}))
