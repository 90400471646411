import { Api } from 'typescript-fetch-api'

export function pathToSurvey(survey: Api.SurveySummary) {
	return `/admin/survey/${survey.id}`
}

export function pathToSurveyEdit(survey: Api.SurveySummary) {
	return `/admin/survey/${survey.id}/edit`
}

export function pathToSurveyEditSection(survey: Api.SurveySummary, sectionId: string) {
	return `/admin/survey/${survey.id}/edit/${sectionId}`
}

export function pathToCustomSurveyQuestion(survey: Api.SurveySummary, sectionId: string, questionId?: string) {
	if (questionId) {
		return `/admin/survey/${survey.id}/edit/${sectionId}/custom/${questionId}`
	} else {
		return `/admin/survey/${survey.id}/edit/${sectionId}/custom`
	}
}

export function pathToSurveyParticipants(survey: Api.SurveySummary) {
	return `/admin/survey/${survey.id}/participants`
}

export function pathToNewSurvey() {
	return '/admin/survey/new'
}
