import LoadingInline from 'modules/common/components/LoadingInline'
import { uniqueToast, uniqueToastError } from 'modules/common/functions'
import React, { useRef, useState } from 'react'
import { CSVReader } from 'react-papaparse'
import { Api } from 'typescript-fetch-api'

type CSVData = CSVRow[]

interface CSVRow {
	data: string[]
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors: any[]
}


interface Props {
	onAddParticipants: (request: Api.AddParticipantsRequest) => Promise<boolean>
}

const ImportParticipants: React.FC<Props> = (props) => {
	const csvReaderRef = useRef<CSVReader>()
	const [importing, setImporting] = useState(false)

	async function handleCSVDrop(data: CSVData) {
		if (data.length === 0) {
			uniqueToastError('No rows were found in the uploaded CSV.')
			return
		}

		const header = data[0].data
		if (header.length < 3) {
			uniqueToastError('The header row doesn‘t contain enough column.')
			return
		}

		if ((header[0] !== 'Given Name' && header[0] !== 'First Name') || 
			(header[1] !== 'Family Name' && header[1] !== 'Last Name') || 
			(header[2] !== 'Email Address' && header[2] !== 'Email')) {
			uniqueToastError('The header row doesn‘t contain: Given Name, Family Name, Email Address')
			return
		}

		let skipped = 0
		const request: Api.AddParticipantsRequest = {
			participants: [],
		}

		for (let i = 1; i < data.length; i++) {
			const row = data[i]
			if (row.errors.length > 0) {
				skipped += 1
				continue
			}

			if (!row.data[2]) {
				continue
			}

			request.participants.push({
				givenName: row.data[0] && row.data[0].trim(),
				familyName: row.data[1] && row.data[1].trim(),
				emailAddress: row.data[2] && row.data[2].trim(),
			})
		}

		if (skipped > 0) {
			uniqueToastError(`${skipped} rows contained errors. Please check the file and try again.`)
			return
		}
		
		setImporting(true)
		try {
			uniqueToast(`Importing ${request.participants.length} participants…`, { type: 'info', autoClose: 5000 })
			await props.onAddParticipants(request)
			// csvReaderRef.current?.removeFile()
		} finally {
			setImporting(false)
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function handleCSVError(error: any) {
		uniqueToastError(`Failed to process CSV file: ${error}`)
	}

	return (
		<>
			<CSVReader
				onDrop={handleCSVDrop}
				onError={handleCSVError}
				ref={csvReaderRef as React.RefObject<CSVReader>}
			>
				<span className="icon-text -upload">Import from a CSV file</span>
			</CSVReader>
			{importing && <div className="note"><LoadingInline /> Importing… Please wait.</div>}
			<p className="note">The CSV must have a header row, and columns: <code>Given Name</code>, <code>Family Name</code>, <code>Email Address</code>.</p>
		</>
	)
}

export default ImportParticipants
