import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { combineReducers } from 'redux'

import * as a from './actions'

import * as admin from 'modules/admin/reducer'
import * as auth from 'modules/auth/reducer'
import * as knowledgeLibrary from 'modules/knowledgelibrary/reducer'
import * as participant from 'modules/participant/reducer'

export type StoreState = DeepReadonly<MutableStoreState>

interface MutableStoreState {
	admin: admin.StoreState
	auth: auth.StoreState
	knowledgeLibrary: knowledgeLibrary.StoreState
	participant: participant.StoreState

	ready: boolean
}

const readyReducer = reducerWithInitialState(false)
	.case(a.readyAction, () => true)

const reducers = {
	admin: admin.reducer,
	auth: auth.reducer,
	knowledgeLibrary: knowledgeLibrary.reducer,
	participant: participant.reducer,

	ready: readyReducer,
}

/**
 * The root reducer, combines reducers for all of the modules / ducks.
 */
export const reducer = combineReducers(reducers)
