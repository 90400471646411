import React, { useEffect } from 'react'
import Screen from 'modules/common/components/Screen'
import { useSelector, useDispatch } from 'react-redux'
import { selectLoggingIn, selectLoggedIn, selectErrorMessage } from '../selectors'
import { useController, Formalities } from 'formalities'
import * as actions from '../actions'
import LoadingScreen from 'modules/common/components/LoadingScreen'
import { Link } from 'react-router-dom'
import { history } from 'modules/routing'

interface LoginForm {
	username: string
	password: string
	rememberMe: boolean
	totpCode?: string
}

export default function Login() {
	const [showLoginForm, setShowLoginForm] = React.useState<boolean | undefined>(undefined)
	const [hasAttemptedTotpLogin, setHasAttemptedTotpLogin] = React.useState(false)
	const controller = useController<LoginForm>({
		username: '',
		password: '',
		rememberMe: false,
	})
	const loggingIn = useSelector(selectLoggingIn)
	const loggedIn = useSelector(selectLoggedIn)
	const errorMessage = useSelector(selectErrorMessage)
	const dispatch = useDispatch()

	async function doLogin(evt: React.FormEvent) {
		evt.preventDefault()
		const formValue = controller.snapshot().value

		dispatch(actions.login.started({
			username: formValue.username,
			password: formValue.password,
			rememberMe: formValue.rememberMe,
			totpCode: formValue.totpCode,
		}))
		setShowLoginForm(false)
		formValue.totpCode ? setHasAttemptedTotpLogin(true) : setHasAttemptedTotpLogin(false)
	}

	function onBackToForm(evt: React.FormEvent) {
		evt.preventDefault()
		setHasAttemptedTotpLogin(false)
		controller.snapshot().setValue({ 
			...controller.snapshot().value,
			totpCode: undefined,
		})
		setShowLoginForm(true)
	}

	useEffect(function() {
		if (loggedIn) {
			/* Use a timeout as sagas will redirect after login is successful; this protects us if someone navigates to this page while signed in */
			const timeout = setTimeout(function() {
				history.replace('/')
			}, 1000)
			return function() {
				clearTimeout(timeout)
			}
		}
	}, [loggedIn])

	if (loggingIn || loggedIn) {
		return <LoadingScreen />
	}

	return (
		<Screen title="Sign-in" includePageHeader={false}>
			<form className="signin-form">
				<div className="content-header">
					<h1 className="headline-text -centered">Sign in to your Umbrella&nbsp;account</h1>
				</div>
				<div className="form">
					{errorMessage && (errorMessage !== 'Auth request failed: invalid_totp' || (errorMessage === 'Auth request failed: invalid_totp' && hasAttemptedTotpLogin)) && (
						<div className="form-row -message">
							<div className="message -error">
								<p className="icon-text -msgerror">
									{errorMessage === 'Auth request failed: invalid_totp' ? 
										('Your One Time Password was not correct. \nPlease try again.') : 
										errorMessage
									}
								</p>
							</div>
						</div>
					)}
					{errorMessage && errorMessage === 'Auth request failed: invalid_totp' && !showLoginForm &&
						<>
							<p>2FA has been enabled for your account, please provide your One Time Password (OTP) in the field below to complete sign in.</p>
							<div className="form-input -text">
								<Formalities.Text controller={controller} prop="totpCode" className="field" autoFocus={true} placeholder="TOTP code" autoComplete="one-time-code" />
							</div> 
						</>
					}
					{
						(!errorMessage || errorMessage !== 'Auth request failed: invalid_totp' || showLoginForm) &&
						<>
							<div className="form-input -text">
								<Formalities.Text controller={controller} prop="username" className="field" autoComplete="username" autoFocus={true} placeholder="Email address" type="email" />
							</div>
							<div className="form-input -text">
								<Formalities.Text controller={controller} prop="password" type="password" className="field" autoComplete="current-password" placeholder="Password" />
							</div>
						</>
					}
				</div>
				<div className="footer-actions -borderless">
					{
						errorMessage && errorMessage === 'Auth request failed: invalid_totp' && !showLoginForm ?
							<div className="button-group">
								<Link to="/" className="icon-text -back" onClick={onBackToForm}>Back to signin form</Link>
							</div> : 
							<div className="button-group">
								<Link to="./reset-password" className="icon-text -help">Forgotten password?</Link>
							</div>
					}
					<div className="button-group -right">
						<input type="submit" className="button-link -action" value="Sign in" onClick={doLogin} />
					</div>
				</div>
			</form>
		</Screen>
	)
}
