import { Store } from 'redux'
import { ConfigurationParameters } from 'typescript-fetch-api'

import { AuthConfig } from 'modules/auth/types'
import platformSupportImplementation from './impl'
import { RootStoreState } from 'modules/root'

export enum Environment {
	Live = 'live',
	Test = 'test',
	Development = 'dev',
	Staging = 'staging',
}

export interface PlatformSupport {

	init: (store: Store<RootStoreState>) => void

	alert: (message: string) => void

	/** Display a message to the user, asking them to confirm the given message. */
	confirm: (message: string, title: string, confirmAction: string) => Promise<boolean>

	/** Create and return the API configuration parameters */
	createApiConfigurationParams: () => ConfigurationParameters

	/** Create and return the authentication configuration */
	createAuthConfiguration: () => AuthConfig

	/** Return which environment the app is running in. */
	environment: () => Environment
}

export default platformSupportImplementation
