import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from './actions'
import * as auth from 'modules/auth/actions'
import produce from 'immer'
import { Api } from 'typescript-fetch-api'

/** The store state for the admin module. */
export type StoreState = DeepReadonly<MutableStoreState>

interface MutableStoreState {
	knowledgeLibrary?: Api.KnowledgeLibrary
	loading: boolean
	error?: Error
}

const INITIAL_STATE: StoreState = {
	loading: false,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(actions.loadKnowledgeLibrary.started, (state, payload) => produce(state, draft => {
	draft.knowledgeLibrary = undefined
	draft.loading = true
	draft.error = undefined
}))

reducer.case(actions.loadKnowledgeLibrary.done, (state, payload) => produce(state, draft => {
	draft.knowledgeLibrary = payload.result
	draft.loading = false
	draft.error = undefined
}))

reducer.case(actions.loadKnowledgeLibrary.failed, (state, payload) => produce(state, draft => {
	draft.knowledgeLibrary = undefined
	draft.loading = false
	draft.error = payload.error
}))

reducer.case(auth.loggedOut, (state) => produce(state, draft => {
	return {
		...INITIAL_STATE,
	}
}))
