import React from 'react'
import { Api } from 'typescript-fetch-api'
import { apiErrorToMessage } from 'modules/api/functions'
import { jsonToCSV } from 'react-papaparse'
import download from 'downloadjs'
import { uniqueToastError } from 'modules/common/functions'

interface Props {
	filenamePrefix?: string
	filenameSuffix?: string
	group?: Api.ParticipantGroup
	loadParticipants: (cursor?: string) => Promise<{ nextCursor?: string; participants: Api.Participant[] }>
}

const ExportParticipants: React.FC<Props> = (props) => {
	const { filenamePrefix, filenameSuffix, group, loadParticipants } = props

	function doDownload(evt: React.MouseEvent) {
		evt.preventDefault()

		async function doDownload() {
			let csv = 'Given Name,Family Name,Email Address\n'
			let result = await loadParticipants(undefined)
			/* https://www.papaparse.com/docs#json-to-csv */
			csv += jsonToCSV(result.participants, {
				header: false,
				columns: ['givenName', 'familyName', 'emailAddress'],
			})

			while (result.nextCursor) {
				result = await loadParticipants(result.nextCursor)
				csv += '\n' + jsonToCSV(result.participants, {
					header: false,
					columns: ['givenName', 'familyName', 'emailAddress'],
				})
			}

			download(csv, (filenamePrefix ? `${filenamePrefix} ` : '') + (group ? `${group.name} Participants` : 'All Participants') + (filenameSuffix ? ` ${filenameSuffix}` : '') + '.csv', 'text/csv')
		}

		doDownload().catch(error => {
			uniqueToastError(`Failed to download the participants:\n${apiErrorToMessage(error)}`)
		})
	}

	return (
		<div className="narrow-form -downloads">
			<h4 className="subheading-text -small">Download CSV</h4>
			{!group &&
			<div className="form-field">
				<button className="icon-text -download" onClick={doDownload}>All participants</button>
			</div>
			}
			{group &&
			<div className="form-field">
				<button className="icon-text -download" onClick={doDownload}>{group.name}</button>
			</div>
			}
		</div>
	)
}

export default ExportParticipants
