import React, { ReactElement } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router'
import LoadingScreen from 'modules/common/components/LoadingScreen'
import ParticipantErrorBoundary from './ParticipantErrorBoundary'
import { usePromiseEffect, useCallApi } from 'modules/common/hooks'
import ErrorScreen from 'modules/common/components/ErrorScreen'
import Screen from 'modules/common/components/Screen'
import { Link } from 'react-router-dom'

export default function ParticipantRouter() {
	const { path } = useRouteMatch()

	return (
		<ParticipantErrorBoundary>
			<Switch>
				<Route
					path={`${path}/:token`} 
					render={props => (
						<ParticipantClaimTokenHandler
							token={props.match.params.token}
							render={() => (
								<Screen title="Claimed" includePageHeader={false}>
									<div className="content-header">
										<h1 className="headline-text -centered">Thank you!</h1>
										<p className="body-text -large -centered">Your survey response has been added to your Umbrella account.</p>
										<p className="body-text -centered">
											<Link to="/" className="button-link -action">Sign in</Link>
										</p>
									</div>
								</Screen>
							)}
						/>
					)} />
			</Switch>
		</ParticipantErrorBoundary>
	)
}

function ParticipantClaimTokenHandler(props: { token: string; render: () => ReactElement }) {
	const { token } = props
	const callApi = useCallApi()

	const loginResult = usePromiseEffect(() => () => callApi(api => api.participationApi.claimParticipation({
		token,
	})), [callApi, token])

	if (loginResult.result) {
		return props.render()
	} else if (loginResult.error) {
		return <ErrorScreen error={loginResult.error} />
	} else {
		return <LoadingScreen />
	}
}
