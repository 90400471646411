import * as React from 'react'
import { useController, Formalities } from 'formalities'
import { MatrixSubQuestion, MatrixQuestion, AnswerRequest } from 'typescript-fetch-api'
import { createScaleOptions } from '../functions'

interface FormState {
	answer: number
	otherTitle: string
	notApplicable: boolean
}

export interface Props {
	matrix: DeepReadonly<MatrixQuestion>
	onAnswerQuestion: (answer: AnswerRequest) => Promise<boolean>
	question: DeepReadonly<MatrixSubQuestion>
	updateCurrentQuestion: (questionId: string) => void
	questionNumber: number
	highlightMissing: boolean
}

export default function QuestionMatrixSubquestion(props: Props) {
	const { question, matrix } = props

	const controller = useController<FormState>({
		answer: question.answerIndex === undefined ? -1 : question.answerIndex,
		otherTitle: question.otherTitle ? question.otherTitle : '',
		notApplicable: question.notApplicableOption && question.answerNotApplicable ? true : false,
	})

	const form = controller.snapshot().value

	controller.addChangeListener(save)

	async function save(form: FormState) {
		const answerReq: AnswerRequest = {
			questionId: question.id,
			intValue: form.answer !== -1 ? form.answer : undefined,
		}

		if (question.requiresOtherTitle) {
			answerReq.otherTitle = form.otherTitle
			if (!form.otherTitle) {
				answerReq.intValue = undefined
			}
		}

		if (question.notApplicableOption) {
			answerReq.notApplicable = form.notApplicable
			if (form.notApplicable) {
				answerReq.intValue = undefined
				answerReq.otherTitle = undefined
			}
		}

		if (answerReq.intValue === question.answerIndex && answerReq.notApplicable === question.notApplicableOption && answerReq.otherTitle === question.otherTitle) {
			return
		}

		const saved = await props.onAnswerQuestion(answerReq)
		if (!saved) {
			controller.snapshot('answer').setValue(question.answerIndex === undefined ? -1 : question.answerIndex)
		}

		props.updateCurrentQuestion(question.id)
	}

	return (
		<div className={'matrix -scale' + (incomplete(question, matrix, props.questionNumber, props.highlightMissing) ? ' -incomplete' : '')}>
			<h2 className="question-title subheading-text">{question.title}</h2>
			{
				question.notApplicableOption &&
					<div className="option-inputs -small -notapplicable">
						<div className="option -checkbox">
							<label className="label">
								<Formalities.Checkable type="checkbox" controller={controller} prop="notApplicable" className="checkbox" checkedValue={true} uncheckedValue={false} />
								<span className="substitute"></span>
								{question.notApplicableOption}
							</label>
						</div>
					</div>
			}
			{
				question.requiresOtherTitle && (
					<div className={'form-field -adjacent' + (form.notApplicable ? ' -disabled' : '')}>
						<label className="label">Please specify:</label>
						<div className="form-input -text">
							<Formalities.Text controller={controller} prop="otherTitle" className="field" disabled={form.notApplicable} />
						</div>
					</div>
				)
			}
			{(!question.requiresOtherTitle || controller.snapshot().value.otherTitle) &&
			<div className="scale">
				<ul className={'option-inputs -scale' + (form.notApplicable ? ' -disabled' : '')}>
					{
						matrix.min !== undefined && matrix.max !== undefined ? createScaleOptions(matrix.min, matrix.max).map((option, optionIndex) => (
							<li className="option -radio" key={optionIndex}>
								<label className="label">
									<Formalities.Checkable type="radio" className="radio" controller={controller} prop="answer" checkedValue={option} disabled={form.notApplicable}/>
									<span className="substitute"></span>
								</label>
							</li>
						)) : ''
					}
				</ul>
				<div className={'scale-key labels-' + matrix.labels.length + (form.notApplicable ? ' -disabled' : '')}>
					{
						matrix.labels && matrix.labels.map((option, optionIndex) => (
							<div className="key" key={optionIndex}>{option}</div>
						))
					}
				</div>
			</div>
			}
		</div>
	)
}

export function incomplete(question: DeepReadonly<MatrixSubQuestion>, matrix: DeepReadonly<MatrixQuestion>, questionNumber: number, highlightMissing: boolean): boolean {
	if (question.notApplicableOption && question.answerNotApplicable) {
		return false
	}

	if (question.requiresOtherTitle) {
		/* If we haven't given an otherTitle and we're optional, then we're not incomplete */
		if (!question.otherTitle && question.optional) {
			return false
		}

		/* If we've given the otherTitle and an answer, then we're not incomplete */
		if (question.otherTitle && question.answerIndex !== undefined) {
			return false
		}
	} else if (question.optional) {
		return false
	} else if (question.answerIndex !== undefined) {
		return false
	}

	if (highlightMissing) {
		return true
	}

	/* Check if they've skipped this question */
	if (matrix.subquestions[questionNumber + 1] !== undefined) {
		for (let i = questionNumber + 1; i < matrix.subquestions.length; i++) {
			if (matrix.subquestions[i].answerIndex !== undefined || matrix.subquestions[i].answerNotApplicable) {
				return true
			}
		}
	}

	/* They haven't skipped us, so no need to show incomplete */
	return false
}
