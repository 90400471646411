/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import Debug from 'modules/common/components/Debug'
import { RouteComponentProps } from 'react-router'

interface State {
	bodyIsOverflowing: boolean
	scrollbarWidth: number
}

const INITIAL_STATE: State = {
	bodyIsOverflowing: false,
	scrollbarWidth: 0,
}

interface Props {
	onClose?: () => void
}

type MyComponentProps = Props & Partial<RouteComponentProps>

export enum ModalStyle {
	Normal = '',
	Narrow = '-narrow',
	Medium = '-medium',
	Large = '-large',
}

export interface ModalComponentProps {
	onClose(): void
}

/** Adds modal capabilities to the given component. */
export function withModal<P extends ModalComponentProps>(WrappedComponent: React.FunctionComponent<P>, style: ModalStyle = ModalStyle.Normal) {
	return class WithModal extends React.Component<Omit<P, keyof ModalComponentProps> & MyComponentProps, State> {

		public state = INITIAL_STATE

		public componentDidMount() {
			setTimeout(this.handleOpenedModal, 10)
		}
	
		public componentWillUnmount() {
			this.handleModalClosed()
		}

		public render() {
			const { onClose, ...rest } = this.props
			return (
				<div>
					<div className="modal-window">
						<div className="overlay"/>
						<div className="container">
							<div className="width-limit -modal">
								<div className={'contents' + (style !== ModalStyle.Normal ? ` ${style}` : '')}>
									<div className="modal-contents">
										<WrappedComponent onClose={this.doClose} {...rest as any} />
									</div>
									<button className="close" onClick={this.onClose} type="button">Close</button>
								</div>
							</div>
						</div>
					</div>
					<Debug params={window.location.search}/>
				</div>

			)
		}

		private doClose = () => {
			if (this.props.onClose) {
				this.props.onClose()
			} else if (this.props.history) {
				this.props.history.goBack()
			} else {
				console.error('Cannot close modal')
			}
		}

		private onClose = (evt: React.MouseEvent) => {
			evt.preventDefault()
			this.doClose()
		}

		private keyListener = (e: KeyboardEvent) => {
			if (e.keyCode === 27) {
				this.doClose()
			}
		}

		private handleOpenedModal = () => {
			this.checkScrollbar()
			this.setScrollbar()
			document.body.className = 'modal-open'
	
			document.addEventListener('keyup', this.keyListener)
		}
	
		private handleModalClosed = () => {
			document.body.className = ''
			this.resetScrollbar()
			document.removeEventListener('keyup', this.keyListener)
		}

		private checkScrollbar() {
			let fullWindowWidth = window.innerWidth
			
			if (!fullWindowWidth) { // workaround for missing window.innerWidth in IE8
				const documentElementRect = document.documentElement.getBoundingClientRect()
				fullWindowWidth = documentElementRect.right - Math.abs(documentElementRect.left)
			}
			
			this.setState({
				bodyIsOverflowing: document.body.clientWidth < fullWindowWidth,
				scrollbarWidth: this.measureScrollbar(),
			})
		}
	
		private setScrollbar() {
			if (this.state.bodyIsOverflowing) {
				document.body.style.paddingRight = this.state.scrollbarWidth + 'px'
			}
		}
	
		private resetScrollbar() {
			document.body.style.paddingRight = ''
		}
	
		private measureScrollbar() {
			const scrollDiv = document.createElement('div')
			scrollDiv.className = 'overflow-scrollbar-measure'
			document.body.appendChild(scrollDiv)
	
			const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
			document.body.removeChild(scrollDiv)
			
			return scrollbarWidth
		}

	}
}
