import React, { useEffect } from 'react'
import { Api } from 'typescript-fetch-api'
import ReportDetails from './ReportDetails'
import Report from './Report'

interface Props {
	report: Api.SurveyReport
	filters?: Api.GetFilters200Response
	request?: Api.ReportOptions
	setRequest: (newRequest: Api.ReportOptions | undefined) => void
	setReport?: (report: Api.SurveyReport) => void
	reports?: Api.GetReports200Response
}

const PrintReport: React.FC<Props> = function(props) {
	useEffect(function() {
		console.log('Ready to print')
	}, [])
	
	return (
		<div className="print width-limit -site screen-report">
			<Report report={props.report} printMode={true} filters={props.filters} setRequest={props.setRequest} request={props.request} setReport={props.setReport} reports={props.reports}/>
			{
				props.report.sections.map((section, index) => 
					<ReportDetails key={index} printMode={true} section={index} report={props.report} filters={props.filters} request={props.request} setRequest={props.setRequest} />,
				)
			}
		</div>
	)
}

export default PrintReport
