import { Api, ParticipationSurvey, QuestionTypeEnum } from 'typescript-fetch-api'

export function createScaleOptions(minValue: number, maxValue: number): Array<number> {
	const scaleOptions = []

	for (let i = minValue; i < (maxValue + 1); i++) {
		scaleOptions.push(i)
	}

	return scaleOptions
}

export function findQuestionSection(questionId: string, survey: DeepReadonlyObject<ParticipationSurvey>): number | undefined {
	let currentSection = -1
	const sections = survey.sections

	for (let i = 0; i < sections.length; i++) {
		currentSection = i

		for (let q = 0; q < sections[i].questions.length; q++) {
			const question = sections[i].questions[q]

			if (question.id === questionId) {
				return currentSection
			} else if (question.type === QuestionTypeEnum.Matrix) {
				for (let sq = 0; sq < question.subquestions.length; sq++) {
					if (question.subquestions[sq].id === questionId) {
						return currentSection
					}
				}
			}
		}
	}
}

export function getSurveyPositionPercentage(questionId: string, survey: DeepReadonlyObject<ParticipationSurvey>): string {
	let numberQuestions = 0
	let currentQuestionIndex = -1
	const sections = survey.sections

	for (let i = 0; i < sections.length; i++) {
		for (let q = 0; q < sections[i].questions.length; q++) {
			const question = sections[i].questions[q]

			if (question.id === questionId) {
				currentQuestionIndex = numberQuestions
			}

			if (question.type !== QuestionTypeEnum.Matrix) {
				numberQuestions++
			}

			if (question.type === QuestionTypeEnum.Matrix) {
				for (let sq = 0; sq < question.subquestions.length; sq++) {
					const subQuestion = question.subquestions[sq]
					
					numberQuestions++

					if (subQuestion.id === questionId) {
						currentQuestionIndex = numberQuestions
					}
				}
			}
		}
	}

	if (numberQuestions > 0 && currentQuestionIndex > -1) {
		const position = Math.round((100 / numberQuestions) * currentQuestionIndex)
		return position.toString()
	} else {
		return '0'
	}
}

export function findLastQuestion(survey: DeepReadonlyObject<ParticipationSurvey>) {
	const lastSectionIndex = survey.sections.length - 1
	const lastQuestionIndex = survey.sections[lastSectionIndex].questions.length - 1
	const lastQuestion = survey.sections[lastSectionIndex].questions[lastQuestionIndex]
	if (lastQuestion.type === QuestionTypeEnum.Matrix) {
		return lastQuestion.subquestions[lastQuestion.subquestions.length - 1]
	}
	return lastQuestion
}

export function questionComplete(question: DeepReadonlyObject<(Api.ScaleQuestion | Api.MultichoiceQuestion | Api.MatrixQuestion | Api.MatrixSubQuestion | Api.FreeTextQuestion)>) {
	switch (question.type) {
		case QuestionTypeEnum.Multi: {
			switch (question.multichoiceType) {
				case Api.MultichoiceQuestion.MultichoiceTypeEnum.Multi:
					return question.answerIndices && question.answerIndices.length > 0 ? true : false
				case Api.MultichoiceQuestion.MultichoiceTypeEnum.LongSingle:
					return question.answerIndex ? true : false
				case Api.MultichoiceQuestion.MultichoiceTypeEnum.Single:
					return question.answerIndex ? true : false
			}
			throw new Error(`Unsupported free multichoice question type: ${question.multichoiceType}`)
		}
		case QuestionTypeEnum.Scale:
			return question.answerIndex ? true : false
		case QuestionTypeEnum.MatrixSub:
			return question.answerIndex ? true : false
		case QuestionTypeEnum.FreeText: {
			return question.answeredStringValue && question.answeredStringValue !== '' ? true : false
		}
		case QuestionTypeEnum.Matrix: {
			return false
		}
	}
}
