import React, { useState } from 'react'
import Screen from 'modules/common/components/Screen'
import { useCallApi, useCursoredDataEffect } from 'modules/common/hooks'
import { useCurrentAccount } from 'modules/admin/hooks'
import { Link, useHistory } from 'react-router-dom'
import LoadingInline from 'modules/common/components/LoadingInline'
import ErrorInline from 'modules/common/components/ErrorInline'
import { Api } from 'typescript-fetch-api'
import { useDispatch } from 'react-redux'
import { setCurrentMembership } from 'modules/admin/actions'

const SuperAdminOrganisations: React.FC = () => {
	const account = useCurrentAccount()
	const callApi = useCallApi()
	const [archived, setArchived] = useState(false)
	const [search, setSearch] = useState('')
	const { response, loadMore, reset } = useCursoredDataEffect((cursor) => callApi(api => {
		if (search) {
			return api.adminApi.searchAccounts(search, archived, cursor)
		} else {
			return api.adminApi.getAccounts(archived, cursor)
		}
	}), [account.id, archived, callApi, search])
	const history = useHistory()
	const dispatch = useDispatch()

	async function chooseAccount(account: Api.AccountSummary) {
		const currentAccount = await callApi((api) => api.accountApi.getAccount(account.id))
		dispatch(setCurrentMembership({
			account: currentAccount,
			role: Api.PersonDetails.Membership.RoleEnum.Admin,
			adminUserRole: Api.AdminUserRole.Manage,
		}))

		history.push('/')
	}

	function handleArchivedChange() {
		setArchived(archived => !archived)
		reset()
	}

	function handleSearchChanged(evt: React.ChangeEvent<HTMLInputElement>) {
		const s = evt.target.value
		setSearch(s)
		reset()
	}

	return (
		<Screen title="Umbrella Organisations" class="-narrow screen-accounts">
			<div className="content-header -split">
				<h1 className="headline-text">All organisations</h1>
				<Link to="/admin/super/new-organisation" className="button-link -action">New organisation</Link>
			</div>
			<div>
				<label><input type="checkbox" checked={archived} onChange={handleArchivedChange} /> Archived</label>
				<input type="text" value={search} onChange={handleSearchChanged} placeholder="Filter organisations" />
			</div>
			<div className="accounts">
				<table className="data-table">
					<thead>
						<tr>
							<th>Name</th>
						</tr>
					</thead>
					<tbody>
						{response.loading && (
							<tr>
								<td colSpan={6}><LoadingInline /></td>
							</tr>
						)}
						{response.error && (
							<tr>
								<td colSpan={6}><ErrorInline error={response.error} /></td>
							</tr>
						)}
						{response.result && response.result.accounts.map((account, index) => (
							account.name && (
								<tr key={index}>
									<th scope="row">
										<Link to="/" className="link" onClick={(evt: React.MouseEvent) => {
											evt.preventDefault()
											chooseAccount(account)
										}}>{account.name}</Link>
									</th>
								</tr>
							)
						))}
						{loadMore && (
							<tr className="more">
								<td colSpan={6}><button onClick={loadMore} className="button-link -action -small">Load more</button></td>
							</tr>
						)}
					</tbody>
				</table>

			</div>
		</Screen>
	)
}

export default SuperAdminOrganisations
