import { useEffect, useCallback } from 'react'

export function useKeyPress(targetKey: number, callback: () => void) {
	useEffect(function() {
		window.addEventListener('keydown', downHandler)

		function downHandler(key: KeyboardEvent) {
			if (key.keyCode === targetKey) {
				callback()
			}
		}

		return () => {
			window.removeEventListener('keydown', downHandler)
		}
	}, [callback, targetKey])
}

export function useKeyboardNavigation(onPrev: () => void, onNext: () => void) {
	function isInTextField() {
		return document.activeElement && ((document.activeElement.tagName === 'INPUT' && (document.activeElement as HTMLInputElement).type === 'text') || document.activeElement.tagName === 'TEXTAREA')
	}

	useKeyPress(37, useCallback(function() {
		if (!isInTextField()) {
			onPrev()
		}
	}, [onPrev]))
	useKeyPress(39, useCallback(function() {
		if (!isInTextField()) {
			onNext()
		}
	}, [onNext]))
}

export function useDisableKeyboardNavigationForRadios() {
	window.addEventListener('keydown', downHandler)

	function downHandler(key: KeyboardEvent) {
		if ((key.keyCode === 37 || key.keyCode === 39) && document.activeElement && document.activeElement.tagName === 'INPUT' && (document.activeElement as HTMLInputElement).type === 'radio') {
			key.preventDefault()
		}
	}
}
