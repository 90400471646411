import { RootStoreState } from 'modules/root'

/**
 * 
 * @param state Select the current auth access token from the root store state.
 */
export const selectAccessToken = (state: RootStoreState) => state.auth.accessToken
export const selectRefreshFailedAt = (state: RootStoreState) => state.auth.refreshFailedAt

/** Selects a boolean, whether the user is logged in or not. */
export const selectLoggedIn = (state: RootStoreState) => state.auth.accessToken !== undefined

export const selectErrorMessage = (state: RootStoreState) => state.auth.errorMessage
export const selectLoggingIn = (state: RootStoreState) => state.auth.loggingIn
