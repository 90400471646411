import React from 'react'

interface Props {
	poor: number | undefined /** A score from 0.0 to 1.0 */
	good: number | undefined /** A score from 0.0 to 1.0 */
	great: number | undefined /** A score from 0.0 to 1.0 */
	minLabel?: string
	midLabel?: string
	maxLabel?: string
	minimal?: boolean
	hideLabels?: boolean
	comparison?: boolean
}

/**
 * Render a set of three ring charts showing values for poor, good and great
 */
const RingChartSet: React.FC<Props> = function(props) {
	return (
		<>
			{
				props.minLabel && props.poor !== undefined &&
				<div className={props.comparison ? 'ring-chart -comparison' : 'ring-chart'}>
					<div className="ringdata -poor">
						<div className="rings">
							<svg className="svg" viewBox="0 0 36 36">
								<g className="ring" style={{ transform: 'scale(1) rotate(-180deg)' }}>
									<circle className="background"
										cx="50%" cy="50%"
										strokeWidth={props.minimal ? '5' : '4'}
										r={props.minimal ? '14.960564651' : '15.915'} /> 
									<circle className="value"
										cx="50%" cy="50%"
										strokeWidth={props.minimal ? '5' : '4'}
										r={props.minimal ? '14.960564651' : '15.915'}
										strokeDasharray={(props.poor * 100) + ', 100'} />
								</g>
							</svg>
						</div>
						<p className="data">
							<span className="result"><span className="value">{Math.round(props.poor * 100)}<span className="unit">%</span></span></span>
							{!props.hideLabels && (
								<small className="type">{props.minLabel}</small>
							)}
						</p>
					</div>
				</div>
			}
			{
				props.midLabel && props.good !== undefined &&
				<div className={props.comparison ? 'ring-chart -comparison' : 'ring-chart'}>
					<div className="ringdata -good">
						<div className="rings">
							<svg className="svg" viewBox="0 0 36 36">
								<g className="ring" style={{ transform: 'scale(1) rotate(-180deg)' }}>
									<circle className="background"
										cx="50%" cy="50%"
										strokeWidth={props.minimal ? '5' : '4'}
										r={props.minimal ? '14.960564651' : '15.915'} /> 
									<circle className="value"
										cx="50%" cy="50%"
										strokeWidth={props.minimal ? '5' : '4'}
										r={props.minimal ? '14.960564651' : '15.915'}
										strokeDasharray={(props.good * 100) + ', 100'} />
								</g>
							</svg>
						</div>
						<p className="data">
							<span className="result"><span className="value">{Math.round(props.good * 100)}<span className="unit">%</span></span></span>
							{!props.hideLabels && (
								<small className="type">{props.midLabel}</small>
							)}
						</p>
					</div>
				</div>
			}
			{
				props.maxLabel && props.great !== undefined &&
				<div className={props.comparison ? 'ring-chart -comparison' : 'ring-chart'}>
					<div className="ringdata -great">
						<div className="rings">
							<svg className="svg" viewBox="0 0 36 36">
								<g className="ring" style={{ transform: 'scale(1) rotate(-180deg)' }}>
									<circle className="background"
										cx="50%" cy="50%"
										strokeWidth={props.minimal ? '5' : '4'}
										r={props.minimal ? '14.960564651' : '15.915'} /> 
									<circle className="value"
										cx="50%" cy="50%"
										strokeWidth={props.minimal ? '5' : '4'}
										r={props.minimal ? '14.960564651' : '15.915'}
										strokeDasharray={(props.great * 100) + ', 100'} />
								</g>
							</svg>
						</div>
						<p className="data">
							<span className="result"><span className="value">{100 - Math.round((props.poor || 0) * 100) - Math.round((props.good || 0) * 100)}<span className="unit">%</span></span></span>
							{!props.hideLabels && (
								<small className="type">{props.maxLabel}</small>
							)}
						</p>
					</div>
				</div>
			}
		</>
	)
}

export default RingChartSet
