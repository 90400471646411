import { useSelector } from 'react-redux'
import { selectCurrentMembership, selectPersonDetails } from './selectors'
import { Api } from 'typescript-fetch-api'
import { selectLoggedIn } from 'modules/auth/selectors'

export function useCurrentAccount(): DeepReadonly<Api.AccountDetails> {
	const loggedIn = useSelector(selectLoggedIn)
	if (!loggedIn) {
		throw new Error('Not logged in')
	}
	
	const currentMembership = useSelector(selectCurrentMembership)
	if (!currentMembership) {
		throw new Error('No account')
	}
	return currentMembership.account
}

export function useCurrentPerson(): DeepReadonly<Api.PersonDetails> {
	const personDetails = useSelector(selectPersonDetails)
	if (!personDetails) {
		throw new Error('Not logged in')
	}
	return personDetails
}

export function useCurrentAdminUserRole(): Api.AdminUserRole | undefined {
	const currentMembership = useSelector(selectCurrentMembership)
	if (!currentMembership) {
		return undefined
	}
	return currentMembership.adminUserRole
}

/** Returns true if the current user can manage the current account. */
export function useCanManage(): boolean {
	const personDetails = useSelector(selectPersonDetails)
	const role = useCurrentAdminUserRole()
	return personDetails !== undefined && (personDetails.superAdmin || role === Api.AdminUserRole.Manage)
}

/** Returns true if the current user can edit the current account. */
export function useCanEdit(): boolean {
	const role = useCurrentAdminUserRole()
	return role === Api.AdminUserRole.Edit || role === Api.AdminUserRole.Manage
}

/** Returns true if the current user can view the current account. */
export function useCanView(): boolean {
	const role = useCurrentAdminUserRole()
	return role === Api.AdminUserRole.Edit || role === Api.AdminUserRole.Manage || role === Api.AdminUserRole.View
}

export function useAssertCanManage(): void {
	const canManage = useCanManage()
	if (!canManage) {
		throw new Error('You cannot access this function as you do not have sufficient privileges on this account.')
	}
}
