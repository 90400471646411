import React, { useState } from 'react'
import { Switch, Route, useRouteMatch, useParams, useLocation } from 'react-router'
import LoadingScreen from 'modules/common/components/LoadingScreen'
import { useApiEffect, useCursoredDataEffect, useCallApi } from 'modules/common/hooks'
import Report from './Report'
import PrintReport from './PrintReport'
import ReportDetails from './ReportDetails'
import ErrorScreen from 'modules/common/components/ErrorScreen'
import { Api } from 'typescript-fetch-api'
import { normaliseRequest } from '../functions'
import Screen from 'modules/common/components/Screen'
import { pathToSurveyReport } from '../navigation'
import { useCurrentAccount } from 'modules/admin/hooks'

function initialReportOptions(): Api.ReportOptions | undefined {
	if (window.location.search && window.location.search.indexOf('options=') !== -1) {
		const i = window.location.search.indexOf('options=')
		let optionsString = window.location.search.substring(i + 'options='.length)
		const j = optionsString.indexOf('&')
		if (j !== -1) {
			optionsString = optionsString.substring(0, j)
		}
		optionsString = unescape(optionsString)
		const optionsObject = JSON.parse(optionsString)
		if (typeof optionsObject === 'object') {
			return optionsObject
		} else {
			return undefined
		}
	} else {
		return undefined
	}
}

interface LocationState {
	from?: string
}

export default function AdminReportRouter() {
	const { path } = useRouteMatch()
	const params = useParams<{ surveyId: string }>()
	const surveyId = params.surveyId
	const [request, setRequest] = useState<Api.ReportOptions | undefined>(initialReportOptions())
	const getFilters = useApiEffect(api => api.surveyApi.getFilters(surveyId), [surveyId])
	const account = useCurrentAccount()
	const callApi = useCallApi()
	const getReports = useCursoredDataEffect(cursor => callApi(api => api.accountApi.getReports(account.id, cursor, Api.SortSurveys.Modified)), [account.id, callApi])
	const location = { ...useLocation<LocationState>().state }
	let breadcrumbText = 'surveys'
	let breadcrumbLink = '/admin/survey/your-surveys'
	
	if (!surveyId) {
		throw new Error('No report')
	}

	if (location.from) {
		if (location.from.includes('admin/reporting')) {
			breadcrumbText = 'reports'
			breadcrumbLink = '/admin/reporting'
		} else if (location.from.includes('admin/survey')) { 
			breadcrumbText = 'survey'
			breadcrumbLink = location.from.substring(location.from.indexOf('admin/survey'))
		} else if (location.from.includes('/admin')) { 
			breadcrumbText = 'dashboard'
			breadcrumbLink = '/admin'
		}
	}

	/* Load the report before routing */
	const getReport = useApiEffect((api) => api.surveyApi.getReport(surveyId, request), [surveyId, request])
	
	/* If a change has been made, we use the state version of report to prevent needing to refresh */
	const [report, setReport] = useState<Api.SurveyReport | undefined>(getReport.result ? getReport.result.report : undefined)

	if (getReport.error) {
		return <ErrorScreen error={getReport.error} />
	} else if (!getReport.result) {
		return <LoadingScreen />
	}

	function onSetRequest(newRequest: Api.ReportOptions | undefined) {
		if (newRequest) {
			newRequest = normaliseRequest(newRequest)
		}
		setRequest(newRequest)
	}

	return (
		<Switch>
			<Route path={`${path}`} exact strict>
				<Screen title="Report" includePageHeader={false} class="-report" additionalClass="screen-report" breadcrumbText={breadcrumbText} breadcrumbLink={breadcrumbLink}>
					<Report report={report ? report : getReport.result.report} setReport={setReport} filters={getFilters.result} request={request} setRequest={onSetRequest} reports={getReports.response.result}/>
				</Screen>
			</Route>
			<Route path={`${path}/print`} exact strict>
				<PrintReport report={getReport.result.report} filters={getFilters.result} request={request} setRequest={onSetRequest} reports={getReports.response.result}/>
			</Route>
			<Route path={`${path}/details/:detailIndex`} render={route => (
				<Screen title="Report Details" includePageHeader={false} class="-report" additionalClass="screen-report" breadcrumbText="report" breadcrumbLink={pathToSurveyReport(params.surveyId)}>
					<ReportDetails key={route.match.params.detailIndex} report={report ? report : getReport.result.report} filters={getFilters.result} reports={getReports.response.result} request={request} setRequest={onSetRequest} setReport={setReport}/>
				</Screen>
			)}>
			</Route>
		</Switch>
	)
}
