import * as React from 'react'

interface Props {
	centred?: boolean
	large?: boolean
}

const LoadingInline: React.FC<Props> = function(props) {
	return (
		<div className={'loading-spinner' + (props.centred ? ' -centred' : ' -inline') + (props.large ? ' -large' : '')}><p className="spinner">Loading…</p></div>
	)
}

export default LoadingInline
