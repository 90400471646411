import React from 'react'
import { Api } from 'typescript-fetch-api'
import Screen from 'modules/common/components/Screen'
import ProgressBar from './ProgressBar'
import ReactMarkdown from 'react-markdown'

interface Props {
	section: DeepReadonly<Api.ParticipationSurvey.Section>
	question: DeepReadonly<Api.Question>
	currentMatrixQuestion?: string
}

const QuestionScreen: React.FC<Props> = function({ section, question, children, currentMatrixQuestion }) {
	return (
		<Screen title={section.name} includePageHeader={false} class="-survey" additionalHeader={<ProgressBar questionId={currentMatrixQuestion ? currentMatrixQuestion : question.id} />}>
			<div className="content-header">
				<h1 className="headline-text">{section.name}</h1>
				<div className="body-text -large">
					<ReactMarkdown children={question.introduction ? question.introduction : section.intro || ''} />
				</div>
			</div>
			{children}
		</Screen>
	)
}

export default QuestionScreen
