import * as React from 'react'
import { QuestionProps } from './types'
import { useController, Formalities } from 'formalities'
import QuestionScreen from './QuestionScreen'
import { AnswerRequest, MultichoiceQuestion } from 'typescript-fetch-api'
import { useKeyboardNavigation } from '../hooks'
import { uniqueToastError } from 'modules/common/functions'
import QuestionHeader from './QuestionHeader'

interface FormState {
	answers: number[]
	stringValue: string
}

export default function QuestionCheckboxes(props: QuestionProps<MultichoiceQuestion>) {
	const { section, question, questionIndex } = props

	useKeyboardNavigation(onPrev, onNext)
	
	const controller = useController<FormState>({
		answers: question.answerIndices === undefined ? [] : question.answerIndices as number[],
		stringValue: question.answeredStringValue ? question.answeredStringValue : '',
	})

	controller.addChangeListener(form => save(form))

	async function save(form: FormState) {
		const otherIndex = form.answers.indexOf(question.options.length)
		const answerReq: AnswerRequest = {
			questionId: question.id,
			intValueArray: form.answers,
		}

		if (question.allowOtherAnswer && otherIndex !== -1) {
			if (form.stringValue) {
				answerReq.stringValue = form.stringValue
			} else {
				const newAnswers = [...form.answers]
				newAnswers.splice(otherIndex, 1)
				answerReq.intValueArray = newAnswers
			}
		}

		if (answerReq.intValueArray === question.answerIndices) {
			return
		}

		const saved = await props.onAnswerQuestion(answerReq)
		if (!saved) {
			controller.snapshot('answers').setValue(question.answerIndices === undefined ? [] : question.answerIndices as number[])
		}
	}

	function onNext(evt?: React.FormEvent) {
		evt && evt.preventDefault()

		const form = controller.snapshot().value
		if (!question.optional && form.answers.length === 0) {
			uniqueToastError('Please choose an answer before continuing')
			return false
		} else if (!question.optional && question.allowOtherAnswer && form.answers.indexOf(question.options.length) !== -1 && !form.stringValue) {
			uniqueToastError('Please specify answer before continuing')
			return false
		}
		props.onNext()
	}

	function onSelectOther(evt: React.FocusEvent) {
		const snapshot = controller.snapshot()
		const form: FormState = {
			...snapshot.value,
			answers: [...snapshot.value.answers],
		}
		if (form.answers.indexOf(question.options.length) === -1) {
			form.answers.push(question.options.length)
		}
		snapshot.setValue(form)
	}

	function canProgress() {
		const form = controller.snapshot().value
		if (!question.optional && form.answers.length === 0) {
			return false
		} else if (!question.optional && question.allowOtherAnswer && form.answers.indexOf(question.options.length) !== -1 && !form.stringValue) {
			return false
		} else {
			return true
		}
	}

	function onPrev(evt?: React.MouseEvent) {
		evt && evt.preventDefault()
		props.onPrev()
	}

	function onExit(evt: React.MouseEvent) {
		evt.preventDefault()
		props.onExit()
	}

	return (
		<QuestionScreen section={section} question={question}>
			<div className="survey-question">
				<QuestionHeader question={question} questionIndex={questionIndex} />
				<div className="form-row">
					{question.options &&
					<ul className="option-inputs -large">
						{question.options.map((option, optionIndex) => (
							<li key={optionIndex} className="option -checkbox">
								<label className="label">
									<Formalities.MultiCheckable type="checkbox" className="checkbox" controller={controller} prop="answers" checkedValue={optionIndex} />
									<span className="substitute"></span>
									{option}
								</label>
							</li>
						))}
						{
							question.allowOtherAnswer &&
							<li className="option -checkbox">
								<label className="label">
									<Formalities.MultiCheckable type="checkbox" className="checkbox" controller={controller} prop="answers" checkedValue={question.options.length} />
									<span className="substitute"></span>
									<div className="other">
										<span className="label">Other</span>
										<div className="form-input -text">
											<Formalities.Text controller={controller} prop="stringValue" className="field" placeholder="Please specify…" onFocus={onSelectOther} />
										</div>
									</div>
								</label>
							</li>
						}
					</ul>
					}
				</div>
					
				<div className="footer-actions">
					<div className="button-group">
						<a href="/" className="button-link -secondary -back" onClick={onPrev}>Back</a>
						<a href="/" className="button-link -text" onClick={onExit}>Exit survey</a>
					</div>
					<div className="button-group -right">
						<input type="submit" className={'button-link -action -next ' + (canProgress() ? '' : '-disabled')} value="Next" onClick={onNext} />
					</div>
				</div>

				<div className="keyboard-nav">
					<p className="option">You can also use your <span className="key">&larr;</span> <span className="key">&rarr;</span> arrow keys to navigate forward &amp; back</p>
				</div>
			</div>
		</QuestionScreen>
	)
}
