import * as actions from './actions'
import * as auth from '../auth/actions'
import * as admin from '../admin/actions'
import * as participant from '../participant/actions'
import { SagaIterator } from 'redux-saga'
import { callApi } from 'modules/api/functions'
import sagaPromiseWrapper, { makeSagaPromiseFunc } from 'modules/root/saga-promise-wrapper'
import { takeEvery } from 'redux-saga/effects'
import { RootStoreState } from 'modules/root'
import { Store } from 'redux'
import { readyAction } from 'modules/root/actions'
import getApi from 'modules/api'
import { selectCurrentMembership } from 'modules/admin/selectors'
import { selectParticipation } from 'modules/participant/selectors'

/**
 * When the user chooses a membership we load the details and setup the store.
 */
const loadKnowledgeLibrary = makeSagaPromiseFunc<RootStoreState>(async function({ dispatch, select, store }, action) {
	dispatch(actions.loadKnowledgeLibrary.started(undefined))

	const currentMembership = select(selectCurrentMembership)
	const currentParticipation = select(selectParticipation)
	try {
		/* Use account id from current admin setting if possible, otherwise use current participant setting if possible, otherwise undefined and the server decides */
		const result = await callApi(() => getApi().knowledgeLibraryApi.getKnowledgeLibrary(currentMembership?.account.id || currentParticipation?.account.id), store)
		dispatch(actions.loadKnowledgeLibrary.done({
			result,
		}))
	} catch (error) {
		dispatch(actions.loadKnowledgeLibrary.failed({
			error: error as Error,
		}))
	}
})

export default function* knowledgeLibrarySagas(store: Store<RootStoreState>): SagaIterator {
	const sw = sagaPromiseWrapper(store)
	yield takeEvery(readyAction, sw(loadKnowledgeLibrary))
	yield takeEvery(auth.loggedIn, sw(loadKnowledgeLibrary))
	yield takeEvery(auth.loggedOut, sw(loadKnowledgeLibrary))
	yield takeEvery(admin.setCurrentMembership, sw(loadKnowledgeLibrary))
	yield takeEvery(participant.actionSetParticipation, sw(loadKnowledgeLibrary))
}
