import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentMembership } from 'modules/admin/selectors'
import { Redirect } from 'react-router'
import { selectLoggedIn } from 'modules/auth/selectors'

export default function HomeRedirect() {
	const currentAccount = useSelector(selectCurrentMembership)
	const loggedIn = useSelector(selectLoggedIn)
	
	if (currentAccount && loggedIn) {
		return <Redirect to="/admin" />
	} else if (loggedIn) {
		return <Redirect to="/p/" />
	} else {
		return <Redirect to="/auth/sign-in" />
	}
}
