import React, { useCallback } from 'react'
import { withModal, ModalStyle } from 'modules/common/modal-hoc'
import { useApiEffect } from 'modules/common/hooks'
import { useParams } from 'react-router'
import LoadingInline from 'modules/common/components/LoadingInline'
import ErrorScreen from 'modules/common/components/ErrorScreen'
import { renderArticleContent, renderArticleImage } from 'modules/knowledgelibrary/components/Article'
import ArticleList from 'modules/knowledgelibrary/components/ArticleList'

export default withModal(
	function ReportArticle() {
		const params = useParams<{ articleId: string }>()
		const getArticle = useApiEffect(api => api.knowledgeLibraryApi.getArticle(params.articleId), [params.articleId])

		const onClickGoBack = useCallback((function() {
			window.history.back()
		}), [])

		if (getArticle.error) {
			return <ErrorScreen error={getArticle.error} />
		} else if (!getArticle.result) {
			return (
				<div className="modal-layout">
					<div className="article-page">
						<div className="header">
							<div className="width-limit -article">
								<h2 className="icon-text subheading -article">Resource library</h2>
							</div>
						</div>
						<div className="body">
							<div className="width-limit -article">
								<LoadingInline />
							</div>
						</div>
					</div>
				</div>
			)
		}
		
		const article = getArticle.result

		return (
			<div className="modal-layout">
				<div className="article-page">
					<div className="header">
						<div className="width-limit -article">
							<h2 className="icon-text subheading -article">Resource library</h2>
						</div>
					</div>
					<div className="body">
						<div className="width-limit -article">
							<div className="content body-text -hero">
								<h4 className="title -link" onClick={onClickGoBack}>{'< back'}</h4>
								<h1 className="headline-text">{article.name}</h1>
								{renderArticleImage(article)}
								{article.contents.map(renderArticleContent)}
								{article.relatedArticles && article.relatedArticles?.length > 0 && 
									<>
										<div className="related">
											<h3 className="subheading-text">Related articles</h3>
											<div className="articles">
												<ArticleList articles={article.relatedArticles}/>
											</div>
										</div>
									</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	},

	ModalStyle.Large,

)