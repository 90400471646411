import * as React from 'react'
import Screen from 'modules/common/components/Screen'
import Logo from './Logo'
import { useSelector } from 'react-redux'
import { selectSurvey } from 'modules/participant/selectors'

export default function Exit() {
	const survey = useSelector(selectSurvey)

	if (!survey) {
		throw new Error('No survey')
	}

	return (
		<Screen title="Exit" includePageHeader={false} class="-survey">
			<div className="survey-introduction">
				<Logo survey={survey} />
				<h1 className="headline-text -centered">Survey ended</h1>
				<div className="body body-text -large">
					<p>
						Your answers have been saved so you can come back and continue, just use the link you were sent to access the survey again.
						If you do not return to complete the survey your answers will not be&nbsp;saved.
					</p>
				</div>
			</div>
		</Screen>
	)
}