import React, { useEffect } from 'react'
import LoadingScreen from 'modules/common/components/LoadingScreen'
import { logoutRequest } from '../actions'
import { useDispatch } from 'react-redux'

/**
 * A component that logs out the current user.
 * 
 * We use this so that we don't dispatch the logout request while rendering a logged in view, as it
 * will immediately re-render and fail.
 */
const Logout: React.FC = function() {
	const dispatch = useDispatch()

	useEffect(function() {
		dispatch(logoutRequest({ relocate: true }))	
	})
	return <LoadingScreen />
}

export default Logout
