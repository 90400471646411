import { AccessToken } from './types'
import * as s from './selectors'
import * as a from './actions'

import { getAuthConfig } from './index'

import * as url from 'url'
import { Store } from 'redux'
import { RootStoreState } from 'modules/root'

let refreshingToken = false
let refreshingTokenResolves: ((accessToken: AccessToken) => void)[] = []
let refreshingTokenRejects: ((error: Error) => void)[] = []

async function fetchAccessToken(options: RequestInit): Promise<AccessToken> {
	const response = await fetch(getAuthConfig().tokenEndpoint, options)
	if (response.ok) {
		return await response.json() as AccessToken
	} else {
		const contentType = response.headers.get('Content-Type')
		if (contentType && contentType.indexOf('json') !== -1) {
			const msg = await response.json()
			if (msg.error) {
				if (msg.error === 'invalid_grant') {
					throw new Error('Invalid username or password')
				} else if (msg.error === 'standdown' && msg.error_description) {
					throw new Error(msg.error_description)
				} else {
					throw new Error(`Auth request failed: ${msg.error}`)
				}
			} else {
				throw new Error(`Auth request failed: ${response.statusText}`)
			}
		} else {
			throw new Error(`Auth request failed: ${response.statusText}`)
		}
	}
}

/** Attempt to obtain an AccessToken with the given credentials. */
export async function authenticate(username: string, password: string, totp?: string): Promise<AccessToken> {
	const config = getAuthConfig()
	const query = {
		client_id: config.clientId,
		client_secret: config.clientSecret,
		grant_type: 'password',
		username,
		password,
		totp,
	}
	const formData = url.format({ query }).substring(1)

	const options: RequestInit = {
		method: 'POST',
		body: formData,
		headers: {
			'content-type': 'application/x-www-form-urlencoded',
		},
	}
	return await fetchAccessToken(options)
}

/** Attempt to refresh the AccessToken using the given refresh token.
 * Returns a new AccessToken.
 */
function refreshAccessToken(refreshToken: string): Promise<AccessToken> {
	const config = getAuthConfig()
	const query = {
		client_id: config.clientId,
		client_secret: config.clientSecret,
		grant_type: 'refresh_token',
		refresh_token: refreshToken,
	}
	const formData = url.format({ query }).substring(1)

	const options: RequestInit = {
		method: 'POST',
		body: formData,
		headers: {
			'content-type': 'application/x-www-form-urlencoded',
		},
	}
	return fetchAccessToken(options)
}

export async function refreshAuthentication(store: Store<RootStoreState>): Promise<AccessToken> {
	const accessToken = s.selectAccessToken(store.getState())
	if (!accessToken) {
		throw new Error('Not logged in')
	}
	if (!accessToken.refresh_token) {
		throw new Error('Cannot refresh this token')
	}

	if (!refreshingToken) {
		refreshingToken = true
		try {
			const refreshedAccessToken = await refreshAccessToken(accessToken.refresh_token)
			refreshingToken = false
			const saveResolves = refreshingTokenResolves
			refreshingTokenResolves = []
			saveResolves.forEach(resolve => resolve(refreshedAccessToken))

			store.dispatch(a.refreshedToken(refreshedAccessToken))
			return refreshedAccessToken
		} catch (error) {
			refreshingToken = false
			const saveRejects = refreshingTokenRejects
			refreshingTokenRejects = []
			saveRejects.forEach(reject => reject(error as Error))

			// TODO: this is nasty relying on the error message format
			if ((error as Error).message === 'Invalid username or password') {
				store.dispatch(a.refreshTokenFailed({
					date: Date.now(),
					refreshToken: accessToken.refresh_token,
				}))
				throw new Error('Not logged in')
			}
			throw error
		}
	} else {
		/* The token is already being refreshed, so wait for the result of that operation, so we don't
		   double-up our refresh requests.
		 */
		return await new Promise<AccessToken>((resolve, reject) => {
			refreshingTokenResolves.push(resolve)
			refreshingTokenRejects.push(reject)
		})
	}
}

function revokeToken(token: string): Promise<boolean> {
	const config = getAuthConfig()
	const query = {
		client_id: config.clientId,
		client_secret: config.clientSecret,
		token,
	}
	const formData = url.format({ query }).substring(1)

	const options: RequestInit = {
		method: 'POST',
		body: formData,
		headers: {
			'content-type': 'application/x-www-form-urlencoded',
		},
	}
	
	return fetch(getAuthConfig().revokeTokenEndpoint, options)
		.then(response => {
			return response.ok
		})
}

export function logout(accessToken: string, refreshToken?: string): Promise<boolean> {
	if (refreshToken) {
		return revokeToken(refreshToken)
	} else {
		return revokeToken(accessToken)
	}
}

