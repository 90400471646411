import { useCallback, useState, useEffect, DependencyList } from 'react'
import produce from 'immer'
import { useStore } from 'react-redux'
import { callApi, CallApiFunction, apiErrorToMessages } from 'modules/api/functions'
import api from 'modules/api'
import { useHistory } from 'react-router'
import { ApiError } from 'modules/api/types'

interface CursoredResult {
	nextCursor?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface UseCursoredDataResult<T, E = any> {
	response: PromiseEffectResult<T, E>
	loadMore?: () => void
	refresh: () => void
	reset: () => void
}

/**
 * A helper hook to work with cursored API endpoints.
 * @param name the name of the data being loaded; used in error messages
 * @param func the function to return the next cursored result
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useCursoredDataEffect<T extends CursoredResult, E = any>(func: (cursor?: string) => Promise<T>, deps: DependencyList): UseCursoredDataResult<T> {
	const [version, setVersion] = useState(0)
	const refresh = useCallback(function() {
		setVersion(version + 1)
	}, [version, setVersion])

	const [result, setResult] = useState<PromiseEffectResult<T, E>>({
		loading: true,
		refresh,
	})
	const [nextCursor, setNextCursor] = useState<string | undefined>(undefined)

	const reset = useCallback(function() {
		setResult({
			loading: true,
			refresh,
		})
		setNextCursor(undefined)
		setVersion(version + 1)
	}, [refresh, version])

	const fetchData = useCallback(
		async function(cursor: string | undefined) {
			return await func(cursor)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		deps,
	)

	useEffect(() => {
		let unmounted = false
		fetchData(undefined).then(function(next) {
			if (unmounted) {
				return
			}
			setResult({
				result: next,
				refresh,
			})
			setNextCursor(next.nextCursor)
		}).catch(function(error) {
			if (unmounted) {
				return
			}
			setResult({
				error,
				refresh,
			})
		})
		return () => {
			unmounted = true
		}
	}, [fetchData, refresh])

	function loadMore() {
		fetchData(nextCursor).then(function(next) {
			setResult(result => !result ? { result: next, refresh } : produce(result, draft => {
				/* Merge all array properties */

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const draftAny = draft.result as any
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const resultAny = next as any
				for (const k in draftAny) {
					if (typeof draftAny[k] === 'object' && draftAny[k].length !== undefined) {
						if (typeof resultAny[k] === 'object' && resultAny[k].length !== undefined) {
							draftAny[k] = [...draftAny[k], ...resultAny[k]]
						}
					}
				}
			}))

			setNextCursor(next.nextCursor)
		}).catch(function(error) {
			setResult({
				error,
				refresh,
			})
		})
	}

	return {
		response: result,
		loadMore: nextCursor ? loadMore : undefined,
		refresh,
		reset,
	}
}

type PromiseEffectResult<T, E> = PromiseEffectSuccess<T> | PromiseEffectError<E> | PromiseEffectLoading

interface PromiseEffectSuccess<T> {
	result: T
	error?: undefined
	loading?: false
	refresh: () => void
}

interface PromiseEffectError<E> {
	result?: undefined
	error: E
	loading?: false
	refresh: () => void
}

interface PromiseEffectLoading {
	result?: undefined
	error?: undefined
	loading: true
	refresh: () => void
}

/**
 * A helper hook to work with promises; returns the result of the promise or `undefined` if the promise
 * hasn't been resolved yet. If the promise results in an error, the hook throws an error.
 * NB: this hook requires a special react-hooks/exhaustive-deps eslint rule in order to lint
 * @param func a function that returns a promise
 * @param deps the dependencies for the function, as for `useCallback`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function usePromiseEffect<T, E = any>(func: () => () => Promise<T>, deps: DependencyList): PromiseEffectResult<T, E> {
	const [version, setVersion] = useState(0)
	const refresh = useCallback(function() {
		setVersion(version + 1)
	}, [version, setVersion])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	func = useCallback(func, deps)
	const [result, setResult] = useState<PromiseEffectResult<T, E>>({
		loading: true,
		refresh,
	})

	useEffect(() => {
		let unmounted = false
		
		setResult({
			loading: true,
			refresh,
		})

		func()().then(function(result) {
			if (unmounted) {
				return
			}
			setResult({
				result,
				refresh,
			})
		}).catch(function(error) {
			if (unmounted) {
				return
			}
			setResult({
				error,
				refresh,
			})
		})

		return () => {
			unmounted = true
		}
	}, [func, refresh])

	return result
}

/**
 * A help hook to work with API calls; returns the result of the API call or `undefined` if the
 * API call is still loading. If the API call results in an error, the hook throws an error.
 * NB: this hook requires a special react-hooks/exhaustive-deps eslint rule in order to lint
 * @param func the API call function
 * @param deps the dependencies for the function, as for `useCallback`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useApiEffect<T, E = any>(func: CallApiFunction<T>, deps: DependencyList): PromiseEffectResult<T, E> {
	const callApi = useCallApi()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return usePromiseEffect(() => () => callApi(func), [...deps, callApi])
}

export function useCallApi(): <T>(func: CallApiFunction<T>) => Promise<T> {
	const store = useStore()
	return useCallback((func) => callApi(() => func(api()), store), [store])
}

export function useApiError(error: ApiError): string[] {
	const history = useHistory()

	useEffect(function() {
		if (error instanceof Error && error.message === 'Not logged in') {
			history.replace('/auth/sign-in')
		}
	})

	return apiErrorToMessages(error)
}
