import * as React from 'react'
import Screen from 'modules/common/components/Screen'
import { useCallApi } from 'modules/common/hooks'
import { apiErrorToMessage } from 'modules/api/functions'
import { useHistory } from 'react-router'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'

interface Props {
	onUpdateProfile: () => void
}

const Setup2FA: React.FC<Props> = function(props) {
	const [currentPassword, setCurrentPassword] = React.useState('')
	const [dataUri, setDataUri] = React.useState<string | undefined>()
	const [token, setToken] = React.useState<string | undefined>()
	const [authenticationCode, setAuthenticationCode] = React.useState<string | undefined>()

	const callApi = useCallApi()
	const history = useHistory()

	async function onSubmitPassword(evt: React.FormEvent) {
		evt.preventDefault()
		
		try {
			const result = await callApi(api => api.personApi.startTotp({ password: currentPassword }))
			setDataUri(result.qrcode)
			setToken(result.token)
			uniqueToastSuccess('Success! Please proceed to finish setting up 2FA.')
		} catch (error) {
			uniqueToastError(`Unable to set up 2FA.\n${apiErrorToMessage(error)}`)
		}
	}

	async function onSubmitTwoFactorAuth(evt: React.FormEvent) {
		evt.preventDefault()

		if (token && authenticationCode) {
			try {
				await callApi(api => api.personApi.confirmTotp({ token: token, totpCode: authenticationCode }))
				uniqueToastSuccess('2FA has now been set up for your account.')
				props.onUpdateProfile()
				history.goBack()
			} catch (error) {
				uniqueToastError(`Unable to set up 2FA.\n${apiErrorToMessage(error)}`)
			}
		}
	}
	
	function goBack(evt: React.FormEvent) {
		evt.preventDefault()
		history.goBack()
	}

	return (
		<Screen title="Your profile" class="two-factor -narrow" includePageHeader={false}>
			<div className="content-header">
				<h1 className="headline-text">Set up two factor authentication</h1>
			</div>

			<form>
				{!dataUri && 
				<>
					<div className="form-field -text">
						<h2 className="subheading-text">Please enter your password</h2>
					</div>
					<div className="form-field -adjacent">
						<label className="label">Current Password</label>
						<div className="form-input -text">
							<input className="field" type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} autoComplete="current-password" />
						</div>
					</div>
				</>
				}
				{
					dataUri &&
					<>
						<div className="form-field -text">
							<h2 className="subheading-text">Please scan the QR code below in your authentication app</h2>
							<p>After scanning the QR code, please enter the authentication code from your authentication app, and submit this form to complete the 2FA setup.</p>
						</div>
						<div className="code-container">
							<img src={dataUri} alt="data-uri"/>
							<div className="form-field -stacked -guttered">
								<label className="label -large">Authentication code</label>
								<div className="form-input -text">
									<input className="field" type="text" value={authenticationCode || ''} onChange={(e) => setAuthenticationCode(e.target.value)} autoComplete="current-password" />
								</div>
							</div>
						</div>
					</>
				}
				<div className="footer-actions">
					<div className="button-group -right">
						<a href="/" className="button-link -secondary" onClick={goBack}>Cancel</a>
						<input type="submit" className="button-link -action" value="Submit" onClick={authenticationCode && authenticationCode !== '' ? onSubmitTwoFactorAuth : onSubmitPassword}/>
					</div>
				</div>
			</form>
		</Screen>
	)
}

export default Setup2FA
