import React from 'react'
import { Question } from 'typescript-fetch-api'
import ReactMarkdown from 'react-markdown'

interface Props {
	question: DeepReadonly<Question>
	questionIndex: number
}

const QuestionHeader: React.FC<Props> = ({ question, questionIndex }) => {
	return (
		<>
			<h2 className="subheading-text">
				<span className="number">{questionIndex + 1}. </span>
				<div className="body-text -large"><ReactMarkdown children={question.title} /></div>&nbsp;
				{question.optional && <span className="optional">(optional)</span>}
			</h2>
			{
				question.subtitle &&
				<ReactMarkdown children={question.subtitle} className="question-subtitle" />
			}
		</>
	)
}

export default QuestionHeader
