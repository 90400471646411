import React, { useState } from 'react'
import { Api } from 'typescript-fetch-api'
import { pathToSurveyReportDetails } from '../navigation'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'
import { useCallApi } from 'modules/common/hooks'
import { apiErrorToMessage } from 'modules/api/functions'
import { getConfiguration } from 'modules/api'
import { useSelector } from 'react-redux'
import { selectCurrentMembership } from 'modules/admin/selectors'
import MyReactQuill from 'modules/common/components/MyReactQuill'
import { useController } from 'formalities'
import { useCurrentPerson } from 'modules/admin/hooks'
import Popover, { ArrowContainer } from 'react-tiny-popover'
import ReportFilters from './ReportFilters'
import ReportComparisons from './ReportComparisons'
import LoadingInline from 'modules/common/components/LoadingInline'
import produce from 'immer'
import ArcChart from 'modules/common/components/ArcChart'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'
import ReportCombination from './ReportCombination'

interface Props {
	report: Api.SurveyReport
	setReport?: (report: Api.SurveyReport) => void
	printMode?: boolean
	filters?: Api.GetFilters200Response
	reports?: Api.GetReports200Response
	request?: Api.ReportOptions
	setRequest: (newRequest: Api.ReportOptions | undefined) => void
}

interface ExecutiveSummary {
	text: string
	disabled: boolean 
}

const Report: React.FC<Props> = function(props) {
	const params = useParams<{ surveyId: string }>()
	const currentAccount = useSelector(selectCurrentMembership)
	const person = useCurrentPerson()
	const [isFilterPopoverOpen, setFilterPopoverOpen] = useState(false)
	const [isComparePopoverOpen, setComparePopoverOpen] = useState(false)
	const [isCombinePopoverOpen, setCombinePopoverOpen] = useState(false)
	const { report, request, setRequest } = props
	const hideExecSummary = request && (request.compareToSurvey || request.compareToNormative || request.compareToFilter || request.filter || request.combineWithSurveys) ? true : false
	
	const scheduling = report.survey.scheduling
	const callApi = useCallApi()
	const executiveSummaryController = useController<ExecutiveSummary>({
		text: report.executiveSummary ? report.executiveSummary : '',
		disabled: true,
	})
	const executiveSummary = executiveSummaryController.snapshot().value
	const compareToSurvey = request && request.compareToSurvey && props.reports ? props.reports.reports.find(s => s.survey.id === request.compareToSurvey) : undefined
	const combiningSurveys = request && request.combineWithSurveys && report.combinedSurveys ? report.combinedSurveys : undefined

	function onDownloadReport(evt: React.MouseEvent) {
		evt.preventDefault()
		
		async function doDownload() {
			const tokenResponse = await callApi(api => api.surveyApi.requestReportDownload(report.survey.id, request))
			const token = tokenResponse.token
			
			const frontend = `${window.location.protocol}//${window.location.host}`
			const url = `${getConfiguration().basePath}/survey/${report.survey.id}/report/download?token=${encodeURIComponent(token)}&frontend=${encodeURIComponent(frontend)}`
			window.location.assign(url)
		}

		doDownload().catch(error => {
			uniqueToastError(`Couldn’t print the report.\n\n${apiErrorToMessage(error)}`)
		})
	}

	function getSurveyDuration() {
		const start = report.survey.whenOpen ? report.survey.whenOpen : scheduling?.openAt
		const end = report.survey.whenClosed ? report.survey.whenClosed : scheduling?.closeAt

		if (report.survey.status === Api.SurveySummary.StatusEnum.OPEN) {
			return `Opened ${moment(start).format('Do MMM YYYY')}`
		} else if (moment(start).isSame(end, 'month')) {
			return moment(start).format('Do') + ' — ' + moment(end).format('Do MMM YYYY')
		} else {
			return moment(start).format('Do MMM') + ' — ' + moment(end).format('Do MMM YYYY')
		}
	}

	async function saveExecutiveSummary(evt: React.FormEvent) {
		evt.preventDefault()
		executiveSummaryController.snapshot().setValue({
			text: executiveSummary.text,
			disabled: true,
		})
		try {
			const response = await callApi(api => api.surveyApi.patchSurveyReport(params.surveyId, { executiveSummary: executiveSummary.text }))
			props.setReport && props.setReport(response.report)
			uniqueToastSuccess('Successfully updated executive summary')
		} catch (error) {
			uniqueToastError('Failed to update executive summary')
		}
	}

	function onSetRequest(newRequest: Api.ReportOptions | undefined) {
		setRequest(newRequest)
		setFilterPopoverOpen(false)
		setComparePopoverOpen(false)
		setCombinePopoverOpen(false)
	}

	function onRemoveQuestionFilter(questionId: string, answerIndex: number) {
		setRequest(produce(request, draft => {
			if (!draft || !draft.filter || !draft.filter.questions) {
				return draft
			}
			const i = draft.filter.questions.findIndex(q => q.question === questionId)
			if (i !== -1) {
				const j = draft.filter.questions[i].answers.indexOf(answerIndex)
				if (j !== -1) {
					draft.filter.questions[i].answers.splice(j, 1)
				}
			}
		}))
	}

	function onRemoveParticipantGroupFilter(participantGroupId: string) {
		setRequest(produce(request, draft => {
			if (!draft || !draft.filter || !draft.filter.participantGroups) {
				return draft
			}

			const i = draft.filter.participantGroups.indexOf(participantGroupId)
			if (i !== -1) {
				draft.filter.participantGroups.splice(i, 1)
			}
		}))
	}

	function onRemoveCompareToSurvey() {
		setRequest(produce(request, draft => {
			if (!draft) {
				return draft
			}

			draft.compareToSurvey = undefined
			draft.compareToNormative = false
		}))
	}
	
	function onRemoveCombinedSurvey(surveyId: string) {
		setRequest(produce(request, draft => {
			if (!draft || !draft.combineWithSurveys) {
				return draft
			}
			const i = draft.combineWithSurveys.indexOf(surveyId)
			if (i !== -1) {
				draft.combineWithSurveys.splice(i, 1)

				if (draft.combineWithSurveys.length === 0) {
					draft.combineWithSurveys = undefined
				}
			}
		}))
	}

	function getParticipantsCompletedCount() {
		let count = report.survey.participants.completedCount

		if (combiningSurveys) {
			for (let i = 0; i < combiningSurveys.length; i++) {
				count += combiningSurveys[i].participants.completedCount
			}
		}

		return count
	}

	function getParticipantsCompletedPercentage() {
		const completedCount = getParticipantsCompletedCount()
		let totalCount = report.survey.participants.totalCount

		if (combiningSurveys) {
			for (let i = 0; i < combiningSurveys.length; i++) {
				totalCount += combiningSurveys[i].participants.totalCount
			}
		}

		return Math.round((completedCount) * 100 / totalCount)
	}

	return (
		<>
			<div className="report-header">
				<div className="header">
					<div className="title-row">
						<div className="title-wrapper">
							<h1 className="headline-text -hero">{report.survey.name}</h1>
							{report.survey.status === Api.SurveySummary.StatusEnum.OPEN && <h2 className="headline-text">Progress report</h2>}
						</div>
						<p className="options _no-print">
							<Popover 
								isOpen={isFilterPopoverOpen}
								position={'bottom'}
								content={({ position, targetRect, popoverRect }) => (
									<ArrowContainer
										position={position}
										targetRect={targetRect}
										popoverRect={popoverRect}
										arrowColor={'white'}
										arrowSize={10}
										arrowStyle={{ opacity: 1 }}
									>
										{props.filters ? (
											<ReportFilters filters={props.filters} request={request} setRequest={onSetRequest} />
										) : (
											<LoadingInline />
										)}
									</ArrowContainer>
								)}
								onClickOutside={() => setFilterPopoverOpen(false)}
							><button className="icon-text -filter" onClick={() => setFilterPopoverOpen(!isFilterPopoverOpen)}>Filter</button></Popover>
							<Popover 
								isOpen={isComparePopoverOpen}
								position={'bottom'}
								content={({ position, targetRect, popoverRect }) => (
									<ArrowContainer
										position={position}
										targetRect={targetRect}
										popoverRect={popoverRect}
										arrowColor={'white'}
										arrowSize={10}
										arrowStyle={{ opacity: 1 }}
									>
										{props.reports ? (
											<ReportComparisons reports={props.reports} request={props.request} setRequest={onSetRequest} surveyToCompareId={report.survey.id}/>
										) : (
											<LoadingInline />
										)}
									</ArrowContainer>
								)}
								onClickOutside={() => setComparePopoverOpen(false)}
							><button className="icon-text -compare _no-print" onClick={() => setComparePopoverOpen(!isComparePopoverOpen)}>Compare</button></Popover>
							<Popover 
								isOpen={isCombinePopoverOpen}
								position={'bottom'}
								content={({ position, targetRect, popoverRect }) => (
									<ArrowContainer
										position={position}
										targetRect={targetRect}
										popoverRect={popoverRect}
										arrowColor={'white'}
										arrowSize={10}
										arrowStyle={{ opacity: 1 }}
									>
										{props.reports ? (
											<ReportCombination reports={props.reports} request={props.request} setRequest={onSetRequest} currentSurveyId={report.survey.id} currentSurveyTemplateId={report.survey.template?.id}/>
										) : (
											<LoadingInline />
										)}
									</ArrowContainer>
								)}
								onClickOutside={() => setCombinePopoverOpen(false)}
							><button className="icon-text -report-combine _no-print" onClick={() => setCombinePopoverOpen(!isCombinePopoverOpen)}>Combine</button></Popover>
						</p>
					</div>
					<div className="details">
						{currentAccount && <p className="detail body-text -large"><strong>{currentAccount.account.name}</strong></p>}
						<p className="detail _no-print"><span className="report-print icon-text -chart" onClick={onDownloadReport}>Download report</span></p>
					</div>
				</div>	
			</div>
			{request && (request.filter || (request.compareToSurvey || request.compareToNormative) || combiningSurveys) &&
				<div className="report-filtering">
					{request.filter &&
						<div className="filters">
							<h3 className="ui-heading -large -dark">Filters:</h3>
							{request.filter && request.filter.questions && request.filter.questions.map((q, questionIndex) => {
								const question = props.filters && props.filters.questions.find(fq => fq.id === q.question)
								return (
									<React.Fragment key={questionIndex}>
										<h3 className="body-text -small">{question ? question.title : 'Unknown'}</h3>
										<ul className="tag-set">
											{q.answers.map(answerIndex => (
												<li className="tag" key={answerIndex}>
													{question && question.type === Api.QuestionTypeEnum.Multi ? question.options[answerIndex] : '???'}
													<button className="remove" onClick={evt => {
														evt.preventDefault()
														onRemoveQuestionFilter(q.question, answerIndex) 
													}}>&multi;</button>
												</li>
											))}
										</ul>
									</React.Fragment>
								)
							})}
							{request.filter && request.filter.participantGroups && (
								<>
									<h3 className="body-text -small">Participant Groups</h3>
									<ul className="tag-set">
										{request.filter.participantGroups.map((participantGroupId, pgIndex) => {
											const participantGroup = props.filters && props.filters.participantGroups.find(pg => pg.id === participantGroupId)
											return (
												<li className="tag" key={pgIndex}>
													{participantGroup ? participantGroup.name : 'Unknown'}
													<button className="remove" onClick={evt => {
														evt.preventDefault()
														onRemoveParticipantGroupFilter(participantGroupId) 
													}}>&multi;</button>
												</li>
											)
										})}
									</ul>
								</>
							)}
							<button className="icon-unit -small -edit _no-print" onClick={() => setFilterPopoverOpen(true)}>Edit filters</button>
						</div>
					}
					{(request.compareToSurvey || request.compareToNormative) &&
						<div className="filters">
							<h3 className="ui-heading -large -dark">Comparing:</h3>
							<ul className="tag-set">
								<li className="tag">
									{compareToSurvey ? compareToSurvey.survey.name : request.compareToNormative ? 'Normative' : 'Unknown'}
									<button className="remove _no-print" onClick={evt => {
										evt.preventDefault()
										onRemoveCompareToSurvey()
									}}>&multi;</button>
								</li>
							</ul>
							<button className="icon-unit -small -edit _no-print" onClick={(evt) => {
								evt.preventDefault()
								setComparePopoverOpen(!isComparePopoverOpen) 
							}}>Edit comparison survey</button>
						</div>
					}					
					{combiningSurveys &&
						<div className="filters">
							<h3 className="ui-heading -large -dark">Combining:</h3>
							<ul className="tag-set">
								{combiningSurveys.map((survey, index) => (
									<li key={'compareSurvey' + index} className="tag">
										{survey.name}
										<button className="remove _no-print" onClick={evt => {
											evt.preventDefault()
											onRemoveCombinedSurvey(survey.id)
										}}>&multi;</button>
									</li>
								))}
							</ul>
							<button className="icon-unit -small -edit _no-print" onClick={(evt) => {
								evt.preventDefault()
								setCombinePopoverOpen(!isCombinePopoverOpen) 
							}}>Edit combined surveys</button>
						</div>
					}
					<button className="clear _no-print" onClick={() => setRequest(undefined)}>Clear all</button>
				</div>
			}
			<div className="report-overview results-block">
				{report.score !== undefined &&
					<div className="detail">
						<h3 className="subheading-text">Overview</h3>
						<ArcChart 
							score={report.score} 
							compareToScore={compareToSurvey ? compareToSurvey.score : (request?.compareToNormative && report.compareToScores) ? report.compareToScores![0] : undefined}
							reportName={report.survey.name}
							showComparativeKeys={(compareToSurvey || request?.compareToNormative) ? true : false}
							comparativeName={compareToSurvey ? compareToSurvey.survey.name : request?.compareToNormative ? 'Normative' : undefined}
						/>
					</div>
				}
				<div className="detail">
					<h3 className="subheading-text">Participants</h3>
					<p className="value">{getParticipantsCompletedCount()}</p>
					<p className="body-text -small">{getParticipantsCompletedPercentage()}% Participants<span className="_md-up"> completed</span></p>
				</div>
				<div className="detail">
					<h3 className="subheading-text">Duration</h3>
					<p className="value -small">
						{getSurveyDuration()}
					</p>
				</div>
			</div>

			<div className="body-text -large">
				{report.article && (
					<>
						<h2 className="subheading-text">{report.article.name}</h2>
						{renderArticleContent(report.article)}
					</>
				)}
				{
					!props.printMode && person.superAdmin && !hideExecSummary ? 
						<>
							<h2 className="subheading-text">Executive summary</h2>
							<div className="summaryinput">
								<div className="form-field">
									<div className="form-input -text">
										<MyReactQuill
											theme="bubble" 
											placeholder="Enter Umbrella executive summary to appear on the report."
											className="field body-text"
											value={executiveSummary.text || ''}
											onChange={(content, delta, sources, editor) => {
												executiveSummaryController.snapshot().setValue({
													text:	editor.getHTML(),
													disabled: false,
												})
											}} 
										/>
									</div>
								</div>
								<input type="submit" className="button-link -action" value="Save" onClick={saveExecutiveSummary} disabled={executiveSummary.disabled}/> 
							</div>
						</>
						: report.executiveSummary && !hideExecSummary ? 
							<>
								<h2 className="subheading-text">Executive summary</h2>
								<div dangerouslySetInnerHTML={{ __html: report.executiveSummary }} />
							</>
							: null
				}
			</div>
			<div className="overview-columns">
				{
					report.sections.map((section, index) => (
						<div key={index} className="column">
							<div className="overview-section results-block">
								<div className="details">
									<h2 className="headline-text">{section.name}</h2>
									<div className="body-text -large">
										{section.description}
									</div>
									<Link to={pathToSurveyReportDetails(params.surveyId, index)} className="button-link -secondary _no-print">View details</Link>
								</div>
								<ArcChart 
									score={section.score} 
									compareToScore={(compareToSurvey || request?.compareToNormative) && section.compareToScores ? section.compareToScores[0] : undefined}
								/>
							</div>
						</div>
					))
				}
			</div>
		</>
	)
}

function renderArticleContent(article: Api.Article) {
	const content = article.contents[0]

	if (content.contentType === 'Html') {
		return (
			<p dangerouslySetInnerHTML={{ __html: content.html }}></p>
		)
	} else {
		return null
	}
}

export default Report
