import { AuthConfig } from './types'

let config: AuthConfig | undefined

export function getAuthConfig(): AuthConfig {
	if (!config) {
		throw new Error('setAuthConfig has not been called')
	}
	return config
}

export function setAuthConfig(newConfig: AuthConfig) {
	config = newConfig
}
