import { RootStoreState } from 'modules/root'

/**
 * 
 * @param state Select the current admin access token from the root store state.
 */

export const selectCurrentMembership = (state: RootStoreState) => state.admin.currentMembership
export const selectPersonDetails = (state: RootStoreState) => state.admin.personDetails
export const selectAdminLoading = (state: RootStoreState) => state.admin.loading
