import * as React from 'react'
import { Api } from 'typescript-fetch-api'
import { questionTypeToName } from 'modules/admin/functions'
import { renderFreeTextQuestion, renderMultiQuestion } from 'modules/admin/survey/components/EditSection'
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'
import { XYCoord } from 'dnd-core'
import { pathToCustomSurveyQuestion } from '../navigation'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

export interface QuestionProps {
	question: Api.ScaleQuestion | Api.MultichoiceQuestion | Api.MatrixSubQuestion | Api.MatrixQuestion | Api.FreeTextQuestion
	index: number
	moveQuestion: (dragIndex: number, hoverIndex: number) => void
	survey: Api.SurveyDetails
	section: Api.SurveySection
	onRemoveQuestion: (evt: React.FormEvent, question: (Api.ScaleQuestion | Api.MultichoiceQuestion | Api.MatrixSubQuestion | Api.MatrixQuestion | Api.FreeTextQuestion)) => void
}

interface DragItem {
	index: number
	id: string
	type: string
}

export const Question: React.FC<QuestionProps> = (props) => {
	const dropRef = React.useRef<HTMLTableRowElement>(null)
	const dragRef = React.useRef(null)
	const [openQuestion, setOpenQuestion] = React.useState(false)
	const { survey, section, question } = props
	
	const [, drop] = useDrop({
		accept: 'question',
		hover(item: DragItem, monitor: DropTargetMonitor) {
			if (!dropRef.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = props.index

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}

			// Determine rectangle on screen
			const hoverBoundingRect = dropRef.current!.getBoundingClientRect()
			// Get vertical middle
			const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

			// Determine mouse position
			const clientOffset = monitor.getClientOffset()

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}

			// Time to actually perform the action
			props.moveQuestion(dragIndex, hoverIndex)

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex
		},
	})

	const [{ isDragging }, drag, preview] = useDrag({
		item: { type: 'question', id: props.question.id, index: props.index },
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
		type: 'question',
	})

	const opacity = isDragging ? 0 : 1

	preview(drop(dropRef))
	drag(dragRef)

	return (
		<tr key={props.question.id} ref={dropRef} style={{ opacity }}>
			<th scope="row" onClick={() => setOpenQuestion(!openQuestion)}>
				<span className={'question' + (openQuestion ? ' -active' : '') + (question.custom ? ' -custom' : '')}>
					<ReactMarkdown
						children={question.title}
						components={{
							p: 'span',
						}}
					/>
				</span>
				{
					openQuestion &&
					(question.type === Api.QuestionTypeEnum.Multi ? renderMultiQuestion(question) : 
						question.type === Api.QuestionTypeEnum.FreeText ? renderFreeTextQuestion(question) : null)
				}
			</th>
			<td>{questionTypeToName(props.question.type)}</td>
			<td className="options rightalign">
				{
					question.custom &&
					<Link className="icon-unit -edit" to={pathToCustomSurveyQuestion(survey, section.id, question.id)}>Edit</Link>
				}
				<button className="icon-unit -remove" onClick={(e) => props.onRemoveQuestion(e, question)}>Remove</button>
				<span ref={dragRef} className="icon-unit -order">Order</span>
			</td>
		</tr>
	)
}