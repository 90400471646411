import { actionCreatorFactory } from 'typescript-fsa'

const actionCreator = actionCreatorFactory()

/**
 * The store is ready to use. Fired before readyAction to give internals a chance to get
 * ready before the UI.
 */
export const storeReadyAction = actionCreator('STORE_READY')

/**
 * The application is ready to use.
 */
export const readyAction = actionCreator('READY')
