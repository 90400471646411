/** Platform specific implementations. */
import { Store } from 'redux'
import { ConfigurationParameters } from 'typescript-fetch-api'

import { AuthConfig } from 'modules/auth/types'
import { RootStoreState } from 'modules/root'
import { PlatformSupport, Environment } from '..'
import { selectAccessToken } from 'modules/auth/selectors'

import { Capacitor } from '@capacitor/core'

let _store: Store<RootStoreState>

function basePath() {
	if (process.env.REACT_APP_API_BACKEND) {
		/* Edit the API backend in the .env file, and then restart webpack */
		return process.env.REACT_APP_API_BACKEND
	} if (Capacitor.isNative) {
		return 'https://v2.umbrella.org.nz/api/v0'
	} else {
		return '/api/v0'
	}
}

const platformSupportImplementation: PlatformSupport = {

	init: (store) => {
		_store = store
	},

	alert: (message: string) => {
		alert(message)
	},

	confirm: (message: string, title: string, confirmAction: string): Promise<boolean> => {
		const result = window.confirm(message)
		return Promise.resolve(result)
	},

	createApiConfigurationParams: (): ConfigurationParameters => {
		return {
			basePath: basePath(),
			authorization: (name: string, scopes?: string[]) => {
				const accessToken = selectAccessToken(_store.getState())
				if (accessToken) {
					return accessToken.access_token
				} else {
					// TODO the generated API doesn't support not returning a valid access token
					// We send a string, rather than nothing, so the server responds with a 401 rather
					// than a 403. A 401 signals that we need to authenticate, so the rest of our code
					// handles the failure appropriately. See handleDiscard.
					return null
				}
			},
		}
	},

	createAuthConfiguration: (): AuthConfig => {
		return {
			tokenEndpoint: `${basePath()}/auth/token`,
			revokeTokenEndpoint: `${basePath()}/auth/revoke`,
			clientId: 'web1',
			clientSecret: ';#7"#>5]Inc+=Ha`6SBMoy7FeTCj`k',
		}
	},

	environment: (): Environment => {
		const hostname = window.location.hostname
		if (hostname === 'umbrella.dev.cactuslab.com') {
			return Environment.Staging
		} else if (hostname === 'localhost' || hostname.startsWith('127.') || hostname.startsWith('10.') || hostname.endsWith('.local') || hostname.endsWith('.cactuslab.com')) {
			return Environment.Development
		} else if (window.location.hostname.indexOf('test-') !== -1) {
			return Environment.Test
		} else {
			return Environment.Live
		}
	},
}

export default platformSupportImplementation
