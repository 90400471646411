import * as React from 'react'
import Screen from './Screen'
import { ApiError } from 'modules/api/types'
import { useHistory } from 'react-router'
import { useApiError } from '../hooks'

interface Props {
	error: ApiError
}

function ErrorScreen(props: Props) {
	const history = useHistory()

	function goBack() {
		history.goBack()
	}
	
	const messages = useApiError(props.error)
	return (
		<Screen title="Error" includePageHeader={false} class="error-message">
			<div className="body-text -large">
				{messages.map((m, i) => <p key={i}>{m}</p>)}
			</div>
			<div className="button-group">
				<button className="button-link -action" onClick={goBack}>Back</button>
			</div>
		</Screen>
	)
}

export default ErrorScreen
