import React from 'react'
import { useSelector } from 'react-redux'
import { selectReady } from 'modules/root/selectors'

interface Props {
	waitComponent: React.ComponentType
}

const ReadyGate: React.FC<Props> = function(props) {
	const ready = useSelector(selectReady)
	if (ready) {
		return <>{props.children}</>
	} else {
		const WaitComponent = props.waitComponent
		return <WaitComponent />
	}
}

export default ReadyGate
