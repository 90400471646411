
import { Switch, Route, useRouteMatch } from 'react-router'
import YourProfile from './YourProfile'
import Settings from './Settings'
import Setup2FA from './Setup2FA'
import { useDispatch } from 'react-redux'
import { apiErrorToMessage } from 'modules/api/functions'
import { useCallApi } from 'modules/common/hooks'
import { setPersonDetails } from 'modules/admin/actions'
import { uniqueToastError } from 'modules/common/functions'

export default function AccountRouter() {
	const { path } = useRouteMatch()
	const dispatch = useDispatch()
	const callApi = useCallApi()

	async function updatePersonDetails() {
		try {
			const person = await callApi(api => api.personApi.getMyDetails())
			dispatch(setPersonDetails(person))
		} catch (error) {
			uniqueToastError(`Unable to save changes.\n${apiErrorToMessage(error)}`)
		}
	}
	
	return (
		<Switch>
			<Route 
				path={`${path}/your-profile/setup-2fa`}
				render={props => <Setup2FA onUpdateProfile={updatePersonDetails} />}
			/>
			<Route 
				path={`${path}/your-profile`}
				component={YourProfile}
			/>
			<Route 
				path={`${path}/settings`}
				component={Settings}
			/>
		</Switch>
	)
}
