import React from 'react'
import Screen from 'modules/common/components/Screen'
import { useCursoredDataEffect, useCallApi } from 'modules/common/hooks'
import { useCurrentAccount } from 'modules/admin/hooks'
import { Api } from 'typescript-fetch-api'
import LoadingInline from 'modules/common/components/LoadingInline'
import ErrorInline from 'modules/common/components/ErrorInline'
import ArcChart from 'modules/common/components/ArcChart'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { pathToSurveyReport } from '../navigation'
import { pathToSurvey } from 'modules/admin/survey/navigation'
import { useCanView } from 'modules/admin/hooks'

const AdminReporting: React.FC = () => {
	const account = useCurrentAccount()
	const callApi = useCallApi()
	const { response } = useCursoredDataEffect((cursor) => callApi(api => api.accountApi.getReports(account.id, cursor, Api.SortSurveys.ReportAvailable)), [account.id, callApi])
	const canView = useCanView()
	
	return (
		<Screen title="Reporting">
			<div className="content-header -split">
				<h1 className="headline-text">Reporting</h1>
			</div>
			<div className="report-tiles">
				{response.loading && (
					<div className="loading-container -centered -large">
						<LoadingInline />
					</div>
				)}
				{response.error && (
					<div className="errors body-text -large -centered">
						<ErrorInline error={response.error} />
					</div>
				)}
				{response.result?.reports.length === 0 && 
					<div className="errors body-text -large">
						<p>No survey reports are available yet</p>
					</div>
				}
				{response.result && response.result.reports.map(report => (
					<div className="report-tile" key={report.survey.id}>
						<div className="container">
							<ArcChart score={report.score} variant="small" />
							<div className="content body-text">
								<h3 className="subheading-text"><Link to={{ pathname: pathToSurveyReport(report.survey.id), state: { from: window.location.pathname } }} className="link">{report.survey.name}</Link></h3>
								<p>
									{report.survey.status === Api.SurveySummary.StatusEnum.OPEN ?
										<>In progress since {moment(report.survey.whenOpen).format('d MMMM, YYYY')}<br/></> :
										<>Completed {moment(report.survey.whenClosed).format('d MMMM, YYYY')}<br/></>
									}
									{report.survey.participants.completedCount} participants
								</p>
							</div>
							<div className={'button-group' + (canView ? ' -split' : ' -right')}>
								{canView &&
									<Link to={{ pathname: pathToSurvey(report.survey), state: { from: window.location.pathname } }} className="button-link -text">Survey details</Link>
								}
								<Link to={{ pathname: pathToSurveyReport(report.survey.id), state: { from: window.location.pathname } }} className="button-link -action">View</Link>
							</div>
						</div>
					</div>
				))}
			</div>
		</Screen>
	)	
}

export default AdminReporting
