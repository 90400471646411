import { Api } from 'typescript-fetch-api'
import produce from 'immer'
import moment from 'moment'

export function normaliseRequest(request: Api.ReportOptions) {
	request = produce(request, draft => {
		/* Remove empty questions */
		if (draft && draft.filter && draft.filter.questions) {
			draft.filter.questions = draft.filter.questions.filter(q => q.answers.length > 0)
			if (draft.filter.questions.length === 0) {
				draft.filter.questions = undefined
			}
		}

		/* Remove empty participant groups */
		if (draft && draft.filter && draft.filter.participantGroups && draft.filter.participantGroups.length === 0) {
			draft.filter.participantGroups = undefined
		}

		if (draft && draft.filter && !draft.filter.questions && !draft.filter.participantGroups) {
			draft.filter = undefined
		}
	})

	if (!request.filter && !request.compareToFilter && !request.compareToSurvey && !request.compareToNormative && !request.combineWithSurveys) {
		return undefined
	}
	return request
}

export function schedulingToText(scheduling?: Api.Scheduling, whenOpen?: string) {
	let schedulingText = ''
	if (whenOpen) {
		schedulingText = 'Opened ' + moment(whenOpen).fromNow() + (scheduling && scheduling.closeAt ? (', ' + moment().to(moment(scheduling.closeAt), true) + ' remaining.') : ', no closing date.')
	} else if (scheduling) {
		schedulingText = 'Opens in ' + moment(scheduling.openAt).fromNow(true) + '.'
	} else {
		schedulingText = 'No scheduled start date.'
	}
	return schedulingText
}
