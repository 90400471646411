import * as React from 'react'
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'
import { XYCoord } from 'dnd-core'

export interface AnswerProps {
	index: number
	answer: string
	moveAnswer: (dragIndex: number, hoverIndex: number) => void
	onRemoveAnswer: (evt: React.FormEvent, answerToRemove: string) => void
	editAnswer: (evt: React.FormEvent, answerToEdit: string) => void
}

interface DragItem {
	index: number
	id: string
	type: string
}

export const Answer: React.FC<AnswerProps> = (props) => {
	const { answer, onRemoveAnswer, editAnswer } = props
	const dropRef = React.useRef<HTMLTableRowElement>(null)
	const dragRef = React.useRef(null)
	
	const [, drop] = useDrop({
		accept: 'answer',
		hover(item: DragItem, monitor: DropTargetMonitor) {
			if (!dropRef.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = props.index

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}

			// Determine rectangle on screen
			const hoverBoundingRect = dropRef.current!.getBoundingClientRect()
			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

			// Determine mouse position
			const clientOffset = monitor.getClientOffset()

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}

			// Time to actually perform the action
			props.moveAnswer(dragIndex, hoverIndex)

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex
		},
	})

	const [{ isDragging }, drag, preview] = useDrag({
		item: { type: 'answer', id: answer, index: props.index },
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
		type: 'answer',
	})

	const opacity = isDragging ? 0 : 1

	preview(drop(dropRef))
	drag(dragRef)

	return (
		<div className="answer" key={answer} ref={dropRef} style={{ opacity }}>
			<p className="text">{answer}</p>
			<div className="options">
				<button className="icon-unit -edit" onClick={(e) => editAnswer(e, answer)}>Edit</button>
				<button className="icon-unit -remove" onClick={(e) => onRemoveAnswer(e, answer)}>Remove</button>
				<span ref={dragRef} className="icon-unit -order">Order</span>
			</div>
		</div>
	)
}