import moment from 'moment'
import Screen from 'modules/common/components/Screen'
import { useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { selectSurvey, selectParticipation } from 'modules/participant/selectors'
import { findQuestionSection, getSurveyPositionPercentage, findLastQuestion, questionComplete } from '../functions'
import { SurveyState } from './types'
import { Api } from 'typescript-fetch-api'
import Logo from './Logo'
import { useRouteMatch, useLocation } from 'react-router'

interface LocationState {
	from?: string
}

export default function Landing() {
	const { path } = useRouteMatch()
	const location = { ...useLocation<LocationState>().state }
	const survey = useSelector(selectSurvey)
	if (!survey) {
		throw new Error('No survey')
	}

	const participation = useSelector(selectParticipation)
	if (!participation) {
		throw new Error('No participation')
	}

	const surveyState = determineSurveyState(participation, survey)
	if (surveyState === SurveyState.Completed) {
		return (
			<Redirect to="/p/report/" />
		)
	}

	const surveyCompleteButNotSubmitted = surveyState === SurveyState.Normal && questionComplete(findLastQuestion(survey)) ? true : false
	if (surveyCompleteButNotSubmitted) {
		if (!location.from) {
			return (
				<Redirect to={`${path}/complete`} />
			)
		}
	}

	const nextSection = participation.nextQuestionUUID ? findQuestionSection(participation.nextQuestionUUID, survey) : undefined

	return (
		<Screen title={survey.name} includePageHeader={false} class="-survey">
			<div className="survey-introduction">
				<Logo survey={survey} />
				<h1 className="headline-text -centered">{survey.name}</h1>
				{
					surveyState === SurveyState.Normal && !surveyCompleteButNotSubmitted &&
					<>
						<div className="body body-text -large">
							{renderWelcome(survey)}
							{renderInstructions(survey)}
						</div>
						<Link to={survey.privacyStatementHtml ? 'survey/privacy' : 'survey/0'} className="button-link -hero -action">Start the survey</Link>
					</>
				}
				{
					surveyState === SurveyState.Normal && surveyCompleteButNotSubmitted &&
					<>
						<div className="body body-text -large">
							{renderWelcome(survey)}
							{renderInstructions(survey)}
							<p className="status"><strong>You have completed 100% of the survey, but have not yet submitted it.</strong></p>
						</div>
						<div className="button-group -centered">
							<Link to="survey/0" className="button-link -hero -secondary">Start again</Link>
							<Link to={`${path}/complete`} className="button-link -hero -action">Continue the survey</Link>
						</div>
					</>
				}
				{
					surveyState === SurveyState.Partial && 
					<>
						<div className="body body-text -large">
							{renderWelcome(survey)}
							{renderInstructions(survey)}
							<p className="status"><strong>You have completed {getSurveyPositionPercentage(participation.nextQuestionUUID!, survey)}% of the survey already.</strong></p>
						</div>
						<div className="button-group -centered">
							<Link to="survey/0" className="button-link -hero -secondary">Start again</Link>
							{
								nextSection !== undefined &&
								<Link to={'survey/' + nextSection + '/' + participation.nextQuestionUUID} className="button-link -hero -action">Continue the survey</Link>
							}
						</div>
					</>
				}
				{
					surveyState === SurveyState.Expired &&
					<div className="body body-text -large">
						{renderWelcome(survey)}
						<p>This survey closed at <strong>{moment(survey.dueDate).format('h:mma on MMMM Do')}</strong>.</p>
					</div>
				}
				{
					surveyState === SurveyState.Pending &&
					<div className="body body-text -large">
						{renderWelcome(survey)}
						<p>This survey is not open yet.</p>
					</div>
				}
				{survey.contact.name &&
				<div className="footer body-text">
					<p>
						This survey was published by {survey.contact.name}.<br />
						{survey.contact.email && 
						<>For any questions or feedback please email <a href={`mailto:${survey.contact.email}`}>{survey.contact.email}</a>.</>
						}
					</p>
				</div>
				}
			</div>
		</Screen>
	)
}

function determineSurveyState(participation: Api.ParticipationInfo, survey: DeepReadonly<Api.ParticipationSurvey>) {
	if (participation.whenCompleted) {
		return SurveyState.Completed
	} else if (survey.status === Api.ParticipationSurvey.StatusEnum.Closed) {
		return SurveyState.Expired
	} else if (survey.status === Api.ParticipationSurvey.StatusEnum.Pending) {
		return SurveyState.Pending
	} else if (participation.nextQuestionUUID) {
		return SurveyState.Partial
	} else {
		return SurveyState.Normal
	}
}

export function renderWelcome(survey: DeepReadonly<Api.ParticipationSurvey>) {
	if (survey.welcome) {
		return <div dangerouslySetInnerHTML={{ __html: survey.welcome }} />
	} else {
		return (
			<p>Thanks for taking part in our survey. <strong>Your answers will be anonymous</strong>, and the aggregate data will be used to get a feeling for the state of wellbeing among the staff and find out any areas that need improvement.</p>
		)
	}
}

export function renderInstructions(survey: DeepReadonly<Api.ParticipationSurvey>) {
	return (
		<p>
			{survey.expectedDuration &&
			<>The survey should only take around <strong>{survey.expectedDuration} minutes</strong> to complete. </>
			}
			{survey.dueDate ? (
				<>You can come back to it at any point if you need to take a break, but it must be completed no later than <strong>{moment(survey.dueDate).format('h:mma on MMMM Do')}</strong>.</>
			) : (
				<>You can come back to it at any point if you need to take a break.</>
			)}
		</p>
	)
}
