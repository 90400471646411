import React from 'react'
import Screen from 'modules/common/components/Screen'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectSurvey, selectParticipation } from 'modules/participant/selectors'
import { useKeyboardNavigation } from '../hooks'

export default function PrivacyStatement() {
	const survey = useSelector(selectSurvey)
	const history = useHistory()

	useKeyboardNavigation(onPrev, onNext)
	
	if (!survey) {
		throw new Error('No survey')
	}

	const participation = useSelector(selectParticipation)
	if (!participation) {
		throw new Error('No participation')
	}
	
	function onNext(evt?: React.FormEvent) {
		evt && evt.preventDefault()
		history.push('0')
	}

	function onPrev(evt?: React.MouseEvent) {
		evt && evt.preventDefault()
		history.push({
			pathname: '../survey',
			state: { 
				from: window.location.pathname,
			},
		})
	}

	return (
		<Screen title={survey.name} class="-survey" includePageHeader={false}>
			<div className="privacy-notice">
				<h1 className="headline-text">For your information</h1>
				<h2 className="subheading-text">Data protection and privacy</h2>
				<div className="body-text" dangerouslySetInnerHTML={{ __html: survey.privacyStatementHtml || '' }} />
				<div className="footer-actions">
					<div className="button-group">
						<a href="/" className="button-link -secondary -back" onClick={onPrev}>Back</a>
					</div>
					<div className="button-group -right">
						<input type="submit" className="button-link -action -next" value="Start the survey" onClick={onNext} />
					</div>
				</div>
			</div>
		</Screen>
	)
}