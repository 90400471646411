import React from 'react'
import { Api } from 'typescript-fetch-api'
import { Formalities, useController, OptionTypeObject } from 'formalities'
import produce from 'immer'

interface Props {
	reports: Api.GetReports200Response
	request?: Api.ReportOptions
	surveyToCompareId?: string
	setRequest: (newRequest: Api.ReportOptions | undefined) => void
}

const ReportComparisons: React.FC<Props> = (props) => {
	const { reports, request } = props
	const availableReports = reports.reports
	const controller = useController<string | undefined>(
		(request && request.compareToSurvey) ? 
			request.compareToSurvey : 
			(request && request.compareToNormative) ? 'normative' : undefined)

	const index = availableReports.findIndex((survey) => survey.survey.id === props.surveyToCompareId)
	if (index !== -1) {
		availableReports.splice(index, 1)
	}
	const options: OptionTypeObject<string>[] = availableReports.map(s => ({
		value: s.survey.id,
		text: s.survey.name,
	}))
	options.unshift({
		value: undefined,
		text: 'Choose a survey…',
	}, {
		value: 'normative',
		text: 'Normative',
	})

	function onClear(evt: React.MouseEvent) {
		evt.preventDefault()

		controller.snapshot().setValue(undefined)
	}

	function onCompare(evt: React.MouseEvent) {
		evt.preventDefault()

		const surveyId = controller.snapshot().value

		const newRequest = produce(props.request, draft => {
			if (!draft) {
				draft = {}
			}

			if (surveyId === 'normative') {
				draft.compareToSurvey = undefined
				draft.compareToNormative = true
			} else if (surveyId) {
				draft.compareToSurvey = surveyId
			} else {
				draft.compareToSurvey = undefined
			}
			return draft
		})
		props.setRequest(newRequest)
	}

	return (
		<div className="popup-menu -filters">
			<h2 className="ui-heading -dark">Compare to...</h2>
			<div className="form-input -select">
				<Formalities.Select 
					controller={controller} 
					prop="this" 
					options={options} 
					className="select"
				/>
			</div>
			<div className="footer-actions -borderless">
				<div className="button-group -split">
					<button type="button" className="button-link -small -secondary" onClick={onClear}>Clear</button>
					<input type="submit" className="button-link -small -action" value="Compare" onClick={onCompare} />
				</div> 
			</div>
		</div>
	)
}

export default ReportComparisons