/**
 * Authentication sagas.
 * 
 * Responsible for the process of logging in, refreshing tokens and logging out.
 */

import { take, call, put, race, select } from 'redux-saga/effects'
import * as actions from './actions'
import { authenticate, logout } from './functions'
import { SagaIterator } from 'redux-saga'
import { storeReadyAction } from 'modules/root/actions'
import { AccessToken } from './types'
import { selectAccessToken } from './selectors'
import platform from 'modules/platform'
import { selectOfflineOutboxQueueLength } from 'modules/api/selectors'

/** Saga handling the state of being logged out. */
function* loggedOutSaga(): SagaIterator {
	/* Wait for a login request, but we also look for a logout request */
	const loginRaceResult = yield race({
		loginRequest: take(actions.login.started),
		programmaticLogin: take(actions.programmaticLogin),
		logout: take(actions.logoutRequest),
	})

	if (loginRaceResult.logout) {
		/* A logout request may come through if we're only partially logged out. That is we don't
		   have an access token, but we do have a username set, and we want the user to re-login
		   without logging out. But they can choose to logout completely, so we handle that here.
		 */
		yield call(handleLogoutRequest, loginRaceResult.logout)
		return
	}

	if (loginRaceResult.loginRequest) {
		const action = loginRaceResult.loginRequest as actions.LoginStarterAction
		const login = action.payload

		try {
			/* Attempt to login, but also let a logout request interrupt our login request */
			const loggingInRaceResult = yield race({
				loginResult: call(authenticate, login.username, login.password, login.totpCode),
				logout: take(actions.logoutRequest),
			})

			if (loggingInRaceResult.loginResult) {
				const accessToken = loggingInRaceResult.loginResult as AccessToken
				yield put(actions.login.done({ params: login, result: accessToken }))
				yield put(actions.loggedIn({
					username: login.username,
					rememberMe: login.rememberMe,
					accessToken,
					intent: login.intent,
				}))
			} else if (loggingInRaceResult.logout) {
				yield call(handleLogoutRequest, loggingInRaceResult.logout)
			}
		} catch (error) {
			yield put(actions.login.failed({ params: login, error: error as Error }))
		}
	} else if (loginRaceResult.programmaticLogin) {
		const action = loginRaceResult.programmaticLogin as ReturnType<typeof actions.programmaticLogin>
		yield call(handleProgrammaticLogin, action.payload)
	}
}

function* handleProgrammaticLogin(request: actions.ProgrammaticLoginRequest) {
	yield put(actions.loggedIn({
		accessToken: request.accessToken,
		rememberMe: request.rememberMe,
		intent: request.intent,
	}))
}

/** Saga handling the state of being logged in. */
function* loggedInSaga(): SagaIterator {
	try {
		const raceResult = yield race({
			logout: take(actions.logoutRequest),
			loggedInError: take(actions.loggedInError),
			refreshTokenFailed: take(actions.refreshTokenFailed),
			programmaticLogin: take(actions.programmaticLogin),
		})

		if (raceResult.logout) {
			yield call(() => handleLogoutRequest(raceResult.logout))
		} else if (raceResult.loggedInError) {
			yield put(actions.loggedOut({ relocate: true }))
		} else if (raceResult.refreshTokenFailed) {
			/* The routing saga handles this to take us to the login form to reauth. */
			yield put(actions.loggedOut({ relocate: false }))
		} else if (raceResult.programmaticLogin) {
			/* Programmatic login - we don't logout previous login */
			yield put(actions.loggedOut({ relocate: false }))
			const action = raceResult.programmaticLogin as ReturnType<typeof actions.programmaticLogin>
			yield call(handleProgrammaticLogin, action.payload)
		}
	} catch (error) {
		yield put(actions.loggedInError(error as Error))
		yield put(actions.loggedOut({ relocate: true }))
	}
}

/** When we request to logout we must check if we have an offline queue that will be lost if
 *  we actually logout. So we do a confirm step first whenever there is a logout request and our
 *  offline queue is not empty.
 */
function* handleLogoutRequest(action: actions.LoggedOutAction): SagaIterator {
	const queueLength = yield select(selectOfflineOutboxQueueLength)
	if (queueLength > 0) {
		const confirmResult = (yield call(
			platform.confirm, 
			'Are you sure you want to logout? You have unsynced updates that will be lost.',
			'Warning!',
			'Logout')) as boolean
		if (confirmResult) {
			yield call(() => doLogout(action))
		}
	} else {
		yield call(() => doLogout(action))
	}
}

function* doLogout(action: actions.LoggedOutAction): SagaIterator {
	const accessToken = (yield select(selectAccessToken)) as ReturnType<typeof selectAccessToken>
	if (accessToken) {
		yield call(logout, accessToken.access_token, accessToken.refresh_token)
	}
	yield put(actions.loggedOut(action.payload))
}

/** Yields a boolean result, whether there is a user logged in or not. */
export function* loggedIn(): SagaIterator {
	const accessToken = (yield select(selectAccessToken)) as ReturnType<typeof selectAccessToken>
	return accessToken !== undefined
}

export default function* saga(): SagaIterator {
	/* Wait for the state to be ready, as we read the state in the loggedIn function.
	The state isn't immediately ready, as we use react-persist to load persisted state,
	which happens asynchronously.
	*/
	yield take(storeReadyAction)

	while (true) {
		const isLoggedIn = (yield call(loggedIn)) as boolean

		if (isLoggedIn) {
			yield call(loggedInSaga)
		} else {
			yield call(loggedOutSaga)
		}
	}
}
