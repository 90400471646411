import React from 'react'
import Screen from 'modules/common/components/Screen'
import { useCursoredDataEffect, useCallApi } from 'modules/common/hooks'
import { useCurrentAccount, useCanEdit } from 'modules/admin/hooks'
import { Api } from 'typescript-fetch-api'
import ArcChart from 'modules/common/components/ArcChart'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { pathToSurveyReport } from 'modules/admin/report/navigation'
import { pathToSurvey, pathToNewSurvey } from 'modules/admin/survey/navigation'
import { surveyStatusToString, surveyStatusToClassName } from 'modules/admin/functions'
import { schedulingToText } from 'modules/admin/report/functions'
import LoadingScreen from 'modules/common/components/LoadingScreen'
import { useCanView } from 'modules/admin/hooks'

const AdminDashboard: React.FC = () => {
	const account = useCurrentAccount()
	const callApi = useCallApi()
	const canEdit = useCanEdit()
	const canView = useCanView()
	const { response } = useCursoredDataEffect((cursor) => callApi(api => api.accountApi.getReports(account.id, cursor, Api.SortSurveys.ReportAvailable)), [account.id, callApi])
	const { response: surveyResponse } = useCursoredDataEffect((cursor) => callApi(api => api.accountApi.getSurveys(account.id, cursor, undefined, undefined)), [account.id, callApi])
	const inProgressSurveys = surveyResponse.result?.surveys && surveyResponse.result?.surveys.filter(survey => survey.status === 'OPEN')
	const upcomingSurveys = surveyResponse.result?.surveys && surveyResponse.result?.surveys.filter(survey => survey.status === 'READY' || survey.status === 'SCHEDULED' || survey.status === 'DRAFT')
	
	return (
		<>
			{(response.loading || surveyResponse.loading) ? 
				<LoadingScreen/> :
				<Screen title="Dashboard">
					<div className="content-header -split">
						<h1 className="headline-text -hero">{account.name}'s dashboard</h1>
						{canEdit &&
							<Link to={pathToNewSurvey()} className="button-link -action">New survey</Link>
						}
					</div>
					{ (canView && inProgressSurveys && inProgressSurveys.length > 0) &&
						<>
							<div className="content-header -split">
								<h2 className="headline-text">In progress <span className="status-slug -small -open">Open</span></h2>
							</div>
							<div className="dashboard-panels">
								{
									inProgressSurveys.map((survey) =>
										<div key={survey.id} className="dashboard-panel">
											<div className="content">
												<h3 className="subheading-text">{survey.name}</h3>
												<p className="body-text">{survey.participants.totalCount} participant{survey.participants.totalCount === 1 ? '' : 's'}. {schedulingToText(survey.scheduling, survey.whenOpen)}</p>
												<div className="progress">
													<div className="bar-chart -background">
														{survey.participants.totalCount ?
															<>
																<div className="bar -background" style={{ width: `${Math.round((survey.participants.completedCount + survey.participants.inProgressCount) * 100 / survey.participants.totalCount)}%` }}>
																	<svg viewBox="0 0 16 16" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><rect x="0" width="16" height="16"></rect></svg>
																</div>
																<div className="bar -great" style={{ width: `${Math.round((survey.participants.completedCount) * 100 / survey.participants.totalCount)}%` }}>
																	<svg viewBox="0 0 16 16" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><rect x="0" width="16" height="16"></rect></svg>
																</div>
															</> : null
														}
													</div>
													<div className="context">
														{survey.participants.totalCount ?
															<p className="ui-heading">{Math.round((survey.participants.completedCount) * 100 / survey.participants.totalCount)}% Participants<span className="_md-up"> completed</span></p> :
															null
														}
														<div className="chart-key -small">
															<ul className="values">
																<li className="value -great"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6"></circle></svg>Completed</li>
																<li className="value -background"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6"></circle></svg>In progress</li>
															</ul>
														</div>
													</div>
												</div>
												<div className="footer">
													<Link to={{ pathname: pathToSurvey(survey), state: { from: window.location.pathname } }} className="button-link -secondary -small">View, edit or close</Link>
												</div>
											</div>
										</div>,
									)
								}
							</div>
						</>
					}
					{(canView && (!upcomingSurveys || upcomingSurveys.length === 0)) && 
						<>
							<div className="content-header -split">
								<h2 className="headline-text">Upcoming surveys</h2>
							</div>
							<div className="dashboard-panel -empty">
								<div className="content">
									<p className="body-text -hero"><strong>You have no upcoming surveys.</strong></p>
									{canEdit &&
										<Link to={pathToNewSurvey()} className="button-link -action">Create a survey</Link>
									}
								</div>
							</div>
						</>
					}
					{(canView && upcomingSurveys && upcomingSurveys.length > 0) && 
						<>
							<div className="content-header -split">
								<h2 className="headline-text">Upcoming surveys</h2>
								<Link to="/admin/survey/your-surveys" className="all">All surveys</Link>
							</div>
							<div className="dashboard-panels">
								{
									upcomingSurveys.map(survey => 
										<div key={survey.id} className="dashboard-panel">
											<div className="content">
												<h3 className="subheading-text">{survey.name}</h3>
												<div className="footer">
													<p className="body-text"><span className={`status-slug -small ${surveyStatusToClassName(survey.status)}`}>{surveyStatusToString(survey.status)}</span> {schedulingToText(survey.scheduling)}</p>
													<Link to={{ pathname: pathToSurvey(survey), state: { from: window.location.pathname } }} className="button-link -secondary -small">View or edit</Link>
												</div>
											</div>
										</div>,
									)
								}
							</div>
						</>
					}
					{ response.result && response.result.reports.length > 0 &&
						<>
							<div className="content-header -split">
								<h2 className="headline-text">Recent reports</h2>
								<Link to="/admin/reporting" className="all">All reports</Link>
							</div>
							<div className="report-tiles">
								{response.result && response.result.reports.map(report => (
									<div className="report-tile" key={report.survey.id}>
										<div className="container">
											<ArcChart score={report.score} variant="small" />
											<div className="content body-text">
												<h3 className="subheading-text"><Link to={{ pathname: pathToSurveyReport(report.survey.id), state: { from: window.location.pathname } }} className="link">{report.survey.name}</Link></h3>
												<p>
													{report.survey.status === Api.SurveySummary.StatusEnum.OPEN ?
														<>In progress since {moment(report.survey.whenOpen).format('d MMMM, YYYY')}<br/></> :
														<>Completed {moment(report.survey.whenClosed).format('d MMMM, YYYY')}<br/></>
													}
													{report.survey.participants.completedCount} participants
												</p>
											</div>
											<div className={'button-group' + (canView ? ' -split' : ' -right')}>
												{canView &&
													<Link to={{ pathname: pathToSurvey(report.survey), state: { from: window.location.pathname } }} className="button-link -text">Survey details</Link>
												}
												<Link to={{ pathname: pathToSurveyReport(report.survey.id), state: { from: window.location.pathname } }} className="button-link -action">View</Link>
											</div>
										</div>
									</div>
								))}
							</div>
						</>
					}
				</Screen>
			}
		</>
	)	
}

export default AdminDashboard
