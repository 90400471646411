import actionCreatorFactory from 'typescript-fsa'
import { Api } from 'typescript-fetch-api'
import { CurrentMembership } from './types'

const actionCreator = actionCreatorFactory('Admin')

export const setPersonDetails = actionCreator<Api.PersonDetails>('SET_PERSON')

export const setCurrentMembership = actionCreator<CurrentMembership>('SET_CURRENT_MEMBERSHIP')

export const chooseMembership = actionCreator<Api.PersonDetails.Membership>('CHOOSE_MEMBERSHIP')

export const adminLoading = actionCreator<boolean>('LOADING')
