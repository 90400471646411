import * as Api from 'typescript-fetch-api'

import { fetchTimeout } from './timeout'

export interface ApiCollection {
	participationApi: Api.ParticipationApi
	surveyApi: Api.SurveyApi
	knowledgeLibraryApi: Api.KnowledgeLibraryApi
	authApi: Api.AuthApi
	personApi: Api.PersonApi
	accountApi: Api.AccountApi
	adminApi: Api.AdminApi
}

/** The API configuration. */
let configuration: Api.Configuration
let api: ApiCollection

/** Our fetch API wrapper to provide additional functionality */
const myFetchAPI: Api.FetchAPI = function(url: RequestInfo | URL, init?: RequestInit): Promise<Response> {
	/* Apply a timeout to our requests. Note that the timeout doesn't cancel the request, it merely
	   throws an error so we are not left hanging.
	 */
	return fetchTimeout(url, init, 30000)
}

export function initApiConfiguration(params: Api.ConfigurationParameters) {
	configuration = new Api.Configuration(params)

	api = {
		participationApi: new Api.ParticipationApi(configuration, undefined, myFetchAPI),
		surveyApi: new Api.SurveyApi(configuration, undefined, myFetchAPI),
		knowledgeLibraryApi: new Api.KnowledgeLibraryApi(configuration, undefined, myFetchAPI),
		authApi: new Api.AuthApi(configuration, undefined, myFetchAPI),
		personApi: new Api.PersonApi(configuration, undefined, myFetchAPI),
		accountApi: new Api.AccountApi(configuration, undefined, myFetchAPI),
		adminApi: new Api.AdminApi(configuration, undefined, myFetchAPI),
	}
}

export function getConfiguration(): Api.Configuration {
	if (!configuration) {
		throw new Error('initApiConfiguration has not been called')
	}
	return configuration
}

/** A global API client. This API client should be used by the rest of the app to connect to the
 * API. It uses the global configuration, so it can pick up the required access token.
 */
export default function getApi(): ApiCollection {
	if (!api) {
		throw new Error('initApiConfiguration has not been called')
	}
	return api
}
