import * as React from 'react'
import Screen from 'modules/common/components/Screen'
import { useHistory, useParams } from 'react-router'
import { Api } from 'typescript-fetch-api'
import { useCallApi } from 'modules/common/hooks'
import { useController, Formalities } from 'formalities'
import { customQuestionTypeToEnum, getCustomQuestionTypeOptions } from 'modules/admin/functions'
import { pathToSurveyEditSection } from '../navigation'
import CustomQuestionAnswerTable from './CustomQuestionAnswerTable'
import * as _ from 'lodash'
import { Link } from 'react-router-dom'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'

interface Props {
	survey: Api.SurveyDetails
	onChangeSurvey: () => void
}

interface FormQuestion {
	title?: string
	subtitle?: string
	answerType?: Api.MultichoiceQuestion.MultichoiceTypeEnum | Api.FreeTextQuestion.FreeTextTypeEnum
	answerToAdd: string
}

const CustomQuestion: React.FC<Props> = function(props) {
	const { survey } = props
	const params = useParams<{ sectionId: string; questionId: string }>()
	const section = survey.editableSections.find((s) => s.id === params.sectionId)
	if (!section) {
		throw new Error('No section')
	}
	const question = section.questions.find((s) => s.id === params.questionId) || section.availableQuestions.find((s) => s.id === params.questionId)
	const controller = useController<FormQuestion>({
		title: question ? question.title : undefined,
		subtitle: question ? question.subtitle : '',
		answerType: question && customQuestionTypeToEnum(question) ? customQuestionTypeToEnum(question) : undefined,
		answerToAdd: '',
	})
	const [answers, setAnswers] = React.useState<string[]>(question && question.type === Api.QuestionTypeEnum.Multi ? question.options : [])
	const questionForm = controller.snapshot().value
	const callApi = useCallApi()
	const history = useHistory()

	React.useEffect(() => {
		const question = document.getElementById('questionInput')
		if (question) {
			question.focus()
		}
	}, [])

	React.useEffect(function() {
		const form = document.getElementById('textInput')
		if (form) {
			form.addEventListener('keyup', detectEnterButton)
		}
		return () => {
			if (form) {
				form.removeEventListener('keyup', detectEnterButton)
			}
		}
	})

	function detectEnterButton(evt: KeyboardEvent) {
		if (evt.keyCode === 13) {
			evt.preventDefault()
			addAnswerToForm()
		}
	}

	function goBack(evt: React.FormEvent) {
		evt.preventDefault()
		history.goBack()
	}

	function addAnswerToForm(evt?: React.FormEvent) {
		evt && evt.preventDefault()

		if (_.includes(answers, questionForm.answerToAdd)) {
			uniqueToastError('Unable to add duplicate answer.')
		} else {
			setAnswers([...answers, questionForm.answerToAdd])
			controller.snapshot().setValue({ ...questionForm, answerToAdd: '' })
			const textBox = document.getElementById('textInput')
			textBox?.focus()
		}
	}

	function removeAnswerFromForm(evt: React.FormEvent, answerToRemove: string) {
		evt.preventDefault()
		const index = answers.indexOf(answerToRemove)
		if (index !== -1) {
			const newArr = [...answers]
			newArr.splice(index, 1)
			setAnswers(newArr)
		}
	}

	function editAnswer(evt: React.FormEvent, answerToEdit: string) {
		evt.preventDefault()
		const index = answers.indexOf(answerToEdit)
		if (index !== -1) {
			const newArr = [...answers]
			newArr.splice(index, 1)
			controller.snapshot().setValue({ ...questionForm, answerToAdd: answerToEdit })
			setAnswers(newArr)
			const textBox = document.getElementById('textInput')
			textBox?.focus()
		}
	}

	function constructQuestion(id?: string): Api.MultichoiceQuestion | Api.FreeTextQuestion {
		const form = controller.snapshot().value
		if (form.answerType === Api.FreeTextQuestion.FreeTextTypeEnum.Short || form.answerType === Api.FreeTextQuestion.FreeTextTypeEnum.Long) {
			return {
				id: id ? id : section!.id,
				title: form.title ? form.title : '',
				subtitle: form.subtitle,
				type: Api.QuestionTypeEnum.FreeText,
				freeTextType: form.answerType,
			}
		} else {
			return {
				id: id ? id : section!.id,
				title: form.title!,
				subtitle: form.subtitle,
				type: Api.QuestionTypeEnum.Multi,
				multichoiceType: form.answerType!,
				options: answers,
				allowOtherAnswer: false,
			}
		}
	}

	async function saveQuestion(evt: React.FormEvent) {
		evt.preventDefault()
		const form = controller.snapshot().value
		if (!form.title || !form.answerType) {
			return
		}

		if (question) {
			try {
				await callApi(api => api.surveyApi.updateCustomQuestion(survey.id, section!.id, question.id, constructQuestion(question.id)))
				uniqueToastSuccess('Successfully updated question.')
				props.onChangeSurvey()
				history.push(pathToSurveyEditSection(survey, section!.id))
			} catch (error) {
				uniqueToastError('Failed to update question.')
			}
		} else {
			try {
				await callApi(api => api.surveyApi.createCustomQuestion(survey.id, section!.id, constructQuestion()))
				uniqueToastSuccess('Successfully created question.')
				props.onChangeSurvey()
				history.push(pathToSurveyEditSection(survey, section!.id))
			} catch (error) {
				uniqueToastError('Failed to create question.')
			}
		}
	}

	function canSaveQuestion(): boolean {
		if (question) {
			if (!questionForm.answerType) {
				return false
			} else if (questionForm.title !== question.title && questionForm.title && questionForm.title !== '') {
				return true
			} else if (question.subtitle !== questionForm.subtitle && ((!question.subtitle || question.subtitle === '') && (!questionForm.subtitle || questionForm.subtitle === '') ? false : true)) {
				return true
			} else if (questionForm.answerType !== customQuestionTypeToEnum(question)) {
				return true
			} else if (question.type === Api.QuestionTypeEnum.Multi && !_.isEqual(question.options, answers)) {
				return true
			}	else {
				return false
			}
		} else {
			if ((questionForm.title === '' || !questionForm.title) || (!questionForm.answerType)) {
				return false
			} else {
				return true
			}
		}
	}

	function isMultiQuestion(): boolean {
		if (!questionForm.answerType || questionForm.answerType === Api.FreeTextQuestion.FreeTextTypeEnum.Short || questionForm.answerType === Api.FreeTextQuestion.FreeTextTypeEnum.Long) {
			return false
		} else {
			return true
		}
	}

	return (
		<Screen title="Edit" includePageHeader={false} breadcrumbText="editing section" breadcrumbLink={pathToSurveyEditSection(survey, section.id)}>
			<div className="content-header">
				<h1 className="headline-text">{question ? 'Edit ' : 'New '}custom question</h1>
			</div>
			<div className="content-cols">
				<div className="body">
					<div className="form-field -adjacent">
						<label className="label">Question</label>
						<div className="form-input -text"><Formalities.Text id="questionInput" className="field" controller={controller} prop="title" /></div>
					</div>
					
					<div className="form-field -adjacent">
						<label className="label">Subtitle <span className="note">(optional)</span></label>
						<div className="form-input -text"><Formalities.Text className="field" controller={controller} prop="subtitle"/></div>
					</div>
					
					<div className="form-field -adjacent">
						<label className="label">Answer type</label>
						<div className="form-input -select">
							<Formalities.Select controller={controller} prop="answerType" className="select" options={getCustomQuestionTypeOptions(question ? question : undefined)}/>
						</div>
					</div>
					{
						isMultiQuestion() && 
							<div className="form-row">
								<h2 className="label -padtop">Answers</h2>
								<div className="fields">
									<CustomQuestionAnswerTable 
										answers={answers} 
										setAnswers={setAnswers}
										onRemoveAnswer={removeAnswerFromForm}
										editAnswer={editAnswer}
									/>
									<div className="buttoned-field">
										<div className="form-input -text">
											<Formalities.Text controller={controller} id="textInput" prop="answerToAdd" className="field" placeholder="Enter answer text…"/>
										</div>
										<button className="button-link -secondary" onClick={addAnswerToForm}>Add</button>
									</div>
								</div>
							</div>
					}
					
					<div className="footer-actions">
						<div className="button-group -right">
							{
								question ? 
									(canSaveQuestion() ?
										<>
											<a href="/" className="button-link -secondary" onClick={goBack}>Discard changes</a>
											<input type="submit" className="button-link -action" value="Save changes" onClick={(e) => saveQuestion(e)} />
										</> :
										<Link to="/" className="button-link -action" onClick={goBack}>Done</Link>) :
									<>
										<a href="/" className="button-link -secondary" onClick={goBack}>Cancel</a>
										<input type="submit" className="button-link -action" value="Save question" onClick={(e) => saveQuestion(e)} disabled={!canSaveQuestion()} />
									</>
							}
						</div>
					</div>
				</div>
			
				<aside className="aside">
					<div className="aside-text">
						<h4 className="icon-text -uppercase -smallicon -flag">Tip</h4>
						<div className="body-text -small">
							<p>
								Custom questions will be availble for use within any survey in your organisation. 
								Questions may be edited up until the first time they are used in an active survey, 
								after which they will remain available in your Question Library but can’t be edited.
							</p>
						</div>
					</div>
				</aside>
			</div>
		</Screen>
	)
}

export default CustomQuestion