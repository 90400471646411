
import React from 'react'
import NewSurvey from './NewSurvey'
import YourSurveys from './YourSurveys'
import ViewSurvey from './ViewSurvey'
import EditSurvey from './EditSurvey'
import EditSection from './EditSection'
import CustomQuestion from './CustomQuestion'
import SurveyParticipants from './SurveyParticipants'
import { Switch, Route, useParams, Redirect } from 'react-router'
import { useApiEffect } from 'modules/common/hooks'
import LoadingScreen from 'modules/common/components/LoadingScreen'
import ErrorScreen from 'modules/common/components/ErrorScreen'
import { Api } from 'typescript-fetch-api'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

interface LoadSurveyProps {
	render: (survey: Api.SurveyDetails, onChangeSurvey: () => void) => React.ReactElement
}
const LoadSurvey: React.FC<LoadSurveyProps> = function(props) {
	const params = useParams<{ surveyId: string }>()
	const surveyId = params.surveyId
	const getSurvey = useApiEffect(api => api.surveyApi.getSurvey(surveyId), [surveyId])

	function onChangeSurvey() {
		getSurvey.refresh()
	}

	if (getSurvey.result) {
		return props.render(getSurvey.result, onChangeSurvey)
	} else if (getSurvey.error) {
		return <ErrorScreen error={getSurvey.error} />
	} else {
		return <LoadingScreen />
	}
}

export default function AdminSurveyRouter() {
	return (
		<DndProvider backend={HTML5Backend}>
			<Switch>
				<Route 
					path="/admin/survey/new/" 
					component={NewSurvey}
				/>
				<Route 
					path="/admin/survey/your-surveys" 
					component={YourSurveys}
				/>
				<Route path="/admin/survey/:surveyId/participants">
					<LoadSurvey render={(survey, onChangeSurvey) => <SurveyParticipants survey={survey} onChangeParticipants={onChangeSurvey} />} />
				</Route>
				<Route path="/admin/survey/:surveyId/edit/:sectionId/custom/:questionId">
					<LoadSurvey render={(survey, onChangeSurvey) => <CustomQuestion survey={survey} onChangeSurvey={onChangeSurvey} />} />
				</Route>
				<Route path="/admin/survey/:surveyId/edit/:sectionId/custom">
					<LoadSurvey render={(survey, onChangeSurvey) => <CustomQuestion survey={survey} onChangeSurvey={onChangeSurvey} />} />
				</Route>
				<Route path="/admin/survey/:surveyId/edit/:sectionId">
					<LoadSurvey render={(survey, onChangeSurvey) => <EditSection survey={survey} onChangeSurvey={onChangeSurvey} />} />
				</Route>
				<Route path="/admin/survey/:surveyId/edit">
					<LoadSurvey render={(survey, onChangeSurvey) => <EditSurvey survey={survey} onChangeSurvey={onChangeSurvey} />} />
				</Route>
				<Route path="/admin/survey/:surveyId">
					<LoadSurvey render={(survey, onChangeSurvey) => <ViewSurvey survey={survey} onChangeSurvey={onChangeSurvey} />} />
				</Route>
				<Route>
					<Redirect to="/admin/survey/your-surveys" push={false} />
				</Route>
			</Switch>
		</DndProvider>
	)
}
