import Results from './Results'
import ReportSectionDetail from './ReportSectionDetail'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router'
import { useSelector } from 'react-redux'
import { selectParticipation } from 'modules/participant/selectors'
import LoadingScreen from 'modules/common/components/LoadingScreen'
import { useApiEffect } from 'modules/common/hooks'
import ErrorScreen from 'modules/common/components/ErrorScreen'
import { isApiErrorWithMessages } from 'modules/api/functions'
import { Api } from 'typescript-fetch-api'

export default function ParticipantReportRouter() {
	const { path } = useRouteMatch()
	const participation = useSelector(selectParticipation)

	if (!participation) {
		throw new Error('No participation')
	}

	/* Load the report before routing */
	const getParticipationReport = useApiEffect((api) => api.participationApi.getParticipationReport(participation!.participationId), [participation])
	if (getParticipationReport.error) {
		if (isApiErrorWithMessages(getParticipationReport.error)) {
			if (getParticipationReport.error.messages[0].code === Api.GetParticipationReport400Response.Message.CodeEnum.NoReportAvailable && participation.account.knowledgeLibraryEnabled) {
				return <Redirect to="/knowledge-library" />
			}
		}
		return <ErrorScreen error={getParticipationReport.error} />
	} else if (!getParticipationReport.result) {
		return <LoadingScreen />
	}

	const { report } = getParticipationReport.result
	return (
		<Switch>
			<Route path={`${path}`} exact strict>
				<Redirect to={`${path}/`} />
			</Route>
			<Route path={`${path}/section/:index`} render={routeProps => (
				<ReportSectionDetail report={report} section={report.sections[parseInt(routeProps.match.params.index!)]} {...routeProps}/>
			)} />
			<Route path={`${path}/section/:index/article/:articleId`} render={routeProps => (
				<ReportSectionDetail report={report} section={report.sections[parseInt(routeProps.match.params.index!)]} {...routeProps}/>
			)} />
			<Route path={`${path}/`}>
				<Results report={getParticipationReport.result.report} participation={getParticipationReport.result.participation} support={getParticipationReport.result.support} />
			</Route>
		</Switch>
	)
}
