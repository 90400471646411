import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import { Redirect, useHistory } from 'react-router'
import Screen from './Screen'

const ErrorFallback: React.FC<FallbackProps> = function({ error, resetErrorBoundary }) {
	const history = useHistory()

	function doContinue(evt: React.MouseEvent) {
		evt.preventDefault()

		history.push('/')
		resetErrorBoundary()
	}

	/* Redirect expired admin sessions back to the login screen */
	if (history.location.pathname.startsWith('/admin') && error && (error.message === 'No account' || error.message === 'Not logged in')) {
		resetErrorBoundary()
		return <Redirect to="/" />
	}

	return (
		<Screen title="Error" includePageHeader={false} class="error-message">
			<div className="body-text -large">
				{error && (error.message === 'No account' || error.message === 'Not logged in') ? (
					<>
						<h2>Your session has expired</h2>
						<p>If you received a link in an email, please return to your email and
						click that link again.</p>
						<p>Otherwise, please click <em>Continue</em> to get a new link.</p>
					</>
				) : error && error.message ? (
					<p>{error.message}</p>
				) : error ? (
					<>
						<h2>An error has occurred</h2>
						<p>{error.message}</p>
					</>
				) : (
					<h2>An unknown error has occurred</h2>
				)}
			</div>
			<div className="button-group">
				<button className="button-link -action" onClick={doContinue}>Continue</button>
			</div>
		</Screen>
	)
}

export default ErrorFallback
