import * as React from 'react'
import { withModal, ModalStyle, ModalComponentProps } from 'modules/common/modal-hoc'
import { Formalities, useController } from 'formalities'

interface OwnProps {
	title: string
	text?: string
	className?: string
	buttonText: string
	action: (input: string) => void
	inputName?: string
}

type Props = ModalComponentProps & OwnProps

export default withModal(
	function InputActionModal(props: Props) {
		const controller = useController<string>('')
		
		function onSubmit(evt: React.FormEvent | React.MouseEvent) {
			evt.preventDefault()

			props.action(controller.snapshot().value)
		}

		return (
			<div className="modal-layout">
				<div className={props.className ? props.className : 'article-page'}>
					<div className="body">
						<div className="content body-text -hero">
							<div className="text">
								<h1 className="headline-text">{props.title}</h1>
								<p>{props.text}</p>
							</div>
							<div className="form-field -stacked -nobottompadding">
								<label className="label">{props.inputName}</label>
								<div className="form-input -text">
									<Formalities.Text className="field" controller={controller} prop="this" autoFocus={true}/>
								</div>
							</div>
							<div className="button-group -right">
								<button type="button" className="button-link -secondary" onClick={props.onClose}>Cancel</button>
								<input type="submit" className="button-link -action" value={props.buttonText} onClick={onSubmit}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	},

	ModalStyle.Narrow,
)