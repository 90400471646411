import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectSurvey } from 'modules/participant/selectors'
import { getSurveyPositionPercentage } from 'modules/participant/survey/functions'

export interface Props {
	questionId: string
}

export default function ProgressBar(props: Props) {
	const survey = useSelector(selectSurvey)
	if (!survey) {
		return null
	}

	return (
		<div className="progress-bar">
			<div className="track">
				<div className="progress" style={{ width: (getSurveyPositionPercentage(props.questionId, survey) + '%') }}></div>
			</div>
		</div>
	)
}
