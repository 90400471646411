import React, { useState } from 'react'
import Screen from 'modules/common/components/Screen'
import { Api, SortParticipants } from 'typescript-fetch-api'
import { useCursoredDataEffect, useApiEffect, useCallApi } from 'modules/common/hooks'
import ErrorInline from 'modules/common/components/ErrorInline'
import { HeaderItem } from 'modules/common/components/Table'
import { selectCurrentMembership } from 'modules/admin/selectors'
import { useSelector } from 'react-redux'
import ParticipantsTable, { TableFilter } from './ParticipantsTable'
import AddParticipants from './AddParticipants'
import ExportParticipants from './ExportParticipants'
import { apiErrorToMessage } from 'modules/api/functions'
import LoadingInline from 'modules/common/components/LoadingInline'
import { useParams, useHistory, RouteComponentProps } from 'react-router'
import InputActionModal from 'modules/common/components/InputActionModal'
import { useCanEdit } from 'modules/admin/hooks'
import { TableAction } from 'modules/admin/types'
import { uniqueToastError, uniqueToastSuccess, uniqueToastWarn } from 'modules/common/functions'

interface MyTableFilter extends TableFilter {
	filter?: Api.FilterSurveyParticipants
	group?: Api.ParticipantGroup
}

interface MyTableHeader extends HeaderItem {
	sortBy: Api.SortParticipants
}

const AccountParticipants: React.FC<RouteComponentProps> = function(props) {
	const currentAccount = useSelector(selectCurrentMembership)
	const params = useParams<{ groupId: string }>()
	const groupId = params.groupId
	if (!currentAccount) {
		throw new Error('No account')
	}
	const history = useHistory()
	const [searchTerm, setSearchTerm] = useState<string | undefined>()
	const [sort, setSort] = useState<SortParticipants | undefined>()
	const [showAddModal, setShowAddModal] = useState(false)
	const getAccountGroups = useApiEffect(api => api.accountApi.getGroups(currentAccount.account.id), [currentAccount])
	const group = getAccountGroups.result && getAccountGroups.result.find(g => g.id === groupId)
	const [filters, setFilters] = useState<Api.FilterSurveyParticipants[] | undefined>()
	const callApi = useCallApi()
	const { response, loadMore, refresh } = useCursoredDataEffect((cursor) => {
		if (searchTerm) {
			return callApi(api => api.accountApi.searchParticipants(currentAccount.account.id, searchTerm, cursor, sort, group ? group.id : undefined))
		} else if (groupId) {
			return callApi(api => api.accountApi.getGroupParticipants(groupId, currentAccount.account.id, cursor, sort))
		} else {
			return callApi(api => api.accountApi.getAccountParticipants(currentAccount.account.id, cursor, sort))
		}
	},
	[sort, filters, callApi, searchTerm, groupId, currentAccount.account.id, group])
	const canEdit = useCanEdit()
	
	async function onAddParticipants(request: Api.AddParticipantsRequest): Promise<boolean> {
		try {
			const accountRequest: Api.AddAccountParticipantsRequest = {
				...request,
				group: groupId,
			}
			const result = await callApi(api => api.accountApi.addAccountParticipants(currentAccount!.account.id, accountRequest))
			refresh()
			
			if (result.numberAdded === 1) {
				uniqueToastSuccess('Added participant.')
				return true
			} else if (result.numberAdded > 1) {
				uniqueToastSuccess(`Added ${result.numberAdded} participants${result.numberExisting > 0 ? ` (${result.numberExisting} were already participants)` : ''}`)
				return true
			} else if (result.numberExisting === 1) {
				uniqueToastWarn('Participant was already a member.')
				return true
			} else {
				uniqueToastWarn('All participants were already members.')
				return true
			}
		} catch (error) {
			uniqueToastError(`Failed to add participants.\n${apiErrorToMessage(error)}`)
			return false
		}
	}
	
	async function onRemoveParticipants(participantIds: string[]) {
		try {
			if (groupId) {
				await callApi(api => api.accountApi.removeGroupParticipants(groupId, currentAccount!.account.id, { participants: participantIds }))
			} else {
				await callApi(api => api.accountApi.deleteAccountParticipants(currentAccount!.account.id, { participants: participantIds }))
			}
			
			refresh()
			uniqueToastSuccess(`Successfully removed participant${participantIds.length > 1 ? 's' : ''}`)
		} catch (error) {
			uniqueToastError(`Failed to remove participant.\n${apiErrorToMessage(error)}`)
		}
	}
	
	async function onSearchParticipants(searchInput?: string, sortBy?: HeaderItem, filters?: MyTableFilter[]) {
		setSearchTerm(searchInput)
		setSort(sortBy ? (sortBy as MyTableHeader).sortBy : undefined)
		setFilters(filters ? filters.filter(f => !!f.filter).map(f => f.filter!) : undefined)
	}

	function onCloseModal() {
		setShowAddModal(false)
	}
	
	async function onNewGroup(input: string) {
		if (currentAccount?.account.id) {
			try {
				await callApi(api => api.accountApi.createGroup(currentAccount.account.id, { name: input }))
				setShowAddModal(false)
				getAccountGroups.refresh()
				uniqueToastSuccess('Successfully added group')
			} catch (error) {
				uniqueToastError('Failed to add group')
			}
		}
	}
	
	async function onAddParticipantsToGroup(groupToAddName: string, participantIds: string[]) {
		const groupToAddId = getAccountGroups.result?.find(group => group.name === groupToAddName)?.id
		if (currentAccount?.account.id && participantIds && groupToAddId) {
			try {
				await callApi(api => api.accountApi.addParticipantsToGroup(groupToAddId, currentAccount.account.id, { participants: participantIds }))
				refresh()
				uniqueToastSuccess(`Successfully added participant${participantIds.length > 1 ? 's' : ''} to group`)
			} catch (error) {
				uniqueToastError('Failed to add group')
			}
		}
	}

	function onUpdateGroup(evt: React.ChangeEvent<HTMLSelectElement>) {
		if (evt.target.value !== '') {
			history.replace(`/admin/participants/${evt.target.value}`)
		} else {
			history.replace('/admin/participants')
		}
	}

	function actions(): TableAction[] {
		const groups = getAccountGroups.result ? [...getAccountGroups.result] : undefined
		const currentGroupIndex = groups ? groups.findIndex((group) => group.id === groupId) : -1
		if (groups && currentGroupIndex !== -1) {
			groups.splice(currentGroupIndex, 1)
		}
		const allParticipantsIndex = groups ? groups.findIndex((group) => group.name === 'All participants') : -1
		if (groups && allParticipantsIndex !== -1) {
			groups.splice(allParticipantsIndex, 1)
		}

		const actions: TableAction[] = [{
			name: groupId ? 'Remove from group' : 'Delete',
			onAction: onRemoveParticipants,
		}]

		if (groups && groups.length > 0) {
			actions.push({
				name: 'Add to group',
				onSelectAction: onAddParticipantsToGroup,
				selectButtonTitle: 'Add',
				selectableItems: groups.map(item => item.name),
			})
		}

		return actions
	}
	
	return (
		<Screen title="Participants" includePageHeader={false}>
			<div className="content-header">
				{group ? (
					<h1 className="headline-text">{group.name} <span className="body-text -hero">for {currentAccount.account.name}</span></h1>
				) : (
					<h1 className="headline-text">All {currentAccount.account.name}’s participants</h1>
				)}
			</div>
			<div className="content-cols -widesidebar">
				<div className="body">
					{response.result && (response.result.participants.length > 0 || searchTerm || (filters && filters.length)) &&
						<ParticipantsTable
							loadMore={loadMore}
							actions={actions}
							onSearch={onSearchParticipants}
							headerItems={[
								{ text: 'Given name', sortBy: SortParticipants.GivenName }, 
								{ text: 'Family name', sortBy: SortParticipants.FamilyName }, 
								{ text: 'Email', sortBy: SortParticipants.Email }, 
							]}
							rows={response.result.participants.map((participant) => {
								return ({
									key: participant.id,
									cols: [
										{ text: participant ? participant.givenName : '' },
										{ text: participant ? participant.familyName : '' },
										{ text: participant ? participant.emailAddress : <em>Anonymous</em> },
									],
								})
							})}
						/>
					}
					{response.result && response.result.participants.length === 0 && !searchTerm &&
						<div className="no-participants">
							<h2 className="subheading-text">No participants</h2>
							<div className="body-text">
								<p>Start adding some either individually, or based on existing participant lists.</p>
							</div>
						</div>
					}
					{response.loading && 
						<div className="loading-container -centered -large">
							<LoadingInline />
						</div>
					}
					{response.error && <ErrorInline error={response.error} />}
				</div>

				{canEdit &&
				<aside className="aside">
					<div className="aside-text">
						{getAccountGroups.result ?
							<div className="narrow-form">
								<h4 className="subheading-text -small">Group</h4>
								<div className="form-field">
									<div className="form-input -select">
										<select value={groupId} onChange={(e) => onUpdateGroup(e)} className="select">
											<option value="">All participants</option>
											{
												getAccountGroups.result.filter(g => g.groupType !== Api.ParticipantGroup.GroupTypeEnum.AllParticipants).map((value, index) => (
													<option key={index} value={value.id}>{value.name}</option>
												))
											}
										</select>
									</div>
								</div>
								<button className="icon-text -add" onClick={() => setShowAddModal(true)}>Add new group</button>
							</div>
							: getAccountGroups.error && <ErrorInline error={getAccountGroups.error} />
						}
						<AddParticipants onAddParticipants={onAddParticipants} />
						<ExportParticipants 
							group={group}
							loadParticipants={(cursor) => {
								if (groupId) {
									return callApi(api => api.accountApi.getGroupParticipants(groupId, currentAccount.account.id, cursor, undefined))
								} else {
									return callApi(api => api.accountApi.getAccountParticipants(currentAccount.account.id, cursor, undefined))
								}
							}}
						/>
					</div>
				</aside>
				}
			</div>
			{ showAddModal &&
				<InputActionModal
					title="Add new group"
					buttonText="Add group"
					text="Please choose a name for your new group. Once created you will be able to add participants."
					className="dialog-modal"
					inputName="Group name"
					action={onNewGroup}
					onClose={onCloseModal}
					{...props}
				/>
			}
		</Screen>
	)
}

export default AccountParticipants
