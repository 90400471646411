import React, { useState } from 'react'
import Popover, { ArrowContainer } from 'react-tiny-popover'

interface Props {
	poor: number | undefined /** A score from 0.0 to 1.0 */
	good: number | undefined /** A score from 0.0 to 1.0 */
	great: number | undefined /** A score from 0.0 to 1.0 */
	label?: string
	showBackground?: boolean
}

/**
 * Render a horizontal bar chart for showing survey results.
 */
const BarChart: React.FC<Props> = function(props) {
	return (
		<>
			{props.label && 
				<h4 className="label body-text -small">{props.label}</h4>
			}
			<div className={'bar-chart' + (props.showBackground ? ' -background' : '')}>
				{props.poor !== undefined && props.poor > 0 && 
					<Bar class="-poor" result={props.poor}/>
				}
				{props.good !== undefined && props.good > 0 && 
					<Bar class="-good" result={props.good}/>
				}
				{props.great !== undefined && props.great > 0 && 
					<Bar class="-great" result={props.great}/>
				}
			</div>
		</>
	)
}

interface BarProps {
	class: string
	result: number
}

function Bar(props: BarProps) {
	const [isPopoverOpen, setPopoverOpen] = useState(false)

	return (
		<Popover
			isOpen={isPopoverOpen}
			position={'top'}
			content={({ position, targetRect, popoverRect }) => (
				<ArrowContainer
					position={position}
					targetRect={targetRect}
					popoverRect={popoverRect}
					arrowColor={'white'}
					arrowSize={10}
					arrowStyle={{ opacity: 1 }}
				>
					<div className="popup-menu">
						<ul className="menuitems -contentfit">
							<li className="menuitem">{Math.round(props.result * 100) + '%'}</li>
						</ul>
					</div>
				</ArrowContainer>
			)}
			onClickOutside={() => setPopoverOpen(false)}
		>
			<div className={'bar ' + props.class} style={{ width: (props.result * 100) + '%' }} onMouseEnter={() => setPopoverOpen(true)} onMouseLeave={() => setPopoverOpen(false)}>
				<svg viewBox="0 0 16 16" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="0" width="16" height="16" />
				</svg>
			</div>
		</Popover>
	)
}

export default BarChart