import * as React from 'react'
import { Api } from 'typescript-fetch-api'
import update from 'immutability-helper'
import { Question } from './Question'

interface Props {
	addedQuestions: (Api.ScaleQuestion | Api.MultichoiceQuestion | Api.MatrixSubQuestion | Api.MatrixQuestion | Api.FreeTextQuestion)[]
	onChangeSurvey: () => void
	section: Api.SurveySection
	survey: Api.SurveyDetails
	setAddedQuestions: (value: React.SetStateAction<(Api.ScaleQuestion | Api.MultichoiceQuestion | Api.MatrixSubQuestion | Api.MatrixQuestion | Api.FreeTextQuestion)[]>) => void
	onRemoveQuestion: (evt: React.FormEvent, question: (Api.ScaleQuestion | Api.MultichoiceQuestion | Api.MatrixSubQuestion | Api.MatrixQuestion | Api.FreeTextQuestion)) => void
}

const DraggableQuestionTable: React.FC<Props> = function(props) {
	const { section, survey, addedQuestions } = props
	const moveQuestion = React.useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const dragCard = addedQuestions[dragIndex]
			props.setAddedQuestions(
				update(addedQuestions, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragCard],
					],
				}),
			)
		},
		[addedQuestions, props],
	)

	return (
		<>
			{
				addedQuestions.length > 0 &&
					<div className="question-table">
						<h2 className="subheading-text">Selected questions</h2>
						<table className="data-table">
							<thead>
								<tr>
									<th>Name</th>
									<th className="type _md-up">Type</th>
									<th className="options rightalign"></th>
								</tr>
							</thead>
							<tbody>
								{addedQuestions.map((question, index) => 
									<Question 
										key={question.id} 
										survey={survey}
										section={section}
										onRemoveQuestion={props.onRemoveQuestion}
										question={question} 
										index={index} 
										moveQuestion={moveQuestion} 
									/>,
								)}
							</tbody>
						</table>
					</div>
			}
		</>
	)
}

export default DraggableQuestionTable