import {
	LoginDoneAction,
	login,
	loggedOut,
	programmaticLogin,
	refreshedToken,
	RefreshedTokenAction,
} from 'modules/auth/actions'
import { storeReadyAction } from 'modules/root/actions'
import { SagaIterator } from 'redux-saga'
import { call, take, takeEvery } from 'redux-saga/effects'
import { RootStoreState } from 'modules/root'
import { SecureCredentials, SecurityLevel } from 'capacitor-secure-credentials-plugin'
import { Store } from 'redux'
import { AccessToken } from 'typescript-fetch-api'

const CREDENTIALS_SECURITY_LEVEL = SecurityLevel.L3_UserPresence
const SERVICE = 'Umbrella'

async function storeLoginCredentials(action: LoginDoneAction) {
	const accessToken = action.payload.result
	const username = action.payload.params.username

	try {
		await SecureCredentials.setCredential({
			service: SERVICE,
			credential: { 
				username,
				password: JSON.stringify(accessToken),
			},
			options: { securityLevel: CREDENTIALS_SECURITY_LEVEL },
		})
	} catch (error) {
		console.debug(error)
	}
}

async function persistRefreshToken(action: RefreshedTokenAction) {
	try {
		const resultAllAccounts = await SecureCredentials.getUsernames({ service: SERVICE })
		if (resultAllAccounts.success && resultAllAccounts.result.length > 0) {
			const username = resultAllAccounts.result[0]
			try {
				await SecureCredentials.setCredential({
					service: SERVICE,
					credential: { 
						username,
						password: JSON.stringify(action.payload),
					},
					options: { securityLevel: CREDENTIALS_SECURITY_LEVEL },
				})
			} catch (error) {
				console.error('Error persisting refresh token:', error)
			}
		}
	} catch (error) {
		console.error('Error retrieving accounts:', error)
	}
}

async function removeLoginCredentials() {
	try {
		await SecureCredentials.removeCredentials({ service: SERVICE })
	} catch (error) {
		console.debug(error)
	}
}

async function attemptLogin(store: Store<RootStoreState>) {
	try {
		const resultAllAccounts = await SecureCredentials.getUsernames({ service: SERVICE })
		if (resultAllAccounts.success && resultAllAccounts.result.length > 0) {
			// This is where the SecureCredentials plugin checks that the user is present, e.g. using biometrics or device PIN
			const resultAllCredentials = await SecureCredentials.getCredential({ service: SERVICE, username: resultAllAccounts.result[0] })
			if (resultAllCredentials.success) {
				const credential = resultAllCredentials.result
				const accessToken: AccessToken = JSON.parse(credential.password)
				store.dispatch(programmaticLogin({
					accessToken,
					rememberMe: true,
				}))
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export default function* saga(store: Store<RootStoreState>): SagaIterator {
	try {
		yield takeEvery(loggedOut, removeLoginCredentials)
		yield takeEvery(login.done, storeLoginCredentials)
		yield takeEvery(refreshedToken, persistRefreshToken)

		// Wait until the store is ready before attempting to login
		yield take(storeReadyAction) 
		yield call(attemptLogin, store)
	} catch (error) {
		console.debug(error)
	}
}
