import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import './browser-check'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { init } from 'modules/root/init'
import 'focus-visible'
import { enableES5 } from 'immer'
import { installGtag } from 'mr-gtag'

enableES5()

init().then(store => {
	ReactDOM.render(<App store={store} />, document.getElementById('root'))
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

/* Inialise GA */
installGtag('G-9C0HEY6THS')
