import React, { useState } from 'react'
import Screen from 'modules/common/components/Screen'
import { useController, Formalities } from 'formalities'
import { useCallApi } from 'modules/common/hooks'
import { apiErrorToMessage } from 'modules/api/functions'
import { Link } from 'react-router-dom'
import { uniqueToastError } from 'modules/common/functions'

interface Form {
	username: string
}

export default function RequestResetPassword() {
	const controller = useController<Form>({
		username: '',
	})
	const callApi = useCallApi()
	const [showThanks, setShowThanks] = useState(false)

	async function doRequestResetPassword(evt: React.FormEvent) {
		evt.preventDefault()
		const formValue = controller.snapshot().value

		try {
			await callApi(api => api.authApi.postRequestResetPassword({
				emailAddress: formValue.username,
			}))

			setShowThanks(true)
		} catch (error) {
			uniqueToastError(`Couldn’t send a reset password email.\n${apiErrorToMessage(error)}`)
		}
	}

	return (
		<Screen title="Reset password" includePageHeader={false}>
			<form className="signin-form">
				<div className="content-header">
					<h1 className="headline-text -centered">Reset password</h1>
				</div>
				{showThanks && (
					<>
						<div className="form">
							<p>Thank you. Please check your email for an email from Umbrella Wellbeing.</p>
						</div>
						<div className="footer-actions -borderless">
							<div className="button-group">
								<Link to="/auth/sign-in" className="button-link -secondary">Continue</Link>
							</div>
						</div>
					</>
				)}
				{!showThanks && (
					<>
						<div className="form">
							<p>Enter your email address below and we’ll send you a link so you can reset your Umbrella Wellbeing password.</p>
							
							<div className="form-input -text">
								<Formalities.Text controller={controller} prop="username" className="field" autoComplete="username" autoFocus={true} placeholder="Email address" type="email" />
							</div>
						</div>
						<div className="footer-actions -borderless">
							<div className="button-group">
								<Link to="/auth/sign-in" className="button-link -secondary">Cancel</Link>
							</div>
							<div className="button-group -right">
								<input type="submit" className="button-link -action" value="Send reset password" onClick={doRequestResetPassword} />
							</div>
						</div>
					</>
				)}
			</form>
		</Screen>
	)
}
