import * as actions from './actions'
import * as auth from '../auth/actions'
import { SagaIterator } from 'redux-saga'
import { callApi } from 'modules/api/functions'
import { history } from 'modules/routing'
import { Api } from 'typescript-fetch-api'
import sagaPromiseWrapper, { makeSagaPromiseFunc, SagaPromiseFunc } from 'modules/root/saga-promise-wrapper'
import { takeEvery } from 'redux-saga/effects'
import { RootStoreState } from 'modules/root'
import { Store } from 'redux'

/**
 * When the user is logged in to the admin, we choose their first membership.
 */
const handleLoggedIn: SagaPromiseFunc<RootStoreState, auth.LoggedInAction> = async function({ dispatch, store }, action) {
	dispatch(actions.adminLoading(true))

	let myDetails: Api.PersonDetails
	try {
		myDetails = await callApi(api => api.personApi.getMyDetails(), store)
		dispatch(actions.setPersonDetails(myDetails))
	} catch (error) {
		/* Failures to load a person's details occur if it's not an admin login */
		dispatch(actions.adminLoading(false))
		return
	}
	
	if (myDetails.memberships.length > 0) {
		const adminMembership = myDetails.memberships.find((membership) => membership.role === Api.PersonDetails.Membership.RoleEnum.Admin)
		if (adminMembership) {
			const currentAccount = await callApi((api) => api.accountApi.getAccount(adminMembership.account.id), store)
			dispatch(actions.setCurrentMembership({
				account: currentAccount,
				role: adminMembership.role,
				adminUserRole: adminMembership.adminUserRole,
			}))
			dispatch(actions.adminLoading(false))
		} else {
			dispatch(actions.adminLoading(false))
		}
	} else {
		dispatch(actions.adminLoading(false))
	}

	if (action.payload.intent !== auth.LoginIntent.PRINT) {
		history.replace('/')
	}
}

/**
 * When the user chooses a membership we load the details and setup the store.
 */
const handleChooseMembership = makeSagaPromiseFunc<RootStoreState, ReturnType<typeof actions.chooseMembership>>(async function({ dispatch, store }, action) {
	const membership = action.payload
	const currentAccount = await callApi((api) => api.accountApi.getAccount(membership.account.id), store)
	dispatch(actions.setCurrentMembership({
		account: currentAccount,
		role: membership.role,
		adminUserRole: membership.adminUserRole,
	}))

	history.push('/')
})

export default function* adminSagas(store: Store<RootStoreState>): SagaIterator {
	const sw = sagaPromiseWrapper(store)
	yield takeEvery(auth.loggedIn, sw(handleLoggedIn))
	yield takeEvery(actions.chooseMembership, sw(handleChooseMembership))
}
