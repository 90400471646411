import React from 'react'
import { useParams, useHistory } from 'react-router'
import Screen from 'modules/common/components/Screen'
import { Formalities, useController } from 'formalities'
import { Link } from 'react-router-dom'
import { useCallApi } from 'modules/common/hooks'
import { apiErrorToMessage } from 'modules/api/functions'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'

interface Params {
	token: string
}

interface Form {
	newPassword: string
	confirmPassword: string
}

const ResetPassword: React.FC = () => {
	const { token } = useParams<Params>()
	const controller = useController<Form>({
		confirmPassword: '',
		newPassword: '',
	})
	const callApi = useCallApi()
	const history = useHistory()

	async function doResetPassword(evt: React.MouseEvent) {
		evt.preventDefault()

		const formValue = controller.snapshot().value
		if (formValue.newPassword !== formValue.confirmPassword) {
			uniqueToastError('Your new password and confirm password must match.')
			return
		}

		try {
			await callApi(api => api.authApi.postResetPassword({
				newPassword: formValue.newPassword,
				token,
			}))

			uniqueToastSuccess('Your password has been reset.')
			history.push('/')
		} catch (error) {
			uniqueToastError(`Your password could not be reset.\n${apiErrorToMessage(error)}`)
		}
	}

	return (
		<Screen title="Reset password" includePageHeader={false}>
			<form className="signin-form">
				<div className="content-header">
					<h1 className="headline-text -centered">Reset password</h1>
				</div>
				<div className="form">
					<p>Please enter a new password for your account.</p>
					<div className="form-field -stacked">
						<label className="label">New Password</label>
						<div className="form-input -text">
							<Formalities.Text className="field" type="password" controller={controller} prop="newPassword" autoComplete="new-password" />
						</div>
					</div>
					<div className="form-field -stacked">
						<label className="label">Confirm Password</label>
						<div className="form-input -text">
							<Formalities.Text className="field" type="password" controller={controller} prop="confirmPassword" autoComplete="new-password" />
						</div>
					</div>
				</div>
				<div className="footer-actions -borderless">
					<div className="button-group">
						<Link to="/" className="button-link -secondary">Cancel</Link>
					</div>
					<div className="button-group -right">
						<input type="submit" className="button-link -action" value="Reset password" onClick={doResetPassword} />
					</div>
				</div>
			</form>
		</Screen>
	)
}

export default ResetPassword
