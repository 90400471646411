import { createBrowserHistory, Location as HistoryLocation } from 'history'
import { History, LocationState } from 'history'
import { gtag } from 'mr-gtag'

export const history = createBrowserHistory({
	basename: '/app',
})

/* Scroll to the top of the page when changing to a new page. */
history.listen((location, action) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	if (!location.state || !(location.state as any).dontScrollToTop) {
		window.scrollTo(0, 0)
	}

	handleHash(location)
	analytics(location)
})

window.addEventListener('load', function(ev) {
	analytics(this.location)
})

export function handleHash(location: Location | HistoryLocation, animated = true) {
	if (location.hash) {
		if (location.hash.startsWith('#')) {
			const element = document.getElementById(location.hash.substr(1))
			if (element) {
				element.scrollIntoView({
					behavior: animated ? 'smooth' : 'auto',
					block: 'center',
				})
			}
		}
	}
}

function analytics(location: Location | HistoryLocation) {
	/* Record Google Analytics after timeout so we have changed the page title correctly using Helmet */
	setTimeout(
		function() {
			gtag('config', 'G-9C0HEY6THS', {
				page_title: document.title,
				page_path: location.pathname + location.search,
			})
		},
		0,
	)
}

export function dontScrollToTopState(): LocationState {
	return {
		dontScrollToTop: true,
	}
}

/** Returns a location descriptor suitable for the <Link> element that will result in the browser
 * scrolling to the top.
 */
export function dontScrollToTopLocation(pathname: string): History.LocationDescriptor {
	return {
		pathname,
		state: dontScrollToTopState(),
	}
}
