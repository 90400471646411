import * as React from 'react'
import Popover, { ArrowContainer } from 'react-tiny-popover'
import { Formalities, Snapshot, wrapComponent, useSnapshotController } from 'formalities'
import { TableAction } from 'modules/admin/types'

export interface Row {
	key: string
	cols: ColItem[]
}

export interface ColItem {
	text?: string | JSX.Element
	link?: string
	centerAlign?: boolean
}

export interface HeaderItem {
	text?: string
	centerAlign?: boolean
	sortBy?: unknown
}

interface Props extends Snapshot<string[] | undefined>{
	individualSelect?: boolean
	filter?: boolean
	headerItems: HeaderItem[]
	rows: Row[]
	onSort?: (sortBy: HeaderItem) => void
	actions?: (itemId?: string) => TableAction[] | undefined
	loadMore?: () => void
}

const Table: React.FC<Props> = function(props) {
	const [selectedItem, setSelectedItem] = React.useState<string | undefined>(undefined)
	const [isPopoverOpen, setPopoverOpen] = React.useState(false)
	const selectedRowsController = useSnapshotController(props)
	const selectedRowsValue = selectedRowsController.snapshot().value


	function onSort(evt: React.MouseEvent, sort: HeaderItem) {
		evt.preventDefault()

		if (props.onSort) {
			props.onSort(sort)
		}
	}

	function onToggleSelectAll(evt: React.MouseEvent) {
		evt.preventDefault()
		if (selectedRowsValue && props.rows.length === selectedRowsValue.length) {
			selectedRowsController.snapshot().setValue(undefined)
		} else {
			const selectAll = []
			for (let i = 0; i < props.rows.length; i++) {
				selectAll.push(props.rows[i].key)
			}
			selectedRowsController.snapshot().setValue(selectAll)
		}
	}


	function onSelectOption(selectAction: TableAction, actionIndex: number, rowItem: string) {
		const element = document.getElementById('selectItem' + actionIndex) as HTMLSelectElement
		const selectedValue = element ? element.value : undefined
		
		if (selectedValue && selectAction.onSelectAction) {
			selectAction.onSelectAction(selectedValue, [rowItem])
			setSelectedItem(undefined)
		}
		setPopoverOpen(false)
	}

	return (
		<div className="tablewrapper">
			<table className="data-table -small">
				<thead>
					<tr>
						{props.individualSelect && <th><a href="/" className="toggle -small" onClick={(e) => onToggleSelectAll(e)}>All</a></th>}
						{
							props.headerItems.map((item, index) => (
								<th key={index} className={item.centerAlign ? 'centeralign' : ''}>
									{
										item.sortBy ? 
											<a href="/" className="toggle" onClick={item.sortBy ? ((e) => onSort(e, item)) : undefined}>
												{item.text}
											</a> :
											item.text
									}
								</th>
							))
						}
						<th></th>
					</tr>
				</thead>
				<tbody>
					{
						props.rows.map((row, index) =>
							<tr key={index}>
								{props.individualSelect && 
									<td>
										<label className="inline-option -checkbox">
											<Formalities.MultiCheckable 
												className="checkbox"
												type="checkbox" 
												name={row.key}
												checkedValue={row.key}
												controller={selectedRowsController} 
												prop="this"
											/>
											<span className="substitute"></span>
										</label>
									</td>
								}
								{
									row.cols.map((colItem, index) => (
										<td key={index} className={colItem.centerAlign ? 'centeralign' : ''}>{colItem.text}</td>
									))
								}
								{
									props.actions && props.actions(row.key) !== undefined &&
									<td className="rightalign">
										{row.key === selectedItem ?
											<Popover 
												isOpen={isPopoverOpen}
												position={'top'}
												content={({ position, targetRect, popoverRect }) => (
													<ArrowContainer
														position={position}
														targetRect={targetRect}
														popoverRect={popoverRect}
														arrowColor={'white'}
														arrowSize={10}
														arrowStyle={{ opacity: 1 }}
													>
														<div className="popup-menu">
															<ul className="menuitems">
																{props.actions!(row.key)!.map((action, actionIndex) => {
																	if (action.onSelectAction) {
																		return (
																			<li key={actionIndex} className="menuitem -subheading">
																				<h4 className="ui-heading -small">{action.name}</h4>
																				<div className="buttoned-field">
																					<div className="form-input -select">
																						<select className="select -small" id={'selectItem' + actionIndex}>
																							{action.selectableItems?.map((item, itemIndex) => (
																								<option key={itemIndex} value={item}>{item}</option>
																							))}
																						</select>
																					</div>
																					<button className="button-link -action -small" onClick={() => onSelectOption(action, actionIndex, row.key)}>
																						{action.selectButtonTitle}
																					</button>
																				</div>
																			</li>
																		)
																	} else if (action.onAction) {
																		return (
																			<li key={actionIndex} className="menuitem"><a href="/" onClick={(e) => {
																				e.preventDefault()
																				action.onAction!([row.key])
																				setPopoverOpen(false)
																			}} className="link">{action.name}</a></li>
																		)
																	} else return null
																},
																)}
															</ul>
														</div>
													</ArrowContainer>
												)}
												onClickOutside={() => setPopoverOpen(false)}>
												<span className="icon-unit -more" onClick={() => {
													setSelectedItem(undefined) 
												}}></span></Popover> : 
											<span className="icon-unit -more" onClick={() => {
												setSelectedItem(row.key)
												setPopoverOpen(true) 
											}}></span>
										}	
									</td>
								}
							</tr>,
						)
					}
					{props.loadMore && (
						<tr className="more">
							<td colSpan={6}><button onClick={props.loadMore} className="button-link -action -small">Load more</button></td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default wrapComponent(Table)