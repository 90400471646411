import * as React from 'react'
import Login from '../components/Login'
import { Switch, Route } from 'react-router'
import Logout from './Logout'
import RequestResetPassword from './RequestResetPassword'
import ResetPassword from './ResetPassword'

const LoginRouter: React.FC = () => {
	return (
		<Switch>
			{/* <Route path="/sign-in/forgot-password" component={ForgotPassword} /> */}
			<Route path="/auth/sign-in" component={Login} />
			<Route path="/auth/sign-out" component={Logout} />
			<Route path="/auth/reset-password" component={RequestResetPassword} />
			<Route path="/auth/r/:token" component={ResetPassword} />
		</Switch>
	)
}

export default LoginRouter
