import * as React from 'react'
import { QuestionProps } from './types'
import { useController, Formalities } from 'formalities'
import QuestionScreen from './QuestionScreen'
import QuestionHeader from './QuestionHeader'
import { AnswerRequest, MultichoiceQuestion } from 'typescript-fetch-api'
import { useKeyboardNavigation, useDisableKeyboardNavigationForRadios } from '../hooks'
import { uniqueToastError } from 'modules/common/functions'

interface FormState {
	answer: number
	stringValue: string
}

export default function QuestionRadios(props: QuestionProps<MultichoiceQuestion>) {
	const { section, question, questionIndex } = props

	useKeyboardNavigation(onPrev, onNext)
	useDisableKeyboardNavigationForRadios()
	
	const controller = useController<FormState>({
		answer: question.answerIndex === undefined ? -1 : question.answerIndex,
		stringValue: question.answeredStringValue ? question.answeredStringValue : '',
	})

	controller.addChangeListener(form => save(form))

	async function save(form: FormState) {
		const answerReq: AnswerRequest = {
			questionId: question.id,
			intValue: form.answer !== -1 ? form.answer : undefined,
		}

		if (question.allowOtherAnswer && form.answer === question.options.length) {
			/* We can only save "other" if there's an other string value */
			if (form.stringValue) {
				answerReq.stringValue = form.stringValue
			} else {
				/* Remove the existing answer */
				answerReq.intValue = undefined
			}
		}

		if (answerReq.intValue === question.answerIndex) {
			return
		}

		const saved = await props.onAnswerQuestion(answerReq)
		if (!saved) {
			controller.snapshot('answer').setValue(question.answerIndex === undefined ? -1 : question.answerIndex)
		}
	}

	function onNext(evt?: React.FormEvent) {
		evt && evt.preventDefault()

		const form = controller.snapshot().value
		if (question.allowOtherAnswer && form.answer === question.options.length && !form.stringValue && !question.optional) {
			uniqueToastError('Please specify the ‘other’ option.')
			return false
		} else if (form.answer === -1 && !question.optional) {
			uniqueToastError('Please choose an answer before continuing')
			return false
		}
		props.onNext()
	}

	function onSelectOther(evt: React.FocusEvent) {
		const snapshot = controller.snapshot()
		const form: FormState = {
			...snapshot.value,
		}
		form.answer = question.options.length
		snapshot.setValue(form)
	}

	function canProgress() {
		const form = controller.snapshot().value
		if (question.allowOtherAnswer && form.answer === question.options.length && !form.stringValue && !question.optional) {
			return false
		} else if (form.answer === -1) {
			return false
		} else {
			return true
		}
	}

	function onPrev(evt?: React.MouseEvent) {
		evt && evt.preventDefault()
		props.onPrev()
	}

	function onExit(evt: React.MouseEvent) {
		evt.preventDefault()
		props.onExit()
	}

	return (
		<QuestionScreen section={section} question={question}>
			<div className="survey-question">
				<QuestionHeader question={question} questionIndex={questionIndex} />
				{
					question.options &&
					<div className="form-row">
						<ul className="option-inputs -large">
							{
								question.options.map((option, optionIndex) => (
									<li key={optionIndex} className="option -radio">
										<label className="label">
											<Formalities.Checkable type="radio" className="radio" controller={controller} prop="answer" checkedValue={optionIndex} />
											<span className="substitute"></span>
											{option}
										</label>
									</li>
								))
							}
							{
								question.allowOtherAnswer &&
								<li className="option -radio">
									<label className="label">
										<Formalities.Checkable type="radio" className="radio" controller={controller} prop="answer" checkedValue={question.options.length} />
										<span className="substitute"></span>
										<div className="other">
											<span className="label">Other</span>
											<div className="form-input -text">
												<Formalities.Text controller={controller} prop="stringValue" className="field" placeholder="Please specify…" onFocus={onSelectOther} />
											</div>
										</div>
									</label>
								</li>
							}
						</ul>
					</div>

				}
				<div className="footer-actions">
					<div className="button-group">
						<a href="/" className="button-link -secondary -back" onClick={onPrev}>Back</a>
						<a href="/" className="button-link -text" onClick={onExit}>Exit survey</a>
					</div>
					<div className="button-group -right">
						<input type="submit" className={'button-link -action -next ' + (canProgress() ? '' : '-disabled')} value="Next" onClick={onNext} />
					</div>
				</div>

				<div className="keyboard-nav">
					<p className="option">You can also use your <span className="key">&larr;</span> <span className="key">&rarr;</span> arrow keys to navigate forward &amp; back</p>
				</div>
			</div>
		</QuestionScreen>
	)
}
