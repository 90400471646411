import React, { useCallback, useState } from 'react'
import Screen from 'modules/common/components/Screen'
import { useHistory } from 'react-router'
import Logo from './Logo'
import { useSelector } from 'react-redux'
import { selectSurvey, selectParticipation } from 'modules/participant/selectors'
import { useCallApi } from 'modules/common/hooks'
import { apiErrorToMessage } from 'modules/api/functions'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'
import { useBeforeunload } from 'react-beforeunload'
import { useController, Formalities } from 'formalities'

interface RegisterForm {
	givenName: string
	familyName: string
	emailAddress: string
	password: string
	confirmPassword: string
	existing: boolean
}

export default function Complete() {
	const survey = useSelector(selectSurvey)
	const participation = useSelector(selectParticipation)
	const callApi = useCallApi()
	const [showLeavePageMessage, setShowLeavePageMessage] = useState(false)

	if (!survey) {
		throw new Error('No survey')
	}

	const history = useHistory()

	useBeforeunload((event: Event) => {
		setShowLeavePageMessage(true)
		event.preventDefault()
	})

	const controller = useController<RegisterForm>({
		givenName: '',
		familyName: '',
		emailAddress: '',
		password: '',
		confirmPassword: '',
		existing: false,
	})

	const snapshot = controller.snapshot()

	const handleRegister = useCallback(function(evt: React.MouseEvent) {
		evt.preventDefault()

		if (!controller.value.emailAddress || !controller.value.password) {
			uniqueToastError('Please complete the form and try again.')
			return
		}

		if (!controller.value.existing) {
			if (controller.value.password !== controller.value.confirmPassword) {
				uniqueToastError('Your new password was not entered the same both times. Please try again.')
				return
			}
		}

		callApi(api => api.participationApi.registerParticipation(participation!.participationId, {
			givenName: controller.value.givenName,
			familyName: controller.value.familyName,
			emailAddress: controller.value.emailAddress,
			password: controller.value.password,
			existingAccount: controller.value.existing,
		}).then((result) => {
			if (result.messages.length) {
				uniqueToastSuccess(result.messages[0].message)
			}
			history.push('/p/report')
		})).catch(e => {
			uniqueToastError(`${apiErrorToMessage(e)}`)
		})
	}, [callApi, history, participation, controller])

	const handleSkip = useCallback(function(evt: React.MouseEvent) {
		evt.preventDefault()

		history.push('/p/report')
	}, [history])

	return (
		<Screen title="Register" includePageHeader={false} class="-survey">
			<div className="survey-introduction">
				<Logo survey={survey} />
				{showLeavePageMessage &&
					<div className="form-row -message">
						<div className="message -error">
							<p className="icon-text -msgerror">Don’t forget to create your Umbrella account so you can come back to your report and the Knowledge Library.</p>
						</div>
					</div>
				}
				<h1 className="headline-text -centered">Create an Umbrella account…</h1>
				<div className="body body-text -large">
					<p>
						Before we show you your results, please create an Umbrella account. This will allow you to
						come back to view your wellbeing report and the recommended resources from the
						Wellbeing Knowledge Library at any time. The account information is used solely to allow
						you to access your reports and resources.
					</p>
					<p>
						You can skip this step if you do not wish to return to access your report or the Wellbeing
						Knowledge Library at a later stage.
					</p>
					<form className="signin-form">
						<div className="form">
							<div className="form-input -type">
								<ul className="option-inputs -inline">
									<li className="option -radio">
										<label className="label">
											<Formalities.Checkable type="radio" className="radio" controller={controller} prop="existing" checkedValue={false} /><span className="substitute"></span>
											Create account
										</label>
									</li>
									<li className="option -radio">
										<label className="label">
											<Formalities.Checkable type="radio" className="radio" controller={controller} prop="existing" checkedValue={true} /><span className="substitute"></span>
											Use existing account
										</label>
									</li>
								</ul>
							</div>
							{!snapshot.value.existing ? (
								<>
									<div className="form-input -text">
										<Formalities.Text controller={controller} prop="givenName" className="field" autoFocus={true} placeholder="Given name" type="text" />
									</div>
									<div className="form-input -text">
										<Formalities.Text controller={controller} prop="familyName" className="field" placeholder="Family Name" type="text" />
									</div>
									<div className="form-input -text">
										<Formalities.Text controller={controller} prop="emailAddress" className="field" autoComplete="email" placeholder="Personal email address" type="email" />
										<p className="body-text -left -small">
										We recommend using your personal email address so that you can easily access your results
										</p>
									</div>
									<div className="form-input -text">
										<Formalities.Text controller={controller} prop="password" type="password" className="field" autoComplete="new-password" placeholder="New Password" />
									</div>
									<div className="form-input -text">
										<Formalities.Text controller={controller} prop="confirmPassword" type="password" className="field" autoComplete="new-password" placeholder="Confirm Password" />
									</div>
								</>
							) : (
								<>
									<div className="form-input -text">
										<Formalities.Text controller={controller} prop="emailAddress" className="field" autoComplete="email" autoFocus={true} placeholder="Email address" type="email" />
									</div>
									<div className="form-input -text">
										<Formalities.Text controller={controller} prop="password" type="password" className="field" autoComplete="current-password" placeholder="Password" />
									</div>
								</>
							)}
						</div>
						<div className="footer-actions -borderless">
							<div className="button-group -right">
								<input type="submit" className="button-link -secondary" value="Skip" onClick={handleSkip} />
								<input type="submit" className="button-link -action" value={snapshot.value.existing ? 'Use existing account' : 'Create account'} onClick={handleRegister} />
							</div>
						</div>
					</form>
				</div>
				{/* <div className="button-group -centered">
					<a href="/" className="button-link -secondary" onClick={goBack}>Go back</a>
					<a href="/" className="button-link -action" onClick={completeSurvey}>Submit survey</a>
				</div> */}
			</div>
		</Screen>
	)
}