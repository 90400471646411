import React from 'react'
import Screen from 'modules/common/components/Screen'

interface ParticipantErrorBoundaryState {
	error?: Error
}

const INITIAL_STATE: ParticipantErrorBoundaryState = {}

export default class ParticipantErrorBoundary extends React.Component<React.PropsWithChildren<unknown>, ParticipantErrorBoundaryState> {

	public state = INITIAL_STATE

	public static getDerivedStateFromError(error: Error): ParticipantErrorBoundaryState {
		// Update state so the next render will show the fallback UI.
		return { error }
	}

	public render() {
		const { error } = this.state

		if (!error) {
			return this.props.children
		}

		return (
			<Screen title="Error" includePageHeader={false}>
				{error.message === 'invalid_grant' ? (
					<p>The URL you’ve clicked was not valid. Please check your email and try again or contact the survey organiser for support</p>
				) : error.message ? (
					<p>{error.message}</p>
				) : (
					<p>An unknown error occurred. Please try reloading the page.</p>
				)}
			</Screen>
		)
	}
	
}