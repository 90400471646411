import React from 'react'
import { Switch, Route } from 'react-router'
import AdminReportRouter from 'modules/admin/report/components/AdminReportRouter'
import AdminSurveyRouter from 'modules/admin/survey/components/AdminSurveyRouter'
import AccountParticipants from 'modules/admin/participants/components/AccountParticipants'
import AdminReporting from 'modules/admin/report/components/AdminReporting'
import AdminDashboard from 'modules/admin/dashboard/components/AdminDashboard'
import { useSelector } from 'react-redux'
import { selectAdminLoading } from '../selectors'
import LoadingScreen from 'modules/common/components/LoadingScreen'
import SuperAdminOrganisations from 'modules/admin/super/components/SuperAdminOrganisations'
import SuperAdminNewOrganisation from 'modules/admin/super/components/SuperAdminNewOrganisation'

const AdminRouter: React.FC = () => {
	const adminLoading = useSelector(selectAdminLoading)
	if (adminLoading) {
		return <LoadingScreen />
	}

	return (
		<Switch>
			<Route path="/admin/survey/:surveyId/report" component={AdminReportRouter} />
			<Route path="/admin/survey" component={AdminSurveyRouter} />
			<Route path="/admin/participants/:groupId" render={(route) => <AccountParticipants {...route} />}/>
			<Route path="/admin/participants" render={(route) => <AccountParticipants {...route} />}/>
			<Route path="/admin/reporting" component={AdminReporting} />
			<Route path="/admin/super/organisations" component={SuperAdminOrganisations} />
			<Route path="/admin/super/new-organisation" component={SuperAdminNewOrganisation} />
			<Route path="/admin" component={AdminDashboard}/>
		</Switch>
	)
}

export default AdminRouter
