import React from 'react'
import Screen from 'modules/common/components/Screen'
import { useSelector } from 'react-redux'
import { selectSurvey } from 'modules/participant/selectors'
import { Api } from 'typescript-fetch-api'
import ArcChart from 'modules/common/components/ArcChart'
import { Link, useHistory, Route, useRouteMatch } from 'react-router-dom'
import ReportPopup from './ReportPopup'

interface Props {
	report: Api.IndividualReport
	participation: Api.ParticipationInfo
	support: Api.IndividualSupport
}

const Results: React.FC<Props> = function(props) {
	const history = useHistory()
	const survey = useSelector(selectSurvey)
	if (!survey) {
		throw new Error('No survey')
	}

	React.useEffect(function() {
		document.body.classList.add('screen-personalreport')

		return () => {
			document.body.classList.remove('screen-personalreport')
		}
	})

	const { report, participation, support } = props
	const { path } = useRouteMatch()

	function doLogout(evt: React.MouseEvent) {
		evt.preventDefault()
		history.replace('/auth/sign-out')
	}

	return (
		<Screen title="Your survey results" includePageHeader={false} class="-content">
			<div className="report-header">
				<div className="header">
					<div className="strip body-text">
						<p className="survey"><strong>{survey.name}</strong></p>
						{support.supportArticle && (
							<Link to="support" className="button-link -confirm">Need Support Now?</Link>
						)}
						<p className="name"><span className="icon-text -userhead">{report.person?.name}</span> &nbsp;•&nbsp; <a href="/" onClick={doLogout} className="-link">Log out</a></p>
					</div>
					{!participation.registered && (
						<div className="banner body-text">
							<h2>Create an Umbrella account</h2>
							<p>
								This will allow you to come back to view your wellbeing report and the recommended
								resources from the Wellbeing Knowledge Library at any time. The account information is
								used solely to allow you to access your reports and resources.
							</p>
							<p>
								You can skip this step if you do not wish to return to access your report or the Wellbeing
								Knowledge Library at a later stage.
							</p>
							<div className="button-group" style={{ marginTop: '1rem' }}>
								<Link to="/p/survey/register" className="button-link -action">Create account</Link>
							</div>
						</div>
					)}
					{report.title && (
						<>
							{report.title.lead && <p className="subheading-text">{report.title.lead}</p>}
							<h1 className="headline-text -hero">{report.title.text}</h1>
						</>
					)}
				</div>
						
				<div className="personal-results">
					<div className={'results-block -intro -compact' + (report.sections.length > 0 ? '' : ' -full')}>
						<div className="content">
							<div className="summary">
								<p className="body-text -large">{report.summary}</p>
							</div>
							{report.sections.length > 0 &&
								<div className="chart-key">
									<ul className="values">
										<li className="value -poor"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>{report.minLabel || 'Poor'}</li>
										<li className="value -good"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>{report.midLabel || 'Good'}</li>
										<li className="value -great"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>{report.maxLabel || 'Great'}</li>
									</ul>
								</div>
							}
						</div>
					</div>

					{support.highPsychologicalDistressScore && (
						<div className="results-block -intro -compact -full">
							<div className="content">
								<div className="summary">
									<h2 className="headline-text">Now is a good time to reach out</h2>
									<div className="body-text -large">
										<p>
										Your responses suggest that you might have felt a bit low or on-edge recently. Now is a good time to reach out.
										</p>
										{support.supportArticle && (
											<p style={{ marginTop: 20 }}>
												<Link to="support" className="button-link -confirm">Need Support Now?</Link>
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					)}

					{report.sections.length > 0 &&
						<>
							{report.sections.map(renderSection)}
						</>
					}
				</div>

				<div className="resources">
					<div className="text">
						<h2 className="headline-text">So what’s next?</h2>
							
						<div className="body-text -large">
							<p>
								We hope you are feeling encouraged to keep focusing on your wellbeing. For more ideas and inspiration have a look at the resources below.
								You may also want to <a href="https://umbrella.org.nz/#footer" target="_blank" rel="noopener noreferrer">sign up to our monthly newsletter</a> for ongoing wellbeing updates.
							</p>
						</div>
					</div>

					<div className="link-lists">
						<div className="list">
							<h3 className="subheading icon-text -video">TED Talks &amp; video</h3>
							<ul className="linklist">
								<li><a href="https://www.ted.com/talks/shawn_achor_the_happy_secret_to_better_work" target="_blank" rel="noopener noreferrer" className="link"><span className="text"><strong>Psychologist Shawn Achor</strong> on how feeling happy can help us be more productive</span></a></li>
								<li><a href="https://www.youtube.com/watch?v=rni41c9iq54" target="_blank" rel="noopener noreferrer" className="link"><span className="text"><strong>Guy Winch</strong> provides a moving talk about the importance of first aid for our emotional wellbeing</span></a></li>
								<li><a href="https://www.ted.com/talks/matthieu_ricard_the_habits_of_happiness" target="_blank" rel="noopener noreferrer" className="link"><span className="text"><strong>Buddhist monk and Biochemist Matthieu Ricard</strong> describes how we can train our minds in habits of wellbeing</span></a></li>
							</ul>
						</div>
						<div className="list">
							<h3 className="subheading icon-text -article">Articles &amp; books</h3>
							<ul className="linklist">
								<li><a href="https://umbrella.org.nz/thinking/our-book/" target="_blank" rel="noopener noreferrer" className="link"><span className="text">“<strong>I don't want to just get by</strong>, I want to do better than that!” Order a copy of our resilience book</span></a></li>
								<li><a href="https://www.goodreads.com/book/show/11281104-mindfulness" target="_blank" rel="noopener noreferrer" className="link"><span className="text"><strong>Learn the practice of mindfulness</strong> to help you create calm in a chaotic world</span></a></li>
								<li><a href="https://optionb.org/book" target="_blank" rel="noopener noreferrer" className="link"><span className="text"><strong>Stories, psychological research and practical advice</strong> for coping with life's challenges from Sheryl Sandberg and Psychologist Adam Grant</span></a></li>
							</ul>
						</div>
						<div className="list">
							<h3 className="subheading icon-text -audio">Podcasts &amp; audio</h3>
							<ul className="linklist">
								<li><a href="https://www.bbc.co.uk/programmes/b006qxx9/episodes/downloads" target="_blank" rel="noopener noreferrer" className="link"><span className="text"><strong><em>All in the Mind:</em></strong> Interesting and enjoyable podcasts from BBC Radio 4 about the human mind</span></a></li>
								<li><a href="https://podcastpals.net/the-7-best-meditation-podcasts/" target="_blank" rel="noopener noreferrer" className="link"><span className="text"><strong>Meditation podcasts:</strong> A collection covering many different kinds of meditation</span></a></li>
								<li><a href="https://www.mentalhealth.org.uk/podcasts-and-videos/podcasts-for-your-wellbeing" target="_blank" rel="noopener noreferrer" className="link"><span className="text"><strong>Wellbeing podcasts from the UK Mental Health Foundation</strong>, on topics from nutrition to mental health &amp; more</span></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			{support.supportArticle && (
				<Route path={`${path}support`} render={(route) => <ReportPopup
					title='Need Support Now?'
					body={support.supportArticle!}
					onClose={() => history.goBack()}
				/>} />
			)}
		</Screen>
	)
}

function renderSection(section: Api.IndividualReport.Section, index: number) {
	return (
		<div key={index} className="block">
			<div className="results-block -usersummary">
				<div className="wrap">
					<div className="header">
						<div className={'icon-unit -large -theme -' + section.code} />
						<ArcChart score={section.score} variant="small" />
						<h2 className={`icon-text subheading${section.code ? ` -${section.code}` : ''}`}>{section.name}</h2>
					</div>
					<div className="content">
						<div className="text">
							<div className="body-text">
								<p className="intro"><strong>{section.intro}</strong></p>
								<p>{section.summary}</p>
							</div>
							<Link to={`/p/report/section/${index}`} className="button-link -secondary -next">More Info &amp; resources</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Results
