import * as React from 'react'
import { QuestionProps } from './types'
import { useController, Formalities } from 'formalities'
import QuestionScreen from './QuestionScreen'
import QuestionHeader from './QuestionHeader'
import { AnswerRequest, FreeTextQuestion } from 'typescript-fetch-api'
import { useKeyboardNavigation } from '../hooks'
import { uniqueToastError } from 'modules/common/functions'

interface FormState {
	answer: string
}

export default function QuestionTextFree(props: QuestionProps<FreeTextQuestion>) {
	const { section, question, questionIndex } = props

	useKeyboardNavigation(onPrev, onNext)

	const controller = useController<FormState>({
		answer: question.answeredStringValue ? question.answeredStringValue : '',
	})

	controller.addChangeListener(form => save(form))
	
	async function save(form: FormState) {
		if (form.answer === '' && !question.optional) {
			return false
		}
	
		const answerReq: AnswerRequest = {
			questionId: question.id,
			stringValue: form.answer,
		}
		return await props.onAnswerQuestion(answerReq)
	}

	function onNext(evt?: React.FormEvent) {
		evt && evt.preventDefault()

		const form = controller.snapshot().value
		if (form.answer === '' && !question.optional) {
			uniqueToastError('Please enter an answer before continuing.')
			return false
		}
		props.onNext()
	}

	function canProgress() {
		const form = controller.snapshot().value
		if (form.answer === '' && !question.optional) {
			return false
		} else {
			return true
		}
	}

	function onPrev(evt?: React.MouseEvent) {
		evt && evt.preventDefault()
		props.onPrev()
	}

	function onExit(evt: React.MouseEvent) {
		evt.preventDefault()
		props.onExit()
	}

	return (
		<QuestionScreen section={section} question={question}>
			<form className="survey-question">
				<QuestionHeader question={question} questionIndex={questionIndex} />
				<div className="form-row">
					<div className="form-input -textarea">
						<Formalities.TextArea className="field" controller={controller} prop="answer" />
					</div>
				</div>
					
				<div className="footer-actions">
					<div className="button-group">
						<a href="/" className="button-link -secondary -back" onClick={onPrev}>Back</a>
						<a href="/" className="button-link -text" onClick={onExit}>Exit survey</a>
					</div>
					<div className="button-group -right">
						<input type="submit" className={'button-link -action -next ' + (canProgress() ? '' : '-disabled')} value="Next" onClick={onNext}/>
					</div>
				</div>

				<div className="keyboard-nav">
					<p className="option">You can also use your <span className="key">&larr;</span> <span className="key">&rarr;</span> arrow keys to navigate forward &amp; back</p>
				</div>
			</form>
		</QuestionScreen>
	)
}
