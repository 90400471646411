import { SagaIterator } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'
import * as auth from 'modules/auth/actions'
import { history } from '.'

function handleLogoutRelocation(action: auth.LoggedOutAction) {
	if (action.payload.relocate === true) {
		history.push('/')
	} else {
		return 
	}
}

export default function* routingSagas(): SagaIterator {
	yield takeEvery(auth.loggedOut, handleLogoutRelocation)
}
