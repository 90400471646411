import React, { useState } from 'react'
import Screen from 'modules/common/components/Screen'
import { useCurrentAccount, useCanEdit } from 'modules/admin/hooks'
import { surveyStatusToString, surveyStatusToClassName } from 'modules/admin/functions'
import moment from 'moment'
import { Link, Route, useRouteMatch, useHistory } from 'react-router-dom'
import { useCursoredDataEffect, useCallApi } from 'modules/common/hooks'
import LoadingInline from 'modules/common/components/LoadingInline'
import { pathToSurvey, pathToNewSurvey } from '../navigation'
import { pathToSurveyReport } from '../../report/navigation'
import { SortSurveys, FilterSurveys } from 'typescript-fetch-api'
import BasicActionModal from 'modules/common/components/BasicActionModal'
import Popover, { ArrowContainer } from 'react-tiny-popover'
import ErrorInline from 'modules/common/components/ErrorInline'
import { apiErrorToMessage } from 'modules/api/functions'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'

export default function YourSurveys() {
	const account = useCurrentAccount()
	const { path } = useRouteMatch()
	const history = useHistory()
	const [sort, setSort] = useState<SortSurveys | undefined>()
	const [isPopoverOpen, setPopoverOpen] = useState(false)
	const [selectedSurvey, setSelectedSurvey] = useState<string | undefined>(undefined)
	const [filter, setFilter] = useState<FilterSurveys[]>([FilterSurveys.Current])
	const callApi = useCallApi()
	const { response, loadMore, refresh } = useCursoredDataEffect((cursor) => callApi(api => api.accountApi.getSurveys(account.id, cursor, sort, filter)), [account.id, callApi, filter, sort])

	function onSortSurveys(evt: React.MouseEvent, sortBy: SortSurveys) {
		evt.preventDefault()
		setSort(sortBy)
	}

	function onFilterSurveys(evt: React.MouseEvent, filterBy: FilterSurveys) {
		evt.preventDefault()
		setFilter([filterBy])
	}

	async function onArchiveSurvey(evt: React.MouseEvent) {
		evt.preventDefault()
		if (selectedSurvey) {
			try {
				await callApi(api => api.surveyApi.patchSurvey(selectedSurvey, {
					archived: true,
				}))
				refresh()
				uniqueToastSuccess('Archived survey')
			} catch (error) {
				uniqueToastError(`Failed to archive survey.\n${apiErrorToMessage(error)}`)
			}
		}
	}

	async function onUnarchiveSurvey(evt: React.MouseEvent) {
		evt.preventDefault()
		if (selectedSurvey) {
			try {
				await callApi(api => api.surveyApi.patchSurvey(selectedSurvey, {
					archived: false,
				}))
				refresh()
				uniqueToastSuccess('Unarchived survey')
			} catch (error) {
				uniqueToastError(`Failed to unarchive survey.\n${apiErrorToMessage(error)}`)
			}
		}
	}

	function onShowDeleteSurveyModal(evt: React.MouseEvent) {
		evt.preventDefault()
		history.push(`${path}/delete/${selectedSurvey}`)
	}

	async function onDeleteSurvey(surveyToDelete?: string) {
		if (surveyToDelete) {
			try {
				await callApi(api => api.surveyApi.deleteSurvey(surveyToDelete))
				history.push(`${path}`)
				uniqueToastSuccess('Deleted survey')
				refresh()
			} catch (error) {
				uniqueToastError(`Failed to delete survey.\n${apiErrorToMessage(error)}`)
			}
		}
	}

	const canEdit = useCanEdit()

	return (
		<Screen title="Your surveys" includePageHeader={false} additionalClass="screen-surveys">
			<div className="content-header -split">
				<h1 className="headline-text">{account.name}’s {filter[0] === FilterSurveys.Archived && 'archived '}surveys</h1>
				{canEdit &&
					<Link to={pathToNewSurvey()} className="button-link -action">New survey</Link>
				}
			</div>
			<div className="surveys">
				<table className="data-table">
					<thead>
						<tr>
							<th><a href="/" className="sort" onClick={(e) => onSortSurveys(e, SortSurveys.Name)}>Name</a></th>
							<th><a href="/" className="sort" onClick={(e) => onSortSurveys(e, SortSurveys.Type)}>Type</a></th>
							<th><a href="/" className="sort" onClick={(e) => onSortSurveys(e, SortSurveys.Status)}>Status</a></th>
							<th><a href="/" className="sort" onClick={(e) => onSortSurveys(e, SortSurveys.Modified)}>Modified</a></th>
							<th className="centeralign">Report</th>
							{canEdit &&
							<th></th>
							}
						</tr>
					</thead>
					<tbody>
						{response.loading &&
							<tr>
								<td colSpan={6}><LoadingInline centred={true}/></td>
							</tr>
						}
						{response.error && (
							<tr>
								<td colSpan={6}><ErrorInline error={response.error} /></td>
							</tr>
						)}
						{response.result && (response.result.surveys.length ? (
							<>
								{response.result && response.result.surveys.map((survey, index) => (
									<tr key={index}>
										<th scope="row"><Link to={pathToSurvey(survey)} className="title">{survey.name}</Link></th>
										{survey.template && <td className="type">{survey.template.name}</td>}
										<td className="status"><span className={`status-slug -small ${surveyStatusToClassName(survey.status)}`}>{surveyStatusToString(survey.status)}</span></td>
										<td className="modified"><span className="_sm-down">Modified</span> {moment(survey.lastModified).fromNow()}</td>
										<td className="report centeralign">
											{survey.reportAvailable ? 
												<Link to={pathToSurveyReport(survey.id)} className="icon-unit -report">View</Link>
												: <span className="icon-unit -empty">Unavailable</span>
											}
										</td>
										{canEdit &&
										<td className="options rightalign" onClick={() => {
											if (selectedSurvey === survey.id) {
												setSelectedSurvey(undefined)
											} else {
												setSelectedSurvey(survey.id)
											}
											setPopoverOpen(true) 
										}}>
											{survey.id === selectedSurvey ?
												<Popover 
													isOpen={isPopoverOpen}
													position={'top'}
													content={({ position, targetRect, popoverRect }) => (
														<ArrowContainer
															position={position}
															targetRect={targetRect}
															popoverRect={popoverRect}
															arrowColor={'white'}
															arrowSize={10}
															arrowStyle={{ opacity: 1 }}
														>
															<div className="popup-menu">
																<ul className="menuitems">
																	<li className="menuitem"><Link to={pathToSurvey(survey)} className="link">Edit</Link></li>
																	{
																		filter[0] === FilterSurveys.Current ? 
																			survey.status !== 'OPEN' && 
																				<li className="menuitem"><a href="/" className="link" onClick={onArchiveSurvey}>Archive</a> </li>
																			: <li className="menuitem"><a href="/" className="link" onClick={onUnarchiveSurvey}>Unarchive</a> </li>
																	}
																	{
																		survey.status !== 'OPEN' &&
																		<li className="menuitem"><a href="/" className="link" onClick={onShowDeleteSurveyModal}>Delete</a></li>
																	}
																</ul>
															</div>
														</ArrowContainer>
													)}
													onClickOutside={() => setPopoverOpen(false)}
												><span className="icon-unit -more" ></span></Popover> : <span className="icon-unit -more" ></span>
											}
										</td>
										}
									</tr>
								))}
								{loadMore && (
									<tr className="more">
										<td colSpan={6}><button onClick={loadMore} className="button-link -action -small">Load more</button></td>
									</tr>
								)}
							</>
						) : (
							<tr className="empty">
								<td colSpan={6}>
									<p>No surveys found</p>
								</td>
							</tr>
						))
						}
					</tbody>
				</table>

			</div>
			<div className="footer">
				{
					filter[0] === FilterSurveys.Current ? 
						<a href="/" className="icon-text -archive" onClick={(e) => onFilterSurveys(e, FilterSurveys.Archived)}>View archive</a>
						: <a href="/" className="icon-text -back" onClick={(e) => onFilterSurveys(e, FilterSurveys.Current)}>View current surveys</a>
				}
			</div>
			<Route path={`${path}/delete/:id`} render={(route) => 
				<BasicActionModal
					title="Delete survey"
					text="Are you sure you want to delete this survey?"
					buttonAction={onDeleteSurvey}
					buttonText="Delete"
					className="dialog-modal"
					{...route}
				/>} 
			/>
		</Screen>
	)
}