import * as React from 'react'
import Screen from 'modules/common/components/Screen'
import { useHistory, Route, useRouteMatch, useLocation } from 'react-router'
import { Api } from 'typescript-fetch-api'
import { surveyStatusToString, surveyStatusToClassName, sendSurveyReminderToToastMessage } from 'modules/admin/functions'
import { pathToSurveyEdit, pathToSurveyParticipants } from '../navigation'
import { useCallApi } from 'modules/common/hooks'
import moment from 'moment'
import BasicActionModal from 'modules/common/components/BasicActionModal'
import { pathToSurveyReport } from 'modules/admin/report/navigation'
import { useCanEdit, useCurrentPerson, useCurrentAccount } from 'modules/admin/hooks'
import { Link } from 'react-router-dom'
import DownloadAnonymisedReport from './DownloadAnonymisedReport'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'
import { apiErrorToMessage } from 'modules/api/functions'

interface Props {
	survey: Api.SurveyDetails
	onChangeSurvey: () => void
}

function processParticipantUrl(url: string | undefined): string | undefined {
	if (url) {
		url = url.replace(/^https?:/, window.location.protocol)
		url = url.replace(/\/\/[^/]+\//, `//${window.location.host}/`)
	}
	return url
}

interface LocationState {
	from?: string
}

const ViewSurvey: React.FC<Props> = function(props) {
	const { survey } = props
	const { url } = useRouteMatch()
	const history = useHistory()
	const callApi = useCallApi()
	const canEdit = useCanEdit()
	const person = useCurrentPerson()
	const account = useCurrentAccount()
	const location = { ...useLocation<LocationState>().state }
	let breadcrumbText = 'surveys'
	let breadcrumbLink = '/admin/survey/your-surveys'

	if (location.from) {
		if (location.from.includes('admin/reporting')) {
			breadcrumbText = 'reports'
			breadcrumbLink = '/admin/reporting'
		} else if (location.from.includes('/admin')) { 
			breadcrumbText = 'dashboard'
			breadcrumbLink = '/admin'
		}
	}

	function goEdit(evt: React.MouseEvent) {
		evt.preventDefault()
		history.push(pathToSurveyEdit(survey))
	}

	async function startSurvey() {
		try {
			await callApi(api => api.surveyApi.openSurvey(survey.id))
			uniqueToastSuccess(`Successfully opened ${survey.name}`)
			props.onChangeSurvey()
		} catch (error) {
			uniqueToastError(`Failed to open ${survey.name}`)
		}
		history.push(`${url}`)
	}

	async function endSurvey() {
		try {
			await callApi(api => api.surveyApi.closeSurvey(survey.id))
			uniqueToastSuccess(`Successfully closed ${survey.name}`)
			props.onChangeSurvey()
		} catch (error) {
			uniqueToastError(`Failed to close ${survey.name}`)
		}
		history.push(`${url}`)
	}


	function onShowDeleteSurveyDataModal(evt: React.MouseEvent) {
		evt.preventDefault()
		history.push(`${url}/delete`)
	}

	async function onDeleteSurveyResults() {
		try {
			await callApi(api => api.accountApi.deleteSurveyResults(account.id, survey.id))
			uniqueToastSuccess(`Successfully deleted data from ${survey.name}`)
			history.push('/admin/survey/your-surveys')
		} catch (error) {
			uniqueToastError(`Failed to delete data from ${survey.name}`)
		}
	}

	function onShowOpenSurveyModal(evt: React.MouseEvent) {
		evt.preventDefault()
		history.push(`${url}/open`)
	}

	function onShowCloseSurveyModal(evt: React.MouseEvent) {
		evt.preventDefault()
		history.push(`${url}/close`)
	}

	function onCopySurveyLink(evt: React.MouseEvent) {
		evt.preventDefault()
		const url = processParticipantUrl(survey.participantUrl)
		if (url) {
			navigator.clipboard.writeText(url)
			uniqueToastSuccess('Link copied to clipboard')
		}
	}

	function onViewReport(evt: React.MouseEvent) {
		evt.preventDefault()
		const url = pathToSurveyReport(survey.id)

		history.push({
			pathname: url,
			state: { 
				from: window.location.pathname,
			},
		})
	}
	
	async function goPreview(evt: React.MouseEvent) {
		evt.preventDefault()

		const response = await callApi(api => api.surveyApi.createPreview(survey.id))

		const href = history.createHref({ pathname: `/p/${response.participationToken}` })
		window.location.assign(href)
	}

	function goParticipants(evt: React.MouseEvent) {
		evt.preventDefault()
		history.push(pathToSurveyParticipants(survey))
	}

	async function onSendReminderEmails(evt: React.MouseEvent) {
		evt.preventDefault()
		
		try {
			const response = await callApi(api => api.surveyApi.sendReminders(survey.id))
			const responseToToastMessage = sendSurveyReminderToToastMessage(response)
			uniqueToastSuccess(`${responseToToastMessage}`)
		} catch (error) {
			uniqueToastError(`Failed to send reminders.\n${apiErrorToMessage(error)}`)
		}
	}

	return (
		<Screen title="Edit survey" includePageHeader={false} breadcrumbText={breadcrumbText} breadcrumbLink={breadcrumbLink}>
			<div className="content-header">
				<h1 className="headline-text">{survey.name} <span className={`status-slug -small ${surveyStatusToClassName(survey.status)}`}>{surveyStatusToString(survey.status)}</span></h1>
				{survey.template && <p className="body-text -large surveytype">{survey.template.name}</p>}
			</div>
			<div className="body">
				<div className="survey-overview">
					<div className="blocks">
						<div className="block -participants">
							<h2 className="subheading-text" onClick={goParticipants}>Participants</h2>
							<p className="value" onClick={goParticipants}>{survey.participants.totalCount}</p>
							<p className="body-text -small"><Link to={pathToSurveyParticipants(survey)}>View participants</Link></p>
						</div>
						<div className="block -completed">
							<h2 className="subheading-text">Completed</h2>
							<p className="value">{survey.participants.completedCount}</p>
							<p className="body-text -small">
								{survey.participants.inProgressCount} in progress 
								({survey.participants.halfWayCount} &gt; 50%)
								<br/>
								{survey.participants.notStartedCount} not started
							</p>
							{survey.status === Api.SurveySummary.StatusEnum.OPEN &&
								<>
									<p className="body-text reminder-link"><strong><a href="/" onClick={onSendReminderEmails}>Send reminder emails</a></strong></p>
									{survey.whenInvitationsLastSent &&
										<p className="body-text -small">Invitations or reminders last sent <br/>{moment(survey.whenInvitationsLastSent).fromNow()}</p>
									}
								</>
							}
						</div>
						<div className="block -scheduling">
							<h2 className="subheading-text">Scheduling</h2>
							{survey.scheduling || survey.whenOpen || survey.whenClosed ? (
								<>
									<div className="dates">
										{(survey.whenOpen || (survey.scheduling && survey.scheduling.openAt)) && (
											<p className="date body-text -large">
												<strong className="ui-heading">{(survey.status === Api.SurveySummary.StatusEnum.OPEN) || (survey.status === Api.SurveySummary.StatusEnum.CLOSED) ? 'Opened' : 'Opens'}</strong>
												{moment(survey.whenOpen ? survey.whenOpen : survey.scheduling!.openAt).format('h:mma D MMMM, YYYY')}
											</p>
										)}
										{(survey.whenClosed || (survey.scheduling && survey.scheduling.closeAt)) && (
											<p className="date body-text -large">
												<strong className="ui-heading">{survey.status === Api.SurveySummary.StatusEnum.CLOSED ? 'Closed' : 'Ends'}</strong>
												{moment(survey.whenClosed ? survey.whenClosed : survey.scheduling!.closeAt).format('h:mma D MMMM, YYYY')}
											</p>
										)}
									</div>
									{survey.status === Api.SurveySummary.StatusEnum.OPEN && survey.scheduling && survey.scheduling.closeAt && (
										<p className="body-text -small">
											{moment(survey.scheduling.closeAt).diff(moment(Date.now()), 'days')} days remaining
										</p>
									)}
								</>
							) : (
								<div className="dates">
									<div className="body-text">{survey.status === Api.SurveySummary.StatusEnum.READY ? 
										'Click "Open Survey" to launch the survey, or edit the survey to add a scheduled start date.' : 
										'Not scheduled'}
									</div>
								</div>
							)}
						</div>
						<form className="block -actions">
							<h2 className="subheading-text">Actions</h2>
							<div className="button-group -stacked">
								{survey.status !== Api.SurveySummary.StatusEnum.CLOSED && canEdit &&
									<button className="button-link -secondary" onClick={goEdit}>Edit</button>
								}
								<button className="button-link -secondary" onClick={goPreview}>Preview</button>
								{survey.reportAvailable &&
									<button className="button-link -action" onClick={onViewReport}>View report</button>}
								{survey.status === Api.SurveySummary.StatusEnum.OPEN && canEdit ?
									<input type="submit" className="button-link -destructive" value="Close survey" onClick={onShowCloseSurveyModal}/>
									: survey.status !== Api.SurveySummary.StatusEnum.CLOSED && canEdit ?
										<input type="submit" className="button-link -confirm" value="Open survey" onClick={onShowOpenSurveyModal} 
											disabled={survey.status === Api.SurveySummary.StatusEnum.DRAFT ? true : false}
										/>
										: null
								}
							</div>
						</form>
						{(survey.whenOpen && survey.participants.totalCount > 0) && (
							<div className="progress">
								<div className="bar-chart -background">
									<div className="bar -background" style={{ width: `${Math.round((survey.participants.completedCount + survey.participants.inProgressCount) * 100 / survey.participants.totalCount)}%` }}>
										<svg viewBox="0 0 16 16" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
											<rect x="0" width="16" height="16" />
										</svg>
									</div>
									<div className="bar -great" style={{ width: `${Math.round((survey.participants.completedCount) * 100 / survey.participants.totalCount)}%` }}>
										<svg viewBox="0 0 16 16" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
											<rect x="0" width="16" height="16" />
										</svg>
									</div>
								</div>
								<div className="context">
									<p className="ui-heading">{Math.round((survey.participants.completedCount) * 100 / survey.participants.totalCount)}% Participants<span className="_md-up"> completed</span></p>
									<div className="chart-key">
										<ul className="values">
											<li className="value -great"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>Completed</li>
											<li className="value -background"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>In progress</li>
										</ul>
									</div>
								</div>
							</div>
						)}
					</div>
					{(survey.status === Api.SurveySummary.StatusEnum.CLOSED ||
					person.superAdmin) &&
					<div className="delete-download">
						<DownloadAnonymisedReport accountId={account.id} surveyId={survey.id} />
						{person.superAdmin && <button className="button-link -destructive delete-data" onClick={onShowDeleteSurveyDataModal}>Delete survey results</button>}
					</div>
					}
				</div>
				{survey.status !== Api.SurveySummary.StatusEnum.CLOSED &&
					<div className="survey-link">
						<div className="buttoned-field">
							<div className="form-input -text"><input type="text" className="field" value={processParticipantUrl(survey.participantUrl) || ''} readOnly={true} /></div>
							<button className="button-link -secondary" onClick={onCopySurveyLink}>Copy</button>
						</div>
						<div className="body-text -small">
							<p>Any participants using this link while the survey is running will be included in the survey results.</p>
						</div>
					</div>
				}
			</div>
			<Route path={`${url}/open`} render={(route) => 
				<BasicActionModal
					title="Open survey"
					text="Are you sure you want to open this survey?"
					buttonAction={startSurvey}
					buttonText="Open survey"
					className="dialog-modal"
					{...route}
				/>} 
			/>
			<Route path={`${url}/close`} render={(route) => 
				<BasicActionModal
					title="Close survey"
					text="Are you sure you want to close this survey? It cannot be reopened once closed."
					buttonAction={endSurvey}
					buttonText="Close survey"
					className="dialog-modal"
					{...route}
				/>} 
			/>
			<Route path={`${url}/delete`} render={(route) => 
				<BasicActionModal
					title="Delete survey results"
					text="Are you sure you want to delete the results from this survey? Both the survey and any results will be deleted. This cannot be undone."
					buttonAction={onDeleteSurveyResults}
					buttonText="Delete"
					className="dialog-modal"
					{...route}
				/>} 
			/>
		</Screen>
	)
}

export default ViewSurvey
