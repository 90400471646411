import actionCreatorFactory from 'typescript-fsa'
import { AccessToken } from './types'

const actionCreator = actionCreatorFactory('Auth')

export interface LoginRequest {
	username: string
	password: string
	rememberMe: boolean
	intent?: LoginIntent
	totpCode?: string
}

export interface LogoutRequest {
	relocate?: boolean
}

export const login = actionCreator.async<LoginRequest, AccessToken, Error>('LOGIN')
export type LoginStarterAction = ReturnType<typeof login.started>
export type LoginDoneAction = ReturnType<typeof login.done>
export type LoginFailedAction = ReturnType<typeof login.failed>

export interface ProgrammaticLoginRequest {
	accessToken: AccessToken
	rememberMe: boolean
	intent?: LoginIntent
}

export const programmaticLogin = actionCreator<ProgrammaticLoginRequest>('PROGRAMMATIC_LOGIN')

export const refreshedToken = actionCreator<AccessToken>('REFRESHED_TOKEN')
export type RefreshedTokenAction = ReturnType<typeof refreshedToken>

/** Signals that refreshing failed. The payload is the time that it failed. */
export const refreshTokenFailed = actionCreator<RefreshTokenFailedPayload>('REFRESH_TOKEN_FAILED')
export interface RefreshTokenFailedPayload {
	refreshToken: string
	date: number
}

/** Action creator for the logout request. */
export const logoutRequest = actionCreator<LogoutRequest>('LOGOUT_REQUEST')

export interface LoggedInPayload {
	username?: string
	rememberMe: boolean
	accessToken: AccessToken
	intent?: LoginIntent
}

export enum LoginIntent {
	PARTICIPATION = 'participation',
	PRINT = 'print',
}

/** The user has logged in. */
export const loggedIn = actionCreator<LoggedInPayload>('LOGGED_IN')
export type LoggedInAction = ReturnType<typeof loggedIn>

/** The user has been logged out. */
export const loggedOut = actionCreator<LogoutRequest>('LOGGED_OUT')
export type LoggedOutAction = ReturnType<typeof loggedOut>

/** An error has occurred while the user is logged in, either logging out or refreshing the token. */
export const loggedInError = actionCreator<Error>('LOGGED_IN_ERROR')
