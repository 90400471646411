import * as React from 'react'
import update from 'immutability-helper'
import { Answer } from './Answer'

interface Props {
	answers: string[]
	setAnswers: (value: string[]) => void
	onRemoveAnswer: (evt: React.FormEvent, answerToRemove: string) => void
	editAnswer: (evt: React.FormEvent, answerToEdit: string) => void
}

const CustomQuestionAnswerTable: React.FC<Props> = function(props) {
	const { answers, setAnswers, editAnswer } = props
	const moveAnswer = React.useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const dragCard = answers[dragIndex]
			setAnswers(
				update(answers, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragCard],
					],
				}),
			)
		},
		[answers, setAnswers],
	)

	return (
		<div className="answers-list body-text">
			{
				answers.map((answer, index) => (
					<Answer 
						key={answer} 
						onRemoveAnswer={props.onRemoveAnswer}
						answer={answer} 
						index={index} 
						moveAnswer={moveAnswer} 
						editAnswer={editAnswer}
					/>
				))
			}
		</div>
	)
}

export default CustomQuestionAnswerTable