import React from 'react'
import { ApiError } from 'modules/api/types'
import { useApiError } from '../hooks'

interface Props {
	error: ApiError
}

function ErrorInline(props: Props) {
	const messages = useApiError(props.error)
	return (
		<>
			{messages.map((m, i) => <p key={i}>{m}</p>)}
		</>
	)
}

export default ErrorInline
