import React from 'react'
import { Api } from 'typescript-fetch-api'
import MyReactQuill from 'modules/common/components/MyReactQuill'
import { ReportAreaAnalysisData } from 'modules/admin/types'

interface Props {
	area: Api.SurveyReportArea
	areaIndex: number
	hideAnalysis: boolean
	analysisText: ReportAreaAnalysisData[]
	onAnalysisChange: (index: number, text?: string | undefined) => void
	saveAreaAnalysis: (evt: React.FormEvent, index: number, areaId: string) => Promise<void>
	superAdmin?: boolean
	printMode?: boolean
}

const ReportAreaAnalysis: React.FC<Props> = function(props) {
	const { area, areaIndex, hideAnalysis, analysisText, onAnalysisChange, saveAreaAnalysis, superAdmin, printMode } = props

	if (!hideAnalysis && !printMode && superAdmin) {
		return (
			<>
				<h2 className="subheading-text">Analysis</h2>
				<div className="summaryinput">
					<div className="form-field">
						<div className="form-input -text">
							<MyReactQuill
								theme="bubble"
								placeholder="Enter Umbrella analysis to appear on the report."
								className="field body-text"
								value={analysisText[areaIndex].text || ''}
								onChange={(content, delta, sources, editor) => onAnalysisChange(areaIndex, editor.getHTML())}
							/>
						</div>
					</div>
					<input type="submit" className="button-link -action" value="Save" onClick={(e) => saveAreaAnalysis(e, areaIndex, area.id)} disabled={analysisText[areaIndex].disabled}/> 
				</div>
			</>
		)
	} else if (!hideAnalysis && area.analysis) {
		return (
			<>
				<h2 className="subheading-text">Analysis</h2>
				<div dangerouslySetInnerHTML={{ __html: area.analysis }} />
			</>
		)
	} else {
		return null
	}
}

export default ReportAreaAnalysis