import * as React from 'react'
import Screen from 'modules/common/components/Screen'
import { useHistory } from 'react-router'
import Logo from './Logo'
import { useSelector } from 'react-redux'
import { selectSurvey } from 'modules/participant/selectors'

export default function Exiting() {
	const survey = useSelector(selectSurvey)

	if (!survey) {
		throw new Error('No survey')
	}
	const history = useHistory()

	function goBack(evt: React.FormEvent) {
		evt.preventDefault()
		history.goBack()
	}
	
	function exit(evt: React.FormEvent) {
		evt.preventDefault()
		history.push('exit')
	}

	return (
		<Screen title="Exit" includePageHeader={false} class="-survey">
			<div className="survey-introduction">
				<Logo survey={survey} />
				<h1 className="headline-text -centered">Exiting the survey</h1>
				<div className="body body-text -large">
					<p>
						You are about to leave the survey. If this was done in error you can <a href="/" onClick={goBack}>go back</a> to where you were.
						We can optionally save your progress so that if you choose to come back later you can continue where you left off.
					</p>
				</div>
				<div className="button-group -centered">
					<button className="button-link -action" onClick={goBack}>Return and complete</button>
					<button className="button-link -action" onClick={exit}>Exit</button>
				</div>
			</div>
		</Screen>
	)
}