import { Api } from 'typescript-fetch-api'

export function surveyStatusToString(status: Api.SurveySummary.StatusEnum): string {
	switch (status) {
		case Api.SurveySummary.StatusEnum.CLOSED:
			return 'Closed'
		case Api.SurveySummary.StatusEnum.DRAFT:
			return 'Draft'
		case Api.SurveySummary.StatusEnum.OPEN:
			return 'Open'
		case Api.SurveySummary.StatusEnum.READY:
			return 'Ready'
		case Api.SurveySummary.StatusEnum.SCHEDULED:
			return 'Scheduled'
	}
}

export function surveyStatusToClassName(status: Api.SurveySummary.StatusEnum): string {
	switch (status) {
		case Api.SurveySummary.StatusEnum.CLOSED:
			return '-closed'
		case Api.SurveySummary.StatusEnum.DRAFT:
			return '-draft'
		case Api.SurveySummary.StatusEnum.OPEN:
			return '-open'
		case Api.SurveySummary.StatusEnum.READY:
			return '-ready'
		case Api.SurveySummary.StatusEnum.SCHEDULED:
			return '-scheduled'
	}
}

export function participantsFilterToName(status: Api.FilterSurveyParticipants): string {
	switch (status) {
		case Api.FilterSurveyParticipants.All:
			return 'All'
		case Api.FilterSurveyParticipants.Completed:
			return 'Completed'
		case Api.FilterSurveyParticipants.Invited:
			return 'Invited'
		case Api.FilterSurveyParticipants.NotCompleted:
			return 'Not completed'
		case Api.FilterSurveyParticipants.NotInvited:
			return 'Not invited'
		case Api.FilterSurveyParticipants.HasEmailAddress:
			return 'Has email address'
	}
}

export function adminRoleToName(status: Api.AdminUserRole): string {
	switch (status) {
		case Api.AdminUserRole.Edit:
			return 'Edit'
		case Api.AdminUserRole.Manage:
			return 'Manage'
		case Api.AdminUserRole.View:
			return 'View'
		case Api.AdminUserRole.Report:
			return 'Report'
	}
}

export function questionTypeToName(question: Api.QuestionTypeEnum): string {
	switch (question) {
		case Api.QuestionTypeEnum.Scale:
			return 'Scale'
		case Api.QuestionTypeEnum.Multi:
			return 'Multichoice'
		case Api.QuestionTypeEnum.Matrix:
			return 'Matrix'
		case Api.QuestionTypeEnum.MatrixSub:
			return 'Matrix subquestion'
		case Api.QuestionTypeEnum.FreeText:
			return 'Free text'
		case Api.QuestionTypeEnum.SurveyQuestion:
			return 'Survey question'
	}
}

export function getCustomQuestionTypeOptions(question?: Api.ScaleQuestion | Api.MultichoiceQuestion | Api.MatrixSubQuestion | Api.MatrixQuestion | Api.FreeTextQuestion | undefined): { text: string; value: Api.MultichoiceQuestion.MultichoiceTypeEnum | Api.FreeTextQuestion.FreeTextTypeEnum | undefined}[] {
	if (question && question.type === Api.QuestionTypeEnum.Multi) {
		return [
			{ text: 'Choose an answer type…', value: undefined },
			{ text: 'Multichoice — Single answer (radio buttons)', value: Api.MultichoiceQuestion.MultichoiceTypeEnum.Single },
			{ text: 'Multichoice — Single answer (select box)', value: Api.MultichoiceQuestion.MultichoiceTypeEnum.LongSingle },
			{ text: 'Multichoice — Multiple answers (checkboxes)', value: Api.MultichoiceQuestion.MultichoiceTypeEnum.Multi },
		]
	} else if (question && question.type === Api.QuestionTypeEnum.FreeText) {
		return [
			{ text: 'Choose an answer type…', value: undefined },
			{ text: 'Free text — Short form', value: Api.FreeTextQuestion.FreeTextTypeEnum.Short },
			{ text: 'Free text — Long form', value: Api.FreeTextQuestion.FreeTextTypeEnum.Long },
		]
	} else {
		return [
			{ text: 'Choose an answer type…', value: undefined },
			{ text: 'Multichoice — Single answer (radio buttons)', value: Api.MultichoiceQuestion.MultichoiceTypeEnum.Single },
			{ text: 'Multichoice — Single answer (select box)', value: Api.MultichoiceQuestion.MultichoiceTypeEnum.LongSingle },
			{ text: 'Multichoice — Multiple answers (checkboxes)', value: Api.MultichoiceQuestion.MultichoiceTypeEnum.Multi },
			{ text: 'Free text — Short form', value: Api.FreeTextQuestion.FreeTextTypeEnum.Short },
			{ text: 'Free text — Long form', value: Api.FreeTextQuestion.FreeTextTypeEnum.Long },
		]
	}
}

export function customQuestionTypeToEnum(question: Api.ScaleQuestion | Api.MultichoiceQuestion | Api.MatrixSubQuestion | Api.MatrixQuestion | Api.FreeTextQuestion | undefined): Api.MultichoiceQuestion.MultichoiceTypeEnum.LongSingle | Api.MultichoiceQuestion.MultichoiceTypeEnum.Multi | Api.MultichoiceQuestion.MultichoiceTypeEnum.Single | Api.FreeTextQuestion.FreeTextTypeEnum.Long | Api.FreeTextQuestion.FreeTextTypeEnum.Short | undefined {
	if (!question) {
		return undefined
	}
	switch (question.type) {
		case Api.QuestionTypeEnum.Multi: {
			switch (question.multichoiceType) {
				case Api.MultichoiceQuestion.MultichoiceTypeEnum.Multi:
					return Api.MultichoiceQuestion.MultichoiceTypeEnum.Multi 
				case Api.MultichoiceQuestion.MultichoiceTypeEnum.LongSingle:
					return Api.MultichoiceQuestion.MultichoiceTypeEnum.LongSingle
				case Api.MultichoiceQuestion.MultichoiceTypeEnum.Single:
					return Api.MultichoiceQuestion.MultichoiceTypeEnum.Single
			}
			break
		}
		case Api.QuestionTypeEnum.FreeText: {
			switch (question.freeTextType) {
				case Api.FreeTextQuestion.FreeTextTypeEnum.Long:
					return Api.FreeTextQuestion.FreeTextTypeEnum.Long
				case Api.FreeTextQuestion.FreeTextTypeEnum.Short:
					return Api.FreeTextQuestion.FreeTextTypeEnum.Short
			}
		}
	}
	return undefined
}

export function sendSurveyReminderToToastMessage(response: Api.SendRemindersResponse): string {
	let message = `Reminder${response.sent === 1 ? '' : 's'} sent to ${response.sent} participant${response.sent === 1 ? '' : 's'}.\n`

	if (response.alreadyCompleted > 0) {
		message += `${response.alreadyCompleted} participant${response.alreadyCompleted === 1 ? ' has' : 's have'} already completed the survey`
		if (response.alreadyCompleted > 0 && response.remindedRecently > 0) {
			message += ' and '
		} else {
			message += '.'
		}
	}
	if (response.remindedRecently > 0) {
		message += `${response.remindedRecently} participant${response.remindedRecently === 1 ? ' has received a reminder' : 's have received reminders'} in the last 24 hours.`
	}

	return message
}
