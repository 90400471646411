import React from 'react'
import { Api } from 'typescript-fetch-api'
import * as Recharts from 'recharts'
import * as d3Scale from 'd3-scale'
import * as d3Interpolate from 'd3-interpolate'
import { jsonToCSV } from 'react-papaparse'
import download from 'downloadjs'

interface Props {
	area: Api.SurveyReportDataArea
	report: Api.SurveyReport
	printMode: boolean
}

const DataReportArea: React.FC<Props> = function(props) {
	const { area, report, printMode } = props
	const useLabel = area.series[0].data && area.series[0].data.length && area.series[0].data[0].label

	const colour = d3Interpolate.piecewise(d3Interpolate.interpolateHsl, ['#D73F38', '#F1DC00', '#50B555'])
	const scale = d3Scale.scaleSequential(colour)

	const data: Api.SurveyReportDataArea.Series.Datum[] | undefined = area.series[0].data && area.series[0].data.map(d => ({ ...d }))
	area.series.slice(1).forEach((series, seriesIndex) => series.data && series.data.forEach(datum => {
		const existing = data && data.find(existing => existing.x === datum.x)
		if (existing) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(existing as any)[`y${seriesIndex + 1}`] = datum.y
		}
	}))

	function onDownloadData(evt: React.MouseEvent) {
		evt.preventDefault()

		const csv = jsonToCSV(data, {
			columns: ['x', 'y', ...area.series.slice(1).map((item, index) => `y${index + 1}`), 'label'],
		})
		download(csv, `${area.name} for ${report.survey.name}.csv`, 'text/csv')
	}

	const ref = React.createRef<HTMLDivElement>()

	return (
		<div className="data" ref={ref}>
			<div className="barchart-single">
				<div className="header">
					<div className="chart-key">
						<ul className="values">
							{area.minLabel && <li className="value -poor"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>{area.minLabel}</li>}
							{area.midLabel && <li className="value -good"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>{area.midLabel}</li>}
							{area.maxLabel && <li className="value -great"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>{area.maxLabel}</li>}
						</ul>
					</div>
					<div className="options _no-print">
						<button className="icon-text -smallicon -download" onClick={onDownloadData}>Data</button>
					</div>
				</div>
				<div className="barchart">
					<div className="chart">
						<Recharts.ResponsiveContainer width="100%" height={400}>
							<Recharts.ComposedChart
								data={data}
								margin={{ top: 5, right: 20, left: 10, bottom: 15 }}
							>
								{useLabel
									? <Recharts.XAxis
										dataKey="label"
										interval="preserveStartEnd"
										type="category"
										label={{ value: area.xAxis.name, dy: 20, fontStyle: 'italic' }}
										dy={5}
									/>
									: <Recharts.XAxis 
										dataKey="x" 
										type="number" 
										domain={area.xAxis.domain ? [area.xAxis.domain[0], area.xAxis.domain[1]] : undefined} 
										label={{ value: area.xAxis.name, dy: 20, fontStyle: 'italic' }} 
										tickLine={{ stroke: '#E6E1EB', strokeWidth: 1 }} 
										tick={{ fill: '#9C919D' }}
										dy={5}
									/>
								}
								<Recharts.YAxis
									type="number" 
									domain={area.yAxis.domain ? [area.yAxis.domain[0], area.yAxis.domain[1]] : undefined} 
									axisLine={false} 
									tickLine={false} 
									tick={{ fill: '#9C919D' }}
									tickFormatter={num => num % 1 === 0 ? num : ''}
								>
									<Recharts.Label value={area.yAxis.name} angle={-90} dx={-10} style={{ textAnchor: 'middle' }} />
								</Recharts.YAxis>
								<Recharts.Tooltip formatter={(value: number) => <>{Math.round(value * 10) / 10}</>} />
								<Recharts.CartesianGrid stroke="#E6E1EB" vertical={false} strokeWidth="1" />
								{area.series[0].style === Api.SurveyReportDataArea.Series.StyleEnum.BARS &&
								<Recharts.Bar dataKey="y" className="series0" name={area.series[0].name} isAnimationActive={!printMode}>
									{
										area.series[0].data && area.series[0].data.map((datum, index) => (
											<Recharts.Cell key={`cell-${index}`} fill={datum.score !== undefined ? scale(datum.score) : undefined} />
										))
									}
								</Recharts.Bar>
								}
								{area.series[0].style === Api.SurveyReportDataArea.Series.StyleEnum.LINE &&
								<Recharts.Line dataKey="y" className="series0" name={area.series[0].name} isAnimationActive={!printMode} dot={false} />
								}
								{area.series.slice(1).map((series, seriesIndex) => {
									if (series.style === Api.SurveyReportDataArea.Series.StyleEnum.LINE) {
										return (
											<Recharts.Line
												key={seriesIndex}
												dataKey={`y${seriesIndex + 1}`} 
												className={`series${seriesIndex + 1}`}
												name={series.name}
												isAnimationActive={!printMode}
												stroke="#999"
												dot={false}
												type="monotone"
											/>
										)
									} else if (series.style === Api.SurveyReportDataArea.Series.StyleEnum.BARS) {
										return (
											<Recharts.Bar
												key={seriesIndex}
												dataKey={`y${seriesIndex + 1}`} 
												className={`series${seriesIndex + 1}`}
												name={series.name}
												isAnimationActive={!printMode}
											>
												{
													series.data && series.data.map((datum, index) => (
														<Recharts.Cell key={`cell-${index}`} fill="#999" />
													))
												}
											</Recharts.Bar>
										)
									} else {
										return null
									}
								})}
							</Recharts.ComposedChart>
						</Recharts.ResponsiveContainer>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DataReportArea