import React from 'react'
import Screen from 'modules/common/components/Screen'
import { useSelector, useDispatch } from 'react-redux'
import { selectParticipations } from '../selectors'
import moment from 'moment'
import { Api } from 'typescript-fetch-api'
import { actionSetParticipation } from '../actions'
import { useHistory, Redirect } from 'react-router'
import { selectPersonDetails } from 'modules/admin/selectors'

const ParticipantHome: React.FC = () => {
	const person = useSelector(selectPersonDetails)
	const participations = useSelector(selectParticipations)
	const dispatch = useDispatch()
	const history = useHistory()
	
	if (!participations) {
		return <Redirect to="/" />
	}

	function goParticipation(participation: Api.ParticipationInfo) {
		dispatch(actionSetParticipation(participation))
		history.push('/p/survey')
	}

	return (
		<Screen title="Welcome" includePageHeader={false} class="-survey">
			<div className="content-header">
				<h1 className="headline-text -centered">Welcome {person && person.givenName ? person.givenName : ''}</h1>
				{participations.length === 0 &&
				<div className="body-text -large -centered">
					<p>You have not yet participated in any surveys.</p>
				</div>
				}
				{participations.length > 0 &&
				<>
					<div className="body-text -large -centered">
						<p>You have participated in the following surveys:</p>
					</div>
					<div className="participated-surveys">
						{participations.map((participation, index) => (
							<div className="survey" key={index}>
								<div className="details">
									<h2 className="subheading-text">{participation.name}</h2>
									<p className="body-text">
										{participation.account.name}
										{participation.whenCompleted ? (
											<>, {moment(participation.whenCompleted).format('d MMMM yyyy')}</>
										) : participation.whenSurveyOpened ? (
											<>, {moment(participation.whenSurveyOpened).format('d MMMM yyyy')}</>
										) : null}
									</p>
								</div>
								{participation.whenCompleted ? (
									<button className="button-link -secondary" onClick={() => goParticipation(participation)}>View Report</button>
								) : participation.whenStarted ? (
									<button className="button-link -action" onClick={() => goParticipation(participation)}>Continue</button>
								) : (
									<button className="button-link -action" onClick={() => goParticipation(participation)}>Start</button>
								)}
							</div>
						))}
					</div>
				</>
				}
			</div>
		</Screen>
	)
}

export default ParticipantHome
