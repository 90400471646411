import React, { useState } from 'react'
import QuestionMatrixSubquestion, { incomplete } from './QuestionMatrixSubquestion'
import { QuestionProps } from './types'
import QuestionScreen from './QuestionScreen'
import QuestionHeader from './QuestionHeader'
import { MatrixQuestion } from 'typescript-fetch-api'
import { useKeyboardNavigation, useDisableKeyboardNavigationForRadios } from '../hooks'
import { uniqueToastError } from 'modules/common/functions'

export default function QuestionMatrix(props: QuestionProps<MatrixQuestion>) {
	const { section, question, questionIndex } = props
	const [currentQuestionId, setCurrentQuestionId] = useState(question.id)
	const [highlightMissing, setHighlightMissing] = React.useState(false)

	useKeyboardNavigation(onPrev, onNext)
	useDisableKeyboardNavigationForRadios()

	function updateCurrentQuestion(questionId: string) {
		if (question.subquestions) {
			const currentQuestionIndex = question.subquestions.findIndex(i => i.id === currentQuestionId)
			const newQuestionIndex = question.subquestions.findIndex(i => i.id === questionId)

			if (newQuestionIndex > currentQuestionIndex) {
				setCurrentQuestionId(questionId)
			}
		} 
	}

	function onNext(evt?: React.FormEvent) {
		evt && evt.preventDefault()

		for (let i = 0; i < question.subquestions.length; i++) {
			if (!question.subquestions[i].optional && question.subquestions[i].requiresOtherTitle && !question.subquestions[i].otherTitle && !question.subquestions[i].answerNotApplicable) {
				uniqueToastError('Please specify the ‘other’ option.')
				setHighlightMissing(true)
				return false
			} else if (!question.subquestions[i].optional && question.subquestions[i].answerIndex === undefined && !question.subquestions[i].answerNotApplicable) {
				uniqueToastError('Please answer all questions before continuing')
				setHighlightMissing(true)
				return false
			}
		}

		props.onNext()
	}

	function canProgress() {
		for (let i = 0; i < question.subquestions.length; i++) {
			if (incomplete(question.subquestions[i], question, i, true)) {
				return false
			}
		}
		return true
	}

	function onPrev(evt?: React.MouseEvent) {
		evt && evt.preventDefault()
		props.onPrev()
	}

	function onExit(evt: React.MouseEvent) {
		evt.preventDefault()
		props.onExit()
	}


	return (
		<QuestionScreen section={section} question={question} currentMatrixQuestion={currentQuestionId}>
			<div className="survey-question">
				<QuestionHeader question={question} questionIndex={questionIndex} />
				<div className="form-row">
					{
						props.onAnswerQuestion && question.subquestions && question.subquestions.map((subquestion, subquestionIndex) => (
							<QuestionMatrixSubquestion key={subquestionIndex} questionNumber={subquestionIndex} question={subquestion} onAnswerQuestion={props.onAnswerQuestion} matrix={question} updateCurrentQuestion={updateCurrentQuestion} highlightMissing={highlightMissing}/>
						))
					}
				</div>
					
				<div className="footer-actions">
					<div className="button-group">
						<a href="/" className="button-link -secondary -back" onClick={onPrev}>Back</a>
						<a href="/" className="button-link -text" onClick={onExit}>Exit survey</a>
					</div>
					<div className="button-group -right">
						<input type="submit" className={'button-link -action -next ' + (canProgress() ? '' : '-disabled')} value="Next" onClick={onNext} />
					</div>
				</div>

				<div className="keyboard-nav">
					<p className="option">You can also use your <span className="key">&larr;</span> <span className="key">&rarr;</span> arrow keys to navigate forward &amp; back</p>
				</div>
			</div>			
		</QuestionScreen>
	)
}