import adminSaga from 'modules/admin/sagas'
import authSaga from 'modules/auth/sagas'
import knowledgeLibrarySaga from 'modules/knowledgelibrary/sagas'
import capacitorSaga from 'modules/capacitor/sagas'
import routingSaga from 'modules/routing/sagas'
import { all } from 'redux-saga/effects'
import { Store } from 'redux'
import { RootStoreState } from '.'
import { Capacitor } from '@capacitor/core'

export default function* rootSaga(store: Store<RootStoreState>) {
	const sagas = [
		authSaga(),
		adminSaga(store),
		knowledgeLibrarySaga(store),
		routingSaga(),
	]
	if (Capacitor.isNativePlatform()) {
		sagas.push(capacitorSaga(store))
	}
	yield all(sagas)
}
