import React, { useState } from 'react'
import Screen from 'modules/common/components/Screen'
import { Api, SortParticipants, FilterSurveyParticipants } from 'typescript-fetch-api'
import { Link, useHistory } from 'react-router-dom'
import { useCursoredDataEffect, useApiEffect, useCallApi } from 'modules/common/hooks'
import { Formalities, useController } from 'formalities'
import ErrorInline from 'modules/common/components/ErrorInline'
import { HeaderItem } from 'modules/common/components/Table'
import { selectCurrentMembership } from 'modules/admin/selectors'
import { useSelector } from 'react-redux'
import { pathToSurvey } from '../navigation'
import ParticipantsTable, { TableFilter } from '../../participants/components/ParticipantsTable'
import AddParticipants from '../../participants/components/AddParticipants'
import { apiErrorToMessage } from 'modules/api/functions'
import LoadingInline from 'modules/common/components/LoadingInline'
import ExportParticipants from 'modules/admin/participants/components/ExportParticipants'
import { useCanEdit, useCurrentPerson } from 'modules/admin/hooks'
import { TableAction } from 'modules/admin/types'
import moment from 'moment'
import { uniqueToastError, uniqueToastSuccess, uniqueToastWarn } from 'modules/common/functions'
import { sendSurveyReminderToToastMessage } from 'modules/admin/functions'

interface Props {
	survey: Api.SurveyDetails
	onChangeParticipants: () => void
}

interface Form {
	group?: Api.ParticipantGroup
}

interface MyTableFilter extends TableFilter {
	filter?: Api.FilterSurveyParticipants
	group?: Api.ParticipantGroup
}

interface MyTableHeader extends HeaderItem {
	sortBy: Api.SortParticipants
}

const SurveyParticipants: React.FC<Props> = function(props) {
	const { survey } = props
	const history = useHistory()
	const person = useCurrentPerson()
	const currentAccount = useSelector(selectCurrentMembership)
	if (!currentAccount) {
		throw new Error('No account')
	}
	const [searchTerm, setSearchTerm] = useState<string | undefined>()
	const [sort, setSort] = useState<SortParticipants | undefined>()
	const getAccountGroups = useApiEffect(api => api.accountApi.getGroups(currentAccount.account.id), [currentAccount])
	const getSurveyGroups = useApiEffect(api => api.surveyApi.getGroups(survey.id), [survey.id])
	const controller = useController<Form>({})
	const [filters, setFilters] = useState<Api.FilterSurveyParticipants[] | undefined>()
	const [groups, setGroups] = useState<string[] | undefined>()
	const callApi = useCallApi()
	const { response, loadMore, refresh } = useCursoredDataEffect((cursor) => {
		if (searchTerm) {
			return callApi(api => api.surveyApi.searchParticipations(survey.id, searchTerm, cursor, sort, filters, groups))
		} else {
			return callApi(api => api.surveyApi.getParticipations(survey.id, cursor, sort, filters, groups))
		}
	},
	[sort, survey.id, filters, groups, callApi, searchTerm])
	const canEdit = useCanEdit()

	async function onAddParticipants(request: Api.AddParticipantsRequest): Promise<boolean> {
		try {
			const result = await callApi(api => api.surveyApi.addParticipants(survey.id, request))
			refresh()
			props.onChangeParticipants()
			
			if (result.numberAdded === 1) {
				uniqueToastSuccess('Added participant.')
				return true
			} else if (result.numberAdded > 1) {
				uniqueToastSuccess(`Added ${result.numberAdded} participants${result.numberExisting > 0 ? ` (${result.numberExisting} were already participants)` : ''}`)
				return true
			} else if (result.numberExisting === 1) {
				uniqueToastWarn('Participant was already a member of this survey.')
				return true
			} else {
				uniqueToastWarn('All participants were already members of this survey.')
				return true
			}
		} catch (error) {
			uniqueToastError(`Failed to add participants.\n${apiErrorToMessage(error)}`)
			return false
		}
	}

	async function onAddGroup(evt: React.FormEvent) {
		evt.preventDefault()
		const group = controller.snapshot().value.group

		if (group) {
			try {
				await callApi(api => api.surveyApi.addGroups(survey.id, {
					groups: [group.id],
				}))
				getSurveyGroups.refresh()
				props.onChangeParticipants()
				uniqueToastSuccess(`Participants from ${group.name} have been added.`)
			} catch (error) {
				uniqueToastError(`Failed to add participants from ${group.name}.\n${apiErrorToMessage(error)}`)
			}
		}
	}

	async function onRemoveGroup(evt: React.MouseEvent, groupId: string) {
		evt.preventDefault()
		const group = getSurveyGroups.result?.groups ? getSurveyGroups.result?.groups.find((group) => group.id === groupId) : undefined
		try {
			await callApi(api => api.surveyApi.removeGroups(survey.id, { groups: [groupId] }))
			getSurveyGroups.refresh()
			props.onChangeParticipants()
			uniqueToastSuccess(`Successfully removed ${group ? group.name : 'group'}`)
		} catch (error) {
			uniqueToastError('Failed to remove group')
		}
	}

	async function onSendSurveyReminder(participantIds: string[]) {
		try {
			const response = await callApi(api => api.surveyApi.postReminders(survey.id, { participations: participantIds }))
			const responseToToastMessage = sendSurveyReminderToToastMessage(response)
			uniqueToastSuccess(`${responseToToastMessage}`)
		} catch (error) {
			uniqueToastError(`Failed to send reminder.\n${apiErrorToMessage(error)}`)
		}
	}

	async function onSendSurveyThanks(participantIds: string[]) {
		try {
			const response = await callApi(api => api.surveyApi.resendThanks(survey.id, { participations: participantIds }))

			let message = `Thanks email${response.sent === 1 ? '' : 's'} sent to ${response.sent} participant${response.sent === 1 ? '' : 's'}.\n`
			if (response.notCompleted > 0) {
				message += `${response.notCompleted} participant${response.notCompleted === 1 ? ' has' : 's have'} not yet completed the survey.\n`
			}
			if (response.anonymous > 0) {
				message += `${response.anonymous} participant${response.anonymous === 1 ? ' is' : 's are'} anonymous.`
			}

			uniqueToastSuccess(`${message}`)
		} catch (error) {
			uniqueToastError(`Failed to send thanks emails.\n${apiErrorToMessage(error)}`)
		}
	}

	async function onRemoveParticipants(participantIds: string[]) {
		try {
			await callApi(api => api.surveyApi.removeParticipations(survey.id, { participations: participantIds }))
			refresh()
			props.onChangeParticipants()
			uniqueToastSuccess(`Successfully removed participant${participantIds.length > 1 ? 's' : ''}`)
		} catch (error) {
			uniqueToastError(`Failed to remove participant.\n${apiErrorToMessage(error)}`)
		}
	}

	async function onSearchParticipants(searchInput?: string, sortBy?: HeaderItem, filters?: MyTableFilter[]) {
		setSearchTerm(searchInput)
		setSort(sortBy ? (sortBy as MyTableHeader).sortBy : undefined)
		setFilters(filters ? filters.filter(f => !!f.filter).map(f => f.filter!) : undefined)
		setGroups(filters ? filters.filter(f => !!f.group).map(f => f.group!.id) : undefined)
	}

	const tableFilters: MyTableFilter[] = []
	
	if (person.superAdmin) {
		tableFilters.push({
			name: 'Completed',
			filter: FilterSurveyParticipants.Completed,
		},
		{
			name: 'Not Completed',
			filter: FilterSurveyParticipants.NotCompleted,
		},
		{
			name: 'Has Email Address',
			filter: FilterSurveyParticipants.HasEmailAddress,
		},
		)
	}

	if (getSurveyGroups.result) {
		for (const group of getSurveyGroups.result.groups) {
			tableFilters.push({
				name: group.name,
				group: group,
			})
		}
	}

	function actions(participantId?: string): TableAction[] | undefined {
		const participant = response.result?.participations.find(p => p.id === participantId)
		
		if (!canEdit) {
			return
		}

		const result: TableAction[] = []

		if (survey.status === Api.SurveySummary.StatusEnum.OPEN && (!participant || participant.status !== Api.Participation.StatusEnum.COMPLETED)) {
			result.push({
				name: 'Send survey reminder',
				onAction: onSendSurveyReminder,
			})
		}

		if (!participant || participant.status === Api.Participation.StatusEnum.COMPLETED) {
			result.push({
				name: 'Resend thanks message',
				onAction: onSendSurveyThanks,
			})
		}

		if ((participant && participant.status === Api.Participation.StatusEnum.COMPLETED && survey.capabilities.removeCompletedParticipations)
			|| (person.superAdmin && survey.status === Api.SurveySummary.StatusEnum.CLOSED)
			|| (survey.status !== Api.SurveySummary.StatusEnum.CLOSED && survey.status !== Api.SurveySummary.StatusEnum.OPEN)
			|| (survey.status === Api.SurveySummary.StatusEnum.OPEN && (!participant || participant.status !== Api.Participation.StatusEnum.COMPLETED))) {
			result.push({
				name: 'Remove',
				onAction: onRemoveParticipants,
			})
		}

		if (result.length) {
			return result
		} else {
			return undefined
		}
	}

	function onViewGroup(evt: React.MouseEvent, groupId: string) {
		evt.preventDefault()
		history.push(`/admin/participants/${groupId}`)
	}
	
	return (
		<Screen title="Participants" includePageHeader={false} breadcrumbText="survey" breadcrumbLink={`/admin/survey/${survey.id}`}>
			<div className="content-header">
				<h1 className="headline-text">Survey participants <span className="body-text -hero">for <Link to={pathToSurvey(survey)} replace={true} className="title">{survey.name}</Link></span></h1>
			</div>
			<div className="content-cols -widesidebar">
				<div className="body">
					{response.result && (response.result.participations.length > 0 || searchTerm || (filters && filters.length > 0) || (groups && groups.length > 0)) &&
						<ParticipantsTable
							loadMore={loadMore}
							actions={actions}
							filters={tableFilters}
							onSearch={onSearchParticipants}
							headerItems={[
								{ text: 'Given name', sortBy: SortParticipants.GivenName }, 
								{ text: 'Family name', sortBy: SortParticipants.FamilyName }, 
								{ text: 'Email', sortBy: SortParticipants.Email }, 
								{ text: 'Invited', sortBy: SortParticipants.Invited }, 
								{ text: person.superAdmin ? 'Done' : '', centerAlign: true },
							]}
							rows={response.result.participations.map((participation) => { 
								return ({
									key: participation.id,
									cols: [
										{ text: participation.participant ? participation.participant.givenName : '' },
										{ text: participation.participant ? participation.participant.familyName : '' },
										{ text: participation.participant ? participation.participant.emailAddress : <em>Anonymous</em> },
										{ text: participation && participation.whenLastInvited ? moment(participation.whenLastInvited).format('DD/MM/YY') : <span className="icon-unit -empty"></span> },
										{ 
											text: person.superAdmin ? 
												(participation.status === Api.Participation.StatusEnum.COMPLETED ? (<span className="icon-unit -tick"></span>) : (<span className="icon-unit -empty"></span>))
												: '', 
											centerAlign: true,
										},
									],
								})
							})}
						/>
					}
					{response.result && response.result.participations.length === 0 && !searchTerm &&
						<div className="no-participants">
							<h2 className="subheading-text">No participants</h2>
							<div className="body-text">
								<p>Start adding some either individually, or based on existing participant lists.</p>
							</div>
						</div>
					}
					{response.loading && <LoadingInline centred={true} />}
					{response.error && <ErrorInline error={response.error} />}
				</div>
			
				{canEdit &&
				<aside className="aside">
					<div className="aside-text">
						{getAccountGroups.result && getAccountGroups.result.length > 0 ?
							<div className="narrow-form">
								{survey.locked && (
									<>
										<h4 className="ui-heading">Locked</h4>
										<p>Unable to add new participants to a closed survey.</p>
									</>
								)}
								{!survey.locked && (
									<>
										<h4 className="subheading-text -small">Add from a group</h4>
										<div className="buttoned-field">
											<div className="form-input -select">
												<Formalities.Select controller={controller} prop="group" className="select"
													options={[undefined, ...getAccountGroups.result]}
													display={t => t ? t.name : 'Add from a group…'}
												/> 
											</div>
											<button className="button-link -secondary" onClick={onAddGroup}>Add</button>
										</div>
									</>
								)}
								{
									getSurveyGroups.result && getSurveyGroups.result.groups.length > 0 &&
									<div className="groups-added body-text">
										<h4 className="ui-heading">Groups added:</h4>
										<ul className="groups">
											{
												getSurveyGroups.result.groups.map((group, index) => 
													<li key={index}>
														<p className="group"><Link to="/" onClick={(e) => onViewGroup(e, group.id)}>{group.name}</Link></p>
														{!survey.locked &&
														<a href="/" className="remove" onClick={(e) => onRemoveGroup(e, group.id)}>Remove</a>
														}
													</li>,
												)
											}
										</ul>
									</div>
								}
							</div>
							: getSurveyGroups.error && <ErrorInline error={getSurveyGroups.error} />
						}
						{!survey.locked &&
						<AddParticipants onAddParticipants={onAddParticipants} />
						}
						<ExportParticipants
							loadParticipants={async(cursor) => {
								const result = await callApi(api => api.surveyApi.getParticipations(survey.id, cursor, sort, filters, groups))
								return {
									nextCursor: result.nextCursor,
									participants: result.participations.filter(p => !!p.participant).map(p => p.participant!),
								}
							}}
							filenameSuffix={`in ${survey.name}`}
						/>
					</div>
				</aside>
				}
			</div>
		</Screen>
	)
}

export default SurveyParticipants
