import React from 'react'
import './App.css'
import { Router, Switch, Route } from 'react-router-dom'
import { ToastContainer, Slide } from 'react-toastify'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Store } from 'redux'
import { Provider } from 'react-redux'
import HomeRedirect from 'modules/routing/components/HomeRedirect'
import LoginRouter from 'modules/auth/components/LoginRouter'
import ParticipantRouter from 'modules/participant/components/ParticipantRouter'
import ReadyGate from 'modules/offline/components/ReadyGate'
import LoadingScreen from 'modules/common/components/LoadingScreen'
import KnowledgeLibrary from 'modules/knowledgelibrary/components/KnowledgeLibrary'
import { history } from 'modules/routing'
import ParticipantSurveyTokenRouter from 'modules/participant/components/ParticipantSurveyTokenRouter'
import AccountRouter from 'modules/account/components/AccountRouter'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from 'modules/common/components/ErrorFallback'
import AdminRouter from 'modules/admin/components/AdminRouter'
import { Capacitor } from '@capacitor/core'
import AppUrlHandler from 'modules/capacitor/components/AppUrlHandler'
import ParticipantClaimRouter from 'modules/participant/components/ParticipantClaimRouter'

/* Main styles */
import 'modules/frontend/css/main.less'

interface Props {
	store: Store
}

const helmetContext = {}

const Core: React.FC = function() {
	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<Switch>
				<Route path="/auth" component={LoginRouter} />
				<Route path="/account" component={AccountRouter} />
				<Route path="/admin" component={AdminRouter} />
				<Route path="/knowledge-library" component={KnowledgeLibrary} />
				<Route path="/p" component={ParticipantRouter} />
				<Route path="/pc" component={ParticipantClaimRouter} />
				<Route path="/s" component={ParticipantSurveyTokenRouter} />
				<Route path="/" component={HomeRedirect} />
			</Switch>
		</ErrorBoundary>
	)
}

const App: React.FC<Props> = (props) => {
	return (
		<Provider store={props.store}>
			<Router history={history}>
				<HelmetProvider context={helmetContext}>
					<ReadyGate waitComponent={LoadingScreen}>
						<ToastContainer
							position="top-center"
							hideProgressBar={true}
							autoClose={5000}
							draggable={false}
							pauseOnHover={true}
							className="toast-container"
							toastClassName="toast-content"
							transition={Slide}
							newestOnTop={true}
						/>
						<Helmet>
							<title>Umbrella</title>
						</Helmet>
						<Core />
						{Capacitor.isNativePlatform() && (
							<AppUrlHandler />
						)}
					</ReadyGate>
				</HelmetProvider>
			</Router>
		</Provider>
	)
}

export default App
