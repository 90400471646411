import * as React from 'react'

interface OwnProps {
	// exampleProperty: string
}

const footerHtml: string | undefined = undefined

export default class Footer extends React.Component<OwnProps> {

	private containerRef = React.createRef<HTMLDivElement>()

	public componentDidMount() {
		if (footerHtml) {
			this.applyFooterHtml()
		} else {
			// this.loadFooterHtml()
		}
	}

	public shouldComponentUpdate() {
		return false
	}

	public render() {
		return (
			<footer className="site-footer" ref={this.containerRef} />
		)
	}

	// private loadFooterHtml = () => {
	// 	getApi().cmsApi.getCMSContentRequest('/cms/footer').then(response => {
	// 		response.text().then(text => {
	// 			footerHtml = text
	// 			this.applyFooterHtml()
	// 		})
	// 	}).catch(reason => {
	// 		console.error(`Failed to load footer: ${reason}`)
	// 	})
	// }

	private applyFooterHtml = () => {
		if (this.containerRef.current && footerHtml) {
			this.containerRef.current.innerHTML = footerHtml

			/* Intercom link */
			const messageLink = document.getElementById('message-link')
			if (messageLink) {
				messageLink.addEventListener('click', (evt: MouseEvent) => {
					evt.preventDefault()
				})
			}
		}
	}
}
