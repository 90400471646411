import React, { useState } from 'react'
import { Api } from 'typescript-fetch-api'
import { useController, Formalities } from 'formalities'
import { produce } from 'immer'

interface Props {
	filters: Api.GetFilters200Response
	request?: Api.ReportOptions
	setRequest: (newRequest: Api.ReportOptions | undefined) => void
}

interface SelectedQuestionFilters {
	[questionId: string]: number[]
}

interface OpenSections {
	[sectionId: string]: boolean
}

const ReportFilters: React.FC<Props> = (props) => {
	const { filters } = props
	const selectedQuestionsController = useController<SelectedQuestionFilters>(!props.request || !props.request.filter || !props.request.filter.questions ? {} : props.request.filter.questions.reduce((result, q) => ({ ...result, [q.question]: q.answers }), {}))
	const selectedParticipantGroupsController = useController<string[]>(!props.request || !props.request.filter || !props.request.filter.participantGroups ? [] : props.request.filter.participantGroups)
	const [openSections, setOpenSections] = useState<OpenSections>(!props.request || !props.request.filter ? {} : {
		...(props.request.filter.questions ? props.request.filter.questions.reduce((result, q) => ({ ...result, [q.question]: true }), {}) : {}),
		'participantGroups': (props.request.filter.participantGroups && props.request.filter.participantGroups.length ? true : false),
	})

	function toggleSection(sectionId: string) {
		setOpenSections(produce(draft => {
			draft[sectionId] = !draft[sectionId]
		}))
	}

	function onClearAll(evt: React.MouseEvent) {
		evt.preventDefault()
		selectedQuestionsController.snapshot().setValue({})
		selectedParticipantGroupsController.snapshot().setValue([])
	}

	function onApply(evt: React.MouseEvent) {
		evt.preventDefault()
		const selectedQuestions = selectedQuestionsController.snapshot().value
		const selectedParticipantGroups = selectedParticipantGroupsController.snapshot().value

		const questionFilters: Api.ReportFilter.Question[] = []
		for (const q of filters.questions) {
			if (selectedQuestions[q.id]) {
				questionFilters.push({
					question: q.id,
					answers: selectedQuestions[q.id],
				})
			}
		}

		const newRequest = produce(props.request, draft => {
			if (!draft) {
				draft = {}
			}
			if (questionFilters.length) {
				if (!draft.filter) {
					draft.filter = {}
				}
				draft.filter.questions = questionFilters
			} else if (draft.filter) {
				draft.filter.questions = undefined
			}
			if (selectedParticipantGroups.length) {
				if (!draft.filter) {
					draft.filter = {}
				}
				draft.filter.participantGroups = selectedParticipantGroups
			} else if (draft.filter) {
				draft.filter.participantGroups = undefined
			}

			return draft
		})
		props.setRequest(newRequest)
	}

	return (
		<div className="popup-menu -filters">
			<h2 className="ui-heading -dark">Filter by...</h2>
			<ul className="filter-options">
				{filters.questions.map(q => (
					<li className={`option ${openSections[q.id] ? '-active' : ''}`} key={q.id} onClick={() => toggleSection(q.id)}>
						<label className="label">{q.title}</label>
						<ul className="option-inputs -small">
							{q.type === Api.QuestionTypeEnum.Multi && q.options.map((o, optionIndex) => (
								<li className="option -checkbox" key={optionIndex}>
									<label className="label">
										<Formalities.MultiCheckable controller={selectedQuestionsController} prop={q.id} checkedValue={optionIndex} className="checkbox" type="checkbox" />
										<span className="substitute"></span>
										{o}
									</label>
								</li>
							))}
						</ul>
					</li>
				))}
				{filters.participantGroups.length > 0 && (
					<li className={`option ${openSections['participantGroups'] ? '-active' : ''}`} onClick={() => toggleSection('participantGroups')}>
						<label className="label">Participant groups</label>
						<ul className="option-inputs -small">
							{filters.participantGroups.map(group => (
								<li className="option -checkbox" key={group.id}>
									<label className="label">
										<Formalities.MultiCheckable controller={selectedParticipantGroupsController} prop="this" checkedValue={group.id} className="checkbox" type="checkbox" />
										<span className="substitute"></span>
										{group.name}
									</label>
								</li>
							))}
						</ul>
					</li>
				)}
			</ul>
			<div className="footer-actions -borderless">
				<div className="button-group -split">
					<button type="button" className="button-link -small -secondary" onClick={onClearAll}>Clear all</button>
					<input type="submit" className="button-link -small -action" value="Apply filters" onClick={onApply} />
				</div>
			</div>
		</div>
	)
}

export default ReportFilters
