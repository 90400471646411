import React from 'react'
import Screen from 'modules/common/components/Screen'
import { useSelector } from 'react-redux'
import { selectSurvey } from 'modules/participant/selectors'
import { Api } from 'typescript-fetch-api'
import { Link } from 'react-router-dom'
import ArcChart from 'modules/common/components/ArcChart'
import { useHistory, Route, RouteComponentProps, useRouteMatch, useParams } from 'react-router'
import ReportArticle from 'modules/participant/report/components/ReportArticle'
import { dontScrollToTopLocation } from 'modules/routing'

interface Props {
	report: Api.IndividualReport
	section: Api.IndividualReport.Section
}

interface ReportSectionParams {
	index: string
}

type MyComponentProps = RouteComponentProps & Props

const ReportSectionDetail: React.FC<MyComponentProps> = function(props) {
	const { report, section } = props
	const { path } = useRouteMatch()
	const params = useParams<ReportSectionParams>()

	React.useEffect(function() {
		document.body.classList.add('screen-personalreport-detail')

		return () => {
			document.body.classList.remove('screen-personalreport-detail')
		}
	})

	const survey = useSelector(selectSurvey)
	if (!survey) {
		throw new Error('No survey')
	}
	if (!report) {
		throw new Error('No report')
	}

	const history = useHistory()
	const sectionIndex = parseInt(params.index, 10)

	function onViewArticle(evt: React.MouseEvent, article: Api.ArticleSummary) {
		evt.preventDefault()
		history.push(dontScrollToTopLocation(`../section/${sectionIndex}/article/${article.id}`))
	}

	function doLogout(evt: React.MouseEvent) {
		evt.preventDefault()
		history.replace('/auth/sign-out')
	}

	return (
		<Screen title={survey.name} includePageHeader={false} class="-content">
			<div className="report-header">
				<div className="header">
					<p className="subheading-text -back"><Link to="/p/report/">Survey results</Link></p>
					<h1 className="headline-text -hero">{section.name}</h1>
					<p className="body-text name"><span className="icon-text -userhead">{report.person?.name}</span> &nbsp;•&nbsp; <a href="/" onClick={doLogout} className="-link">Log out</a></p>
				</div>

				<div className="results-block -intro">
					<div className="content">
						<div className="summary body-text -large">
							<p className="body-text -large">{section.summary || section.intro}</p>
						</div>
						<ArcChart score={section.score} variant="narrow" label1={section.minLabel} label2={section.midLabel} label3={section.maxLabel} />
					</div>
				</div>

				<div className={'resources-page' + (section.layout === Api.IndividualReport.Section.LayoutEnum.Summary ? ' -evencols' : '')}>
					{section.layout === Api.IndividualReport.Section.LayoutEnum.Ranges && (
						<>
							<div className="content">
								{
									section.groupings && section.groupings.map((grouping, index) => 
										<div key={index} className="summary body-text">
											<h2 className="subheading-text">{grouping.title}</h2>
											<p>{grouping.intro}</p>
										</div>,
									)
								}
								<div className="charts">
									{ section.aspects.map((aspect, index) => renderChart(aspect, index)) }
								</div>
							</div>
							{/* <div className="content">
								<div className="summary body-text">
									<h2 className="subheading-text">{}Your result breakdown</h2>
									<p></p>
								</div>
								<div className="charts">
									{ section.aspects.map((aspect, index) => renderChart(aspect, index)) }
									<div className="chart-key">
										<ul className="values">
											<li className="value -poor"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>An issue</li>
											<li className="value -great"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>Not an issue</li>
										</ul>
									</div>
								</div>
							</div> */}


							<div className="resource-summaries">
								{ section.aspects.map((aspect, index) => renderAspectArticles(aspect, index, onViewArticle))}
								{renderSectionArticles(section, onViewArticle)}
							</div>
						</>
					)}
					{section.layout === Api.IndividualReport.Section.LayoutEnum.Icons && (
						<>
							{
								section.groupings && section.groupings.map((grouping, index) => 
									<div key={index} className="content">
										<div className="summary body-text">
											<h2 className="subheading-text">{grouping.title}</h2>
											<p>{grouping.intro}</p>
										</div>
										<div className="charts">
											{ grouping.groups.map((group, index) => renderIconGroup(group, section, index))}
										</div>
									</div>,
								)
							}
							<div className="resource-summaries">
								{ section.aspects.map((aspect, index) => renderAspectArticles(aspect, index, onViewArticle))}
								{renderSectionArticles(section, onViewArticle)}
							</div>
						</>
					)}

					{section.layout === Api.IndividualReport.Section.LayoutEnum.Summary && renderSummaryLayout(section, onViewArticle)}
				</div>
			</div>
			<Route path={`${path}/article/:articleId`} render={(route) => <ReportArticle {...route} />} />
		</Screen>
	)
}

function renderIconGroup(group: Api.IndividualReport.Section.Grouping.Group, section: Api.IndividualReport.Section, index: number) {
	const aspects = section.aspects.filter(aspect => aspect.group === group.code)
	if (aspects.length === 0) {
		return null
	}

	return (
		<div key={index} className="icons">
			<h3 className="ui-heading -dark -large">{group.title}</h3>
			<ul className="icon-grid">
				{
					aspects.map((aspect, index) => (
						<li key={index} className="item"><div className="icon"><span className={'icon-unit ' + (aspect.icon ? (' -' + aspect.icon) : '')}></span><span className="label">{aspect.name}</span></div></li>
					))
				}
			</ul>
		</div>
	)
}

function renderChart(aspect: Api.IndividualReport.Section.Aspect, index: number) {
	return (
		<div key={index} className="chart-stack">
			<h3 className="body-text">{aspect.name}</h3>
			<div className="chart">
				<div className="scale-chart">
					<div className="bar">
						<div className="indicator" style={{ left: aspect.score * 100 + '%' }}></div>
					</div>
				</div>
			</div>
		</div>
	)
}

function willRenderAspectArticles(aspect: Api.IndividualReport.Section.Aspect) {
	return aspect.descriptionArticle || aspect.articles
}

function renderAspectArticles(aspect: Api.IndividualReport.Section.Aspect, index: number, onViewArticle: (evt: React.MouseEvent, article: Api.ArticleSummary) => void) {
	if (!willRenderAspectArticles(aspect)) {
		return null
	}

	return (
		<div key={index} className="summary">
			<h3 className="subheading-text">{aspect.name}</h3>
			{aspect.descriptionArticle &&
			<div className="body-text">
				<p>{aspect.descriptionArticle.teaser} <a href="/" className="more" onClick={(e) => onViewArticle(e, aspect.descriptionArticle!)}>Read more</a></p>
			</div>
			}
			{
				aspect.articles && 
				<div className="articles">
					{
						aspect.articles.map((article, index) => 
							<div key={index} className="content-teaser -small">
								<div className="media -image">
									{
										article.thumbnail ? (
											<div className="aspect">
												<img src={article.thumbnail.url} className="image" alt={article.thumbnail.title || ''} />
											</div>
										) : (
											<div className="aspect -empty">
												<span className="icon-unit -article"></span>
											</div>
										)
									}
								</div>
								<div className="content body-text">
									<h4 className="title"><a href="/" onClick={(e) => onViewArticle(e, article)}>{article.name}</a></h4>
									<p>{article.teaser}</p>
								</div>
							</div>,
						)
					}
				</div>
			}
		</div>
	)
}

function renderSectionArticles(section: Api.IndividualReport.Section, onViewArticle: (evt: React.MouseEvent, article: Api.ArticleSummary) => void) {
	if (!section.articles) {
		return null
	}

	return (
		<div className="summary">
			<h3 className="subheading-text">General Articles</h3>
			{
				<div className="articles">
					{
						section.articles.map((article, index) => 
							<div key={index} className="content-teaser -small">
								<div className="media -image">
									<div className="aspect">
										{
											article.thumbnail ? (
												<img src={article.thumbnail.url} className="image" alt={article.thumbnail.title || ''} />
											) : (
												<span className="icon-unit -article"></span>
											)
										}
									</div>
								</div>
								<div className="content body-text">
									<h4 className="title"><a href="/" onClick={(e) => onViewArticle(e, article)}>{article.name}</a></h4>
									<p>{article.teaser}</p>
								</div>
							</div>,
						)
					}
				</div>
			}
		</div>
	)
}

function renderSummaryLayout(section: Api.IndividualReport.Section, onViewArticle: (evt: React.MouseEvent, article: Api.ArticleSummary) => void) {
	const aspectsToRender = section.aspects.filter(willRenderAspectArticles)
	if (aspectsToRender.length) {
		return (
			<>
				<div className="content">
					{
						aspectsToRender.map((aspect, index) => {
							if (index % 2 === 0) {
								return renderAspectArticles(aspect, index, onViewArticle)
							} else return null
						})
					}
				</div>
				<div className="resource-summaries">
					{
						aspectsToRender.map((aspect, index) => {
							if (index % 2 === 1) {
								return renderAspectArticles(aspect, index, onViewArticle)
							} else return null
						})
					}
					{renderSectionArticles(section, onViewArticle)}
				</div>
			</>
		)
	} else {
		return (
			<div className="resource-summaries -single">
				{renderSectionArticles(section, onViewArticle)}
			</div>
		)
	}
}

export default ReportSectionDetail
