import { AppState } from '@redux-offline/redux-offline/lib/types'
import { RootStoreState } from 'modules/root'

export function selectOfflineOutboxQueueLength(state: RootStoreState): number {
	const appState = state as unknown as AppState
	return appState.offline.outbox.length
}

export function selectOnline(state: RootStoreState): boolean {
	const appState = state as unknown as AppState
	return appState.offline.online
}
