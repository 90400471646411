import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectSurvey } from 'modules/participant/selectors'

interface SurveySectionParams {
	section: string
}

const SurveySection: React.FC = function(props) {
	const survey = useSelector(selectSurvey)
	const params = useParams<SurveySectionParams>()

	if (!survey) {
		throw new Error('No survey')
	}

	const sectionIndex = parseInt(params.section, 10)
	const section = survey.sections[sectionIndex]

	if (!section) {
		throw new Error('No section')
	}

	const question = section.questions[0]
	if (!question) {
		throw new Error('No questions in section')
	}

	return (
		<Redirect to={`${sectionIndex}/${question.id}`} />
	)
}

export default SurveySection
