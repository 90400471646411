import React from 'react'
import ReactQuill, { ReactQuillProps } from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

interface Props extends ReactQuillProps {
	enabled?: boolean
}

type ReactQuillOnChange = ReactQuillProps['onChange']

const MyReactQuill: React.FC<Props> = function(props) {
	const newProps = { ...props }
	if (!newProps.modules && !newProps.formats) {
		newProps.modules = {
			toolbar: [
				['bold', 'italic', 'link'],
				[{ 'header': 2 }, 'blockquote', { 'list': 'bullet' }, { 'list': 'ordered' }],
			],
		}
		newProps.formats = ['header', 'bold', 'italic', 'link', 'blockquote', 'list']
	}

	const handleOnChange: ReactQuillOnChange = (content, delta, sources, editor) => {
		const html = editor.getHTML()
		/* Check that we don't enter a state update loop, that we have seen happen */
		if (html !== props.value) {
			if (props.onChange) {
				props.onChange(content, delta, sources, editor)
			}
		}
	}

	return (
		<ReactQuill {...newProps} onChange={handleOnChange} />
	)
}

export default MyReactQuill
