import React, { ReactElement } from 'react'
import { Switch, Route, useParams, useRouteMatch, Redirect } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { actionSetParticipation, actionSetParticipations } from 'modules/participant/actions'
import ParticipantSurveyRouter from '../survey/components/ParticipantSurveyRouter'
import ParticipantReportRouter from '../report/components/ParticipantReportRouter'
import { getAuthConfig } from 'modules/auth'
import { Api, FindParticipationInfo200Response } from 'typescript-fetch-api'
import { programmaticLogin, LoginIntent } from 'modules/auth/actions'
import LoadingScreen from 'modules/common/components/LoadingScreen'
import ParticipantErrorBoundary from './ParticipantErrorBoundary'
import { usePromiseEffect, useCallApi } from 'modules/common/hooks'
import ErrorScreen from 'modules/common/components/ErrorScreen'
import ParticipantHome from './ParticipantHome'
import { selectParticipation } from '../selectors'
import { selectCurrentMembership } from 'modules/admin/selectors'
import InvalidParticipantToken from './InvalidParticipantToken'

export default function ParticipantRouter() {
	const { path } = useRouteMatch()
	const participation = useSelector(selectParticipation)

	return (
		<ParticipantErrorBoundary>
			<Switch>
				{participation && <Route path={`${path}/survey`} component={ParticipantSurveyRouter} />}
				{participation && <Route path={`${path}/report`} component={ParticipantReportRouter} />}
				<Route
					path={`${path}/:token`} 
					render={props => (
						<ParticipantTokenHandler
							token={props.match.params.token}
							render={participations => {
								if (participations.length === 1) {
									return <Redirect to="/p/survey" />
								} else {
									return <ParticipantHome />
								}
							}}
						/>
					)} />
				<Route path={`${path}`}>
					<ParticipantTokenHandler render={() => <ParticipantHome />} />
				</Route>
			</Switch>
		</ParticipantErrorBoundary>
	)
}

/**
 * Loads the participation from a token in the URL.
 */
function ParticipantTokenHandler(props: { token?: string; render: (participations: Api.ParticipationInfo[]) => ReactElement }) {
	const params = useParams<{ token?: string }>()
	const token = params.token
	const dispatch = useDispatch()
	const callApi = useCallApi()

	const currentMembership = useSelector(selectCurrentMembership)

	const loginResult = usePromiseEffect(() => async() => {
		let response: FindParticipationInfo200Response | undefined = undefined

		if (currentMembership) {
			/* Check if we can use our current admin session to access this participation */
			try {
				response = await callApi(api => api.participationApi.findParticipationInfo(token))
			} catch (error) {
				/* Ignore, we will just start a new session */
			}
		}

		/* Login if not already logged in to the admin */
		if (!response) {
			if (token) {
				const authConfig = getAuthConfig()
				const accessToken = await callApi(api => api.authApi.signIn({
					grant_type: Api.SignInRequest.GrantTypeEnum.Token, 
					client_id: authConfig.clientId, 
					client_secret: authConfig.clientSecret, 
					password: token,
				}))
				dispatch(programmaticLogin({
					accessToken,
					intent: LoginIntent.PARTICIPATION,
					rememberMe: false,
				}))

				response = await callApi(api => api.participationApi.findParticipationInfo(token))
			} else {
				/* If user is not logged in as admin, and comes here without a token, we can still find participations */
				response = await callApi(api => api.participationApi.findParticipationInfo(undefined))
			}
		}

		dispatch(actionSetParticipations(response.participations))
		if (response.participations.length === 1) {
			dispatch(actionSetParticipation(response.participations[0]))
		}
		return response.participations
	}, [callApi, currentMembership, dispatch, token])

	if (loginResult.result) {
		return props.render(loginResult.result)
	} else if (loginResult.error && loginResult.error.error && loginResult.error.error === 'invalid_grant') {
		return <InvalidParticipantToken reason='invalid' />
	} else if (loginResult.error && loginResult.error.error && loginResult.error.error === 'participation_complete') {
		return <InvalidParticipantToken reason='complete' />
	} else if (loginResult.error) {
		return <ErrorScreen error={loginResult.error} />
	} else {
		return <LoadingScreen />
	}
}
