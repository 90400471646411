import React, { useState } from 'react'
import { Formalities, useController } from 'formalities'
import Table, { HeaderItem, Row } from 'modules/common/components/Table'
import Popover, { ArrowContainer } from 'react-tiny-popover'
import { TableAction } from 'modules/admin/types'

interface Filters {
	filters: TableFilter[]
}

export interface TableFilter {
	name: string
}

interface Props {
	// to get actions for the table, no itemId is required, for individual participant actions include id
	actions?: (itemId?: string) => TableAction[] | undefined
	filters?: TableFilter[]
	headerItems: HeaderItem[]
	rows: Row[]
	loadMore?: () => void 
	onSearch: (searchInput: string | undefined, sortBy: HeaderItem | undefined, filter: TableFilter[] | undefined) => void
}

const ParticipantsTable: React.FC<Props> = (props) => {
	const [searchTerm, setSearchTerm] = useState('')
	const [isFilterPopoverOpen, setFilterPopoverOpen] = useState(false)
	const [isActionsPopoverOpen, setActionsPopoverOpen] = useState(false)
	const selectedParticipantsController = useController<string[] | undefined>(undefined)
	const selectedParticipants = selectedParticipantsController.snapshot().value
	const filtersController = useController<Filters>({ filters: [] })
	const [sort, setSort] = useState<HeaderItem | undefined>()

	function onSortParticipants(sortBy: HeaderItem) {
		setSort(sortBy)
		props.onSearch(searchTerm ? searchTerm : undefined, sortBy, filtersController.snapshot().value.filters)
	}

	function onSearch(evt: React.ChangeEvent<HTMLInputElement>) {
		setSearchTerm(evt.target.value)
		props.onSearch(evt.target.value, sort, filtersController.snapshot().value.filters)
	}

	function onSelectOption(selectAction: TableAction, actionIndex: number) {
		const element = document.getElementById('selectItem' + actionIndex) as HTMLSelectElement
		const selectedValue = element ? element.value : undefined
		
		if (selectedValue && selectAction.onSelectAction && selectedParticipants) {
			selectAction.onSelectAction(selectedValue, selectedParticipants)
			setActionsPopoverOpen(false)
			selectedParticipantsController.snapshot().setValue(undefined)
		}
	}

	filtersController.addChangeListener(value => {
		props.onSearch(searchTerm, sort, value.filters)
	})

	return (
		<div className="edit-participants">
			<div className="table-header">
				<div className="form-input -search">
					<input type="text" className="field -small" placeholder="Search participants…" 
						value={searchTerm} 
						onChange={onSearch}
					/>
				</div>
				<div className="button-group">
					{props.actions && props.actions() !== undefined && (
						selectedParticipants && selectedParticipants?.length > 0 ?
							<Popover 
								isOpen={isActionsPopoverOpen}
								position={'bottom'}
								content={({ position, targetRect, popoverRect }) => (
									<ArrowContainer
										position={position}
										targetRect={targetRect}
										popoverRect={popoverRect}
										arrowColor={'white'}
										arrowSize={10}
										arrowStyle={{ opacity: 1 }}
									>
										<div className="popup-menu">
											<ul className="menuitems">
												{props.actions!()!.map((action, actionIndex) => {
													if (action.onSelectAction) {
														return (
															<li key={actionIndex} className="menuitem -subheading">
																<h4 className="ui-heading -small">{action.name}</h4>
																<div className="buttoned-field">
																	<div className="form-input -select">
																		<select className="select -small" id={'selectItem' + actionIndex}>
																			{action.selectableItems?.map((item, itemIndex) => (
																				<option key={itemIndex} value={item}>{item}</option>
																			))}
																		</select>
																	</div>
																	<button className="button-link -action -small" onClick={() => onSelectOption(action, actionIndex)}>
																		{action.selectButtonTitle}
																	</button>
																</div>
															</li>
														)
													} else if (action.onAction) {
														return (
															<li key={actionIndex} className="menuitem"><a href="/" onClick={(e) => {
																e.preventDefault()
																action.onAction!(selectedParticipants)
															}} className="link">{action.name}</a></li>
														)
													} else return null
												},
												)}
											</ul>
										</div>
									</ArrowContainer>
								)}
								onClickOutside={() => setActionsPopoverOpen(false)}
							>
								<button className="icon-text -actions" onClick={() => isActionsPopoverOpen ? setActionsPopoverOpen(false) : setActionsPopoverOpen(true)}>Actions</button>
							</Popover>
							:
							<button className="icon-text -actions" disabled>Actions</button>
					)}
					{props.filters && props.filters.length > 0 ? 
						<Popover 
							isOpen={isFilterPopoverOpen}
							position={'bottom'}
							content={({ position, targetRect, popoverRect }) => (
								<ArrowContainer
									position={position}
									targetRect={targetRect}
									popoverRect={popoverRect}
									arrowColor={'white'}
									arrowSize={10}
									arrowStyle={{ opacity: 1 }}
								>
									<div className="popup-menu">
										<h2 className="ui-heading -dark">Filter by...</h2>
										<ul className="option-inputs -small">
											{props.filters!.map((filter, filterIndex) => (
												<li key={filterIndex} className="option -checkbox">
													<label className="label">
														<Formalities.MultiCheckable 
															type="checkbox" 
															checkedValue={filter}
															controller={filtersController} 
															prop="filters"
															className="checkbox"
														/><span className="substitute"></span>
														{filter.name}
													</label>
												</li>
											))}
										</ul>
									</div>
								</ArrowContainer>
							)}
							onClickOutside={() => setFilterPopoverOpen(false)}
						>
							<button className="icon-text -filter" onClick={() => isFilterPopoverOpen ? setFilterPopoverOpen(false) : setFilterPopoverOpen(true)}>Filter</button>
						</Popover>
						:
						<button className="icon-text -filter" disabled>Filter</button>
					}
				</div>
			</div>
			<Table 
				individualSelect={true}
				headerItems={props.headerItems}
				rows={props.rows}
				onSort={onSortParticipants}
				actions={props.actions}
				loadMore={props.loadMore}
				controller={selectedParticipantsController}
				prop="this"
			/>
		</div>
	)
}

export default ParticipantsTable
