import * as React from 'react'
import { QuestionProps } from './types'
import { useController, Formalities } from 'formalities'
import { createScaleOptions } from '../functions'
import QuestionScreen from './QuestionScreen'
import QuestionHeader from './QuestionHeader'
import { AnswerRequest, ScaleQuestion } from 'typescript-fetch-api'
import { useDisableKeyboardNavigationForRadios, useKeyboardNavigation } from '../hooks'
import { uniqueToastError } from 'modules/common/functions'

interface FormState {
	answer: number
	otherTitle: string
	notApplicable: boolean
}
	
export default function QuestionScale(props: QuestionProps<ScaleQuestion>) {
	const { section, question, questionIndex } = props

	useKeyboardNavigation(onPrev, onNext)
	useDisableKeyboardNavigationForRadios()
	
	const controller = useController<FormState>({
		answer: question.answerIndex === undefined ? -1 : question.answerIndex,
		otherTitle: question.otherTitle ? question.otherTitle : '',
		notApplicable: question.notApplicableOption && question.answerNotApplicable ? true : false,
	})
	const form = controller.snapshot().value
	controller.addChangeListener(save)
	
	async function save(form: FormState) {
		const answerReq: AnswerRequest = {
			questionId: question.id,
			intValue: form.answer === -1 ? undefined : form.answer,
		}

		if (question.requiresOtherTitle) {
			answerReq.otherTitle = form.otherTitle
			if (!form.otherTitle) {
				answerReq.intValue = undefined
			}
		}

		if (question.notApplicableOption) {
			answerReq.notApplicable = !!form.notApplicable

			if (form.notApplicable) {
				answerReq.intValue = undefined
				answerReq.otherTitle = undefined
			}
		}

		if (answerReq.intValue === question.answerIndex && answerReq.notApplicable === question.notApplicableOption && answerReq.otherTitle === question.otherTitle) {
			return
		}
		
		const saved = await props.onAnswerQuestion(answerReq)
		if (!saved) {
			controller.snapshot('answer').setValue(question.answerIndex === undefined ? -1 : question.answerIndex)
		}
	}

	function onNext(evt?: React.FormEvent) {
		evt && evt.preventDefault()

		const form = controller.snapshot().value
		if (!question.optional) {
			if (form.notApplicable === true) {
				props.onNext()
				return true
			}
			if (form.answer === -1) {
				uniqueToastError('Please choose an answer before continuing')
				return false
			} else if (question.requiresOtherTitle && !form.otherTitle) {
				uniqueToastError('Please specify the ‘other’ option.')
				return false
			}
		}
		props.onNext()
	}

	function incomplete() {
		if (question.notApplicableOption && question.answerNotApplicable) {
			return false
		}

		if (question.requiresOtherTitle) {
			/* If we haven't given an otherTitle and we're optional, then we're not incomplete */
			if (!question.otherTitle && question.optional) {
				return false
			}

			/* If we've given the otherTitle and an answer, then we're not incomplete */
			if (question.otherTitle && question.answerIndex !== undefined) {
				return false
			}
		} else if (question.optional) {
			return false
		} else if (question.answerIndex !== undefined) {
			return false
		}

		return true
	}

	function onPrev(evt?: React.MouseEvent) {
		evt && evt.preventDefault()
		props.onPrev()
	}

	function onExit(evt: React.MouseEvent) {
		evt.preventDefault()
		props.onExit()
	}

	return (
		<QuestionScreen section={section} question={question}>
			<div className="survey-question">
				<QuestionHeader question={question} questionIndex={questionIndex} />
				{
					question.notApplicableOption &&
					<div className="option-inputs -small -notapplicable">
						<div className="option -checkbox">
							<label className="label">
								<Formalities.Checkable type="checkbox" controller={controller} prop="notApplicable" className="checkbox" checkedValue={true} uncheckedValue={false} />
								<span className="substitute"></span>
								{question.notApplicableOption}
							</label>
						</div>
					</div>
				}
				{
					question.requiresOtherTitle && (
						<div className="form-field -adjacent">
							<label className="label">Please specify:</label>
							<div className="form-input -text">
								<Formalities.Text controller={controller} prop="otherTitle" className="field" />
							</div>
						</div>
					)
				}
				{(!question.requiresOtherTitle || controller.snapshot().value.otherTitle) &&
				<div className="form-row -scale">
					<ul className={'option-inputs -scale' + (form.notApplicable ? ' -disabled' : '')}>
						{
							question.min !== undefined && question.max !== undefined && createScaleOptions(question.min, question.max).map((option, optionIndex) => (
								<li className="option -radio" key={optionIndex}>
									<label className="label">
										<Formalities.Checkable type="radio" className="radio" controller={controller} prop="answer" checkedValue={option} disabled={form.notApplicable}/>
										<span className="substitute"></span>
									</label>
								</li>
							))
						}
					</ul>
					<div className={'scale-key labels-' + question.labels.length + (form.notApplicable ? ' -disabled' : '')}>
						{
							question.labels && question.labels.map((option, optionIndex) => (
								<div className="key" key={optionIndex}>{option}</div>
							))
						}
					</div>
				</div>
				}
					
				<div className="footer-actions">
					<div className="button-group">
						<a href="/" className="button-link -secondary -back" onClick={onPrev}>Back</a>
						<a href="/" className="button-link -text" onClick={onExit}>Exit survey</a>
					</div>
					<div className="button-group -right">
						<input type="submit" className={'button-link -action -next ' + (!incomplete() ? '' : '-disabled')} value="Next" onClick={onNext} />
					</div>
				</div>

				<div className="keyboard-nav">
					<p className="option">You can also use your <span className="key">&larr;</span> <span className="key">&rarr;</span> arrow keys to navigate forward &amp; back</p>
				</div>
			</div>
		</QuestionScreen>
	)
}
