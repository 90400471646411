import React, { useState } from 'react'
import { Api } from 'typescript-fetch-api'
import { Formalities, useController, OptionTypeObject } from 'formalities'
import produce from 'immer'

interface Props {
	reports: Api.GetReports200Response
	request?: Api.ReportOptions
	currentSurveyId: string
	currentSurveyTemplateId?: string
	setRequest: (newRequest: Api.ReportOptions | undefined) => void
}

const ReportCombination: React.FC<Props> = (props) => {
	const { reports, request, currentSurveyId, currentSurveyTemplateId } = props
	const [availableCombinationSurveys, setAvailableCombinationSurveys] = useState<OptionTypeObject<string>[]>()
	const selectedCombinationSurveysController = useController<string[] | undefined>(request?.combineWithSurveys)

	React.useEffect(() => {
		const availableCombinationSurveys = reports.reports.filter((survey) => 
			survey.survey.template && survey.survey.template.id === currentSurveyTemplateId && survey.survey.id !== currentSurveyId,
		)
		setAvailableCombinationSurveys(
			availableCombinationSurveys.map((survey) => ({
				value: survey.survey.id,
				text: survey.survey.name,
			})),
		)
	}, [reports, currentSurveyId, currentSurveyTemplateId])

	function onClearAll(evt: React.MouseEvent) {
		evt.preventDefault()

		selectedCombinationSurveysController.snapshot().setValue([])
	}

	function onCombine(evt: React.MouseEvent) {
		evt.preventDefault()

		const surveyIds = selectedCombinationSurveysController.snapshot().value

		const newRequest = produce(request, draft => {
			if (!draft) {
				draft = {}
			}
			draft.combineWithSurveys = surveyIds
			return draft
		})
		props.setRequest(newRequest)
	}

	return (
		<div className="popup-menu -filters">
			<h2 className="ui-heading -dark">Combine with...</h2>
			<div className="filter-options">
				<ul className="option-inputs -small">
					{availableCombinationSurveys && availableCombinationSurveys.map((s, index) => (
						<li className="option -checkbox" key={'combine-survey-' + index}>
							<label className="label">
								<Formalities.MultiCheckable controller={selectedCombinationSurveysController} prop="this" checkedValue={s.value} className="checkbox" type="checkbox" />
								<span className="substitute"></span>
								{s.text}
							</label>
						</li>
					))}
				</ul>
			</div>
			<div className="footer-actions -borderless">
				<div className="button-group -split">
					<button type="button" className="button-link -small -secondary" onClick={onClearAll}>Clear all</button>
					<input type="submit" className="button-link -small -action" value="Combine" onClick={onCombine} />
				</div>
			</div>
		</div>
	)
}

export default ReportCombination