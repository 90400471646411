import * as React from 'react'
import Screen from 'modules/common/components/Screen'
import { Link } from 'react-router-dom'

interface Props {
	reason: 'invalid' | 'complete'
}

function InvalidParticipantToken(props: Props) {
	const { reason } = props

	switch (reason) {
		case 'invalid':
			return (
				<Screen title="Invalid invitation" includePageHeader={false} class="">
					<div className="body-text">
						<h2>Invalid invitation</h2>
						<p>The invitation link that you’ve clicked is invalid or has expired.</p>
						<p>Please contact the survey organiser in your invitation email to request a new invitation.</p>
					</div>
				</Screen>
			)
		case 'complete':
			return (
				<Screen title="Invalid invitation" includePageHeader={false} class="">
					<div className="body-text">
						<h2>Survey completed</h2>
						<p>The invitation link that you’ve clicked has already been used to complete the survey.</p>
						<p>Please <Link to="/auth/sign-in">sign in to your Umbrella account</Link> to view your report.</p>
					</div>
				</Screen>
			)
	}
}

export default InvalidParticipantToken
