import React from 'react'
import BarChart from 'modules/common/components/BarChart'
import { Api } from 'typescript-fetch-api'
import { jsonToCSV } from 'react-papaparse'
import download from 'downloadjs'
// import html2canvas from 'html2canfast'

interface Props {
	area: Api.SurveyReportAspectsArea
	report: Api.SurveyReport
}

const AspectsReportArea: React.FC<Props> = function(props) {
	const { area, report } = props
	const ref = React.createRef<HTMLDivElement>()

	// function onSaveImage() {
	// 	html2canvas(ref.current!.parentElement!, {
	// 		backgroundColor: '#fff',
	// 		logging: false,
	// 		scrollX: 0,
	// 		/* https://github.com/niklasvh/html2canvas/issues/1878#issuecomment-511678281 */
	// 		scrollY: -window.scrollY,
	// 		ignoreElements: element => typeof element.className === 'string' ? element.className.indexOf('_no-print') !== -1 : false,
	// 	}).then(canvas => {
	// 		const png = canvas.toDataURL('image/png')
	// 		download(png, `${area.name}.png`, 'image/png')
	// 		// const img = document.createElement('img')
	// 		// img.src = png
	// 		// img.style.width = '100%'
	// 		// document.body.appendChild(img)
	// 	})
	// }

	function onDownloadData(evt: React.MouseEvent) {
		evt.preventDefault()

		const rows: { [name: string]: unknown }[] = []

		for (const aspect of area.series[0].aspects) {
			const row: { [name: string]: unknown } = {
				Aspect: aspect.name,
			}
			if (area.minLabel) {
				row[area.minLabel] = aspect.minRatio
			}
			if (area.midLabel) {
				row[area.midLabel] = aspect.midRatio
			}
			if (area.maxLabel) {
				row[area.maxLabel] = aspect.maxRatio
			}
			rows.push(row)
		}
		for (const series of area.series.slice(1)) {
			let i = 0
			for (const aspect of series.aspects) {
				const row = rows[i]
				if (area.minLabel) {
					row[series.name + ' ' + area.minLabel] = aspect.minRatio
				}
				if (area.midLabel) {
					row[series.name + ' ' + area.midLabel] = aspect.midRatio
				}
				if (area.maxLabel) {
					row[series.name + ' ' + area.maxLabel] = aspect.maxRatio
				}
				i++
			}
		}

		const csv = jsonToCSV(rows)
		download(csv, `${area.name} for ${report.survey.name}.csv`, 'text/csv')
	}

	return (
		<div className="data" ref={ref}>
			<div className="barchart-collection">
				<div className="header">
					<div className="chart-key">
						<ul className="values">
							{area.minLabel && <li className="value -poor"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>{area.minLabel}</li>}
							{area.midLabel && <li className="value -good"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>{area.midLabel}</li>}
							{area.maxLabel && <li className="value -great"><svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" /></svg>{area.maxLabel}</li>}
						</ul>
					</div>
					<div className="options _no-print">
						<button className="icon-text -smallicon -download" onClick={onDownloadData}>Data</button>
						{/* <button className="icon-text -smallicon -chart" onClick={onSaveImage}>Save image</button> */}
					</div>
				</div>
				<div className="barcharts">
					<div className="intervals">
						<div className="area">
							<div className="interval"></div>
							<div className="interval"></div>
							<div className="interval"></div>
							<div className="interval"></div>
							<div className="interval"></div>
							<div className="interval"></div>
						</div>
					</div>
					{area.series[0].aspects.map((aspect, index) => (
						<div key={`${index}`} className={area.series.slice(1).length > 0 ? 'chart -comparison' : 'chart'}>
							<BarChart 
								label={aspect.longName} 
								poor={aspect.minRatio} 
								good={aspect.midRatio} 
								great={aspect.maxRatio} 
							/>
							{area.series.slice(1).map((series, seriesIndex) => {
								const otherAspect = series.aspects.find(a => a.name === aspect.name)
								if (otherAspect) {
									return (
										<BarChart poor={otherAspect.minRatio} good={otherAspect.midRatio} great={otherAspect.maxRatio} key={seriesIndex} />
									)
								} else {
									return null
								}
							})}
						</div>
					))
					}
					<div className="chart -axis">
						<h4 className="label body-text -small">% of respondents</h4>
						<div className="labels">
							<div className="label"><span className="value">0</span></div>
							<div className="label"><span className="value">20</span></div>
							<div className="label"><span className="value">40</span></div>
							<div className="label"><span className="value">60</span></div>
							<div className="label"><span className="value">80</span></div>
							<div className="label"><span className="value">100</span></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AspectsReportArea