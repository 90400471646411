import * as React from 'react'
import Screen from 'modules/common/components/Screen'
import { useController, Formalities } from 'formalities'
import { Api } from 'typescript-fetch-api'
import { useCurrentAccount } from 'modules/admin/hooks'
import { useApiEffect, useCallApi } from 'modules/common/hooks'
import LoadingInline from 'modules/common/components/LoadingInline'
import DatePicker from 'modules/common/components/DatePicker'
import TimePicker from 'modules/common/components/TimePicker'
import { useHistory } from 'react-router'
import { pathToSurvey } from '../navigation'
import moment from 'moment'
import ErrorInline from 'modules/common/components/ErrorInline'
import { apiErrorToMessage } from 'modules/api/functions'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'

interface Form {
	name?: string
	template?: Api.SurveyTemplate
	scheduling?: {
		openAtDate?: string
		openAtTime?: string
		closeAtDate?: string
		closeAtTime?: string
	}
}

export default function NewSurvey() {
	const controller = useController<Form>({})
	const formValue = controller.snapshot().value
	const schedulingController = controller.controller('scheduling')
	const account = useCurrentAccount()
	const getSurveyTemplates = useApiEffect(api => api.accountApi.getSurveyTemplates(account.id), [account])
	controller.addChangeListener((form) => onSurveyTypeChange(form))
	const history = useHistory()
	const callApi = useCallApi()

	function goBack(evt: React.FormEvent) {
		evt.preventDefault()
		history.goBack()
	}

	function onSurveyTypeChange(newForm: Form) {
		const newName = newForm.template?.name + ' ' + (newForm.scheduling?.openAtDate ? moment(newForm.scheduling?.openAtDate).format('MMMM YYYY') : moment().format('MMMM YYYY'))
		if (newForm.template && newForm.template !== formValue.template && newName !== newForm.name) {
			controller.snapshot().setValue({ 
				...newForm,
				name: newName,
			})
		}
	}

	async function onSubmit(evt: React.FormEvent) {
		evt.preventDefault()

		const form = controller.snapshot().value
		const schedulingValues: Api.Scheduling = {}

		if (form.scheduling && form.scheduling.openAtDate) {
			if (form.scheduling.openAtTime) {
				schedulingValues.openAt = moment(moment(form.scheduling.openAtDate).format('YYYY-MM-DD') + moment(form.scheduling.openAtTime).format('HH:mm'), 'YYYY-MM-DDHH:mm').toISOString()
			} else {
				schedulingValues.openAt = moment(moment(form.scheduling.openAtDate).format('YYYY-MM-DD'), 'YYYY-MM-DD').toISOString()
			}
		}

		if (form.scheduling && form.scheduling.closeAtDate) {
			if (form.scheduling.closeAtTime) {
				schedulingValues.closeAt = moment(moment(form.scheduling.closeAtDate).format('YYYY-MM-DD') + moment(form.scheduling.closeAtTime).format('HH:mm'), 'YYYY-MM-DDHH:mm').toISOString()
			} else {
				schedulingValues.closeAt = moment(moment(form.scheduling.closeAtDate).format('YYYY-MM-DD'), 'YYYY-MM-DD').toISOString()
			}
		}

		if (form.template) {
			const request: Api.CreateSurveyRequest = {
				name: form.name || '',
				templateId: form.template ? form.template.id : '',
				scheduling: schedulingValues,
			}
			try {
				const survey = await callApi(api => api.accountApi.createSurvey(account.id, request))
				history.replace(pathToSurvey(survey))
				uniqueToastSuccess('Created new survey')
			} catch (error) {
				uniqueToastError(`Failed to create new survey.\n${apiErrorToMessage(error)}`)
			}
		}
	}

	return (
		<Screen title="New survey" includePageHeader={false} breadcrumbText="surveys" breadcrumbLink="/admin/survey/your-surveys">
			<div className="content-header">
				<h1 className="headline-text">New survey</h1>
			</div>
			<div className="content-cols">
				<div className="body">
					<form onSubmit={onSubmit}>
						<div className="form-field -adjacent">
							<label className="label">Survey type</label>
							<div className="form-input -select">
								{getSurveyTemplates.result ?
									<Formalities.Select controller={controller} prop="template" className="select"
										options={[undefined, ...getSurveyTemplates.result]}
										display={t => t ? t.name : ''}
									/>
									: getSurveyTemplates.error ? <ErrorInline error={getSurveyTemplates.error} />
										: <LoadingInline />
								}
							</div>
						</div>
		
						<div className="form-field -adjacent">
							<label className="label">Survey name</label>
							<div className="form-input -text"><Formalities.Text controller={controller} prop="name" className="field" autoFocus={true} /></div>
						</div>
													
						{/* <div className="form-row">
							<h2 className="label">Participants</h2>
							<div className="fields">
								<div className="participant-summary body-text">
									<a href="/" className="button-link -small -secondary">Add participants</a>
								</div>
								<div className="description body-text">
									<p><strong className="inlinetitle">Note:</strong> Once saved, you will also have the option of sharing a link to the survey in addition to any participants added above.</p>
								</div>
							</div>
						</div> */}
						
						<div className="form-row">
							<h2 className="label -padtop">Scheduling</h2>
							<div className="fields">
								<div className="schedule-date">
									<label className="label">Start on</label>
									<DatePicker controller={schedulingController} prop="openAtDate"/>
									{schedulingController.snapshot().value?.openAtDate && <TimePicker controller={schedulingController} prop="openAtTime"/>}
									<p className="note">(optional)</p>
								</div>
								
								<div className="schedule-date">
									<label className="label">End on</label>
									<DatePicker controller={schedulingController} prop="closeAtDate" startDate={schedulingController.snapshot().value?.openAtDate}/>
									{schedulingController.snapshot().value?.closeAtDate && <TimePicker controller={schedulingController} prop="closeAtTime"/>}
									<p className="note">(optional)</p>
								</div>
							</div>
						</div>
						
						<div className="footer-actions">
							<div className="button-group -right">
								<button className="button-link -secondary" onClick={goBack}>Cancel</button>
								<input type="submit" className="button-link -action" value="Save survey" onClick={onSubmit}/>
							</div>
						</div>
					</form>
				</div>
				
				<aside className="aside">
					<div className="aside-text">
						<h4 className="icon-text -uppercase -smallicon -flag">Tip</h4>
						<div className="body-text -small">
							<p>If you change the survey type or add/edit participants or editable sections using the options provided, a copy of the survey will be saved so that you can return to complete it.</p>
						</div>
					</div>
				</aside>
			</div>
		</Screen>
	)
}