import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { App } from '@capacitor/App'

const AppUrlListener: React.FC = () => {
	const history = useHistory()
	useEffect(() => {
		App.addListener('appUrlOpen', (data: { url: string }) => {
			const slug = data.url.split('umbrella.org.nz/app').pop()
			if (slug) {
				history.push(slug)
			}
		})
	}, [history])
  
	return null
}

export default AppUrlListener
