import * as React from 'react'
import Screen from 'modules/common/components/Screen'
import { useController, Formalities } from 'formalities'
import { Api } from 'typescript-fetch-api'
import { useCallApi } from 'modules/common/hooks'
import { useHistory } from 'react-router'
import { apiErrorToMessage } from 'modules/api/functions'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'

const SuperAdminNewOrganisation: React.FC = () => {
	const callApi = useCallApi()
	const history = useHistory()
	const controller = useController<Api.CreateAccountRequest>({
		name: '',
	})

	async function onSubmit(evt: React.FormEvent) {
		evt.preventDefault()
		
		const request = controller.snapshot().value
		try {
			await callApi(api => api.adminApi.createAccount(request))
			history.replace('/admin/super/organisations')
			uniqueToastSuccess('New organisation created')
		} catch (error) {
			uniqueToastError(`Failed to create the new organisation.\n${apiErrorToMessage(error)}`)
		}
	}

	function goBack(evt: React.MouseEvent) {
		evt.preventDefault()
		history.replace('/admin/super/organisations')
	}

	return (
		<Screen title="New account" includePageHeader={false} breadcrumbText="surveys" breadcrumbLink="/admin/survey/your-surveys">
			<div className="content-header">
				<h1 className="headline-text">New organisation</h1>
			</div>
			<div className="content-cols">
				<div className="body">
					<form onSubmit={onSubmit}>
						<div className="form-field -adjacent">
							<label className="label">Organisation name</label>
							<div className="form-input -text"><Formalities.Text controller={controller} prop="name" className="field" autoFocus={true} /></div>
						</div>
						
						<div className="footer-actions">
							<div className="button-group -right">
								<button className="button-link -secondary" onClick={goBack}>Cancel</button>
								<input type="submit" className="button-link -action" value="Create organisation" onClick={onSubmit}/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</Screen>
	)
}

export default SuperAdminNewOrganisation
