import React from 'react'
import { Link } from 'react-router-dom'
import { Api } from 'typescript-fetch-api'

interface Props {
	topics: DeepReadonly<Api.TopicSummary[]>
	parent?: Api.TopicSummary
	titleOnly?: boolean
}

const TopicList: React.FC<Props> = (props) => {
	const { topics, parent } = props
	const teaserClassName = props.titleOnly ? 'content-teaser -small -minimal' : 'content-teaser -small'

	return (
		<>	
			{topics.map((topic, index) => {
				const topicUri = parent ? `${parent.uri}/${topic.slug}` : topic.uri
				return (
					<div key={topic.id} className={teaserClassName}>
						{
							props.titleOnly ? (
								<div className="content body-text">
									<h4 className="title icon-text -article"><Link to={`/knowledge-library/${topicUri}`}>{topic.name}</Link></h4>
								</div>
							) : (
								<>
									<div className="media -image">
										{
											topic.thumbnail ? (
												<div className="aspect">
													<img src={topic.thumbnail.url} className="image" alt={topic.thumbnail.title || ''} />
												</div>
											) : (
												<div className="aspect -empty">
													<span className="icon-unit -article"></span>
												</div>
											)
										}
									</div>
									<div className="content body-text">
										<h4 className="title"><Link to={`/knowledge-library/${topicUri}`} className="">{topic.name}</Link></h4>
										<p>{topic.description}</p>
									</div>
								</>
							)
						}
					</div>
				)
			})
			}
		</>
	)
}

export default TopicList
