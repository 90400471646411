import React, { useState } from 'react'
import Screen from 'modules/common/components/Screen'
import { useHistory } from 'react-router'
import Logo from './Logo'
import { useSelector } from 'react-redux'
import { selectSurvey, selectParticipation } from 'modules/participant/selectors'
import { useCallApi } from 'modules/common/hooks'
import { apiErrorToMessage } from 'modules/api/functions'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'
import { findLastQuestion } from '../functions'
import { useBeforeunload } from 'react-beforeunload'

export default function Complete() {
	const survey = useSelector(selectSurvey)
	const participation = useSelector(selectParticipation)
	const callApi = useCallApi()
	const [showLeavePageMessage, setShowLeavePageMessage] = useState(false)

	if (!survey) {
		throw new Error('No survey')
	}

	const history = useHistory()

	useBeforeunload((event: Event) => {
		setShowLeavePageMessage(true)
		event.preventDefault()
	})

	function goBack(evt: React.FormEvent) {
		evt.preventDefault()
		const lastQuestionUUID = survey ? findLastQuestion(survey).id : undefined
		const lastSectionIndex = survey ? survey.sections.length - 1 : undefined

		if (lastQuestionUUID && lastSectionIndex) {
			history.push(`../survey/${lastSectionIndex}/${lastQuestionUUID}`)
		} else {
			history.goBack()
		}
	}

	function completeSurvey(evt: React.FormEvent) {
		evt.preventDefault()
		callApi(api => api.participationApi.postComplete(participation!.participationId).then(() => {
			uniqueToastSuccess('Survey successfully submitted')
			if (participation?.registered) {
				history.push('/p/report')
			} else {
				history.push('/p/survey/register')
			}
		})).catch(e => {
			uniqueToastError(`Unable to complete survey: ${apiErrorToMessage(e)}`)
		})
	}

	return (
		<Screen title="Survey Complete" includePageHeader={false} class="-survey">
			<div className="survey-introduction">
				<Logo survey={survey} />
				{showLeavePageMessage &&
					<div className="form-row -message">
						<div className="message -error">
							<p className="icon-text -msgerror">Don’t forget to submit your survey</p>
						</div>
					</div>
				}
				<h1 className="headline-text -centered">Almost done…</h1>
				<div className="body body-text -large">
					<p>
						You have completed all the required questions and can now submit your survey. 
						If you would like to revise any of your answers you can still <a href="/" onClick={goBack}>go back</a> to 
						edit them, otherwise use the button below to finish.
					</p>
				</div>
				<div className="button-group -centered">
					<a href="/" className="button-link -secondary" onClick={goBack}>Go back</a>
					<a href="/" className="button-link -action" onClick={completeSurvey}>Submit survey</a>
				</div>
			</div>
		</Screen>
	)
}