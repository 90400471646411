import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectSurvey, selectParticipation } from 'modules/participant/selectors'
import { useParams, useHistory } from 'react-router'
import QuestionCheckboxes from './QuestionCheckboxes'
import QuestionRadios from './QuestionRadios'
import QuestionScale from './QuestionScale'
import QuestionMatrix from './QuestionMatrix'
import QuestionTextFree from './QuestionTextFree'
import QuestionTextSingle from './QuestionTextSingle'
import QuestionSelect from './QuestionSelect'
import { Api, QuestionTypeEnum, AnswerRequest } from 'typescript-fetch-api'
import { actionSetParticipantSurvey } from 'modules/participant/actions'
import { useCallApi } from 'modules/common/hooks'
import { apiErrorToMessage } from 'modules/api/functions'
import { uniqueToastError } from 'modules/common/functions'

interface SurveySectionParams {
	section: string
	question: string
}

const SurveyQuestion: React.FC = function() {
	const survey = useSelector(selectSurvey)
	const participation = useSelector(selectParticipation)
	const params = useParams<SurveySectionParams>()
	const dispatch = useDispatch()
	const callApi = useCallApi()

	if (!survey) {
		uniqueToastError('Error locating survey')
		throw new Error('no survey')
	}
	if (!participation) {
		uniqueToastError('Error locating participate')
		throw new Error('no participation')
	}

	const sectionIndex = parseInt(params.section, 10)
	const section = survey.sections[sectionIndex]

	if (!section) {
		uniqueToastError('Error locating section')
		throw new Error(`no section at index ${sectionIndex}`)
	}

	const questionIndex = section.questions.findIndex(q => q.id === params.question)
	if (questionIndex === -1) {
		uniqueToastError('Error locating question')
		throw new Error(`Question ${params.question} not found in section ${section.name} (${params.section})`)
	}

	const question = section.questions[questionIndex]

	const history = useHistory()

	function onNext() {
		const nextQuestion = section.questions[questionIndex + 1]
		if (nextQuestion) {
			history.push(nextQuestion.id)
		} else {
			const nextSection = survey!.sections[sectionIndex + 1]
			if (nextSection) {
				history.push(`../${sectionIndex + 1}`)
			} else {
				history.push('../complete')
			}
		}
	}

	function onPrev() {
		if (questionIndex > 0) {
			const prevQuestion = section.questions[questionIndex - 1]
			history.push(prevQuestion.id)
		} else if (sectionIndex > 0) {
			const prevSection = survey!.sections[sectionIndex - 1]
			const prevQuestion = prevSection.questions[prevSection.questions.length - 1]
			history.push(`../${sectionIndex - 1}/${prevQuestion.id}`)
		} else if (survey?.privacyStatementHtml) {
			history.push('../../survey/privacy')
		} else {
			history.push({
				pathname: '../../survey',
				state: { 
					from: window.location.pathname,
				},
			})
		}
	}

	function onExit() {
		history.push('../exiting')
	}

	async function updateSurvey() {
		const survey = await callApi(api => api.participationApi.getParticipationSurvey(participation!.participationId))
		dispatch(actionSetParticipantSurvey(survey))
	}
	
	async function answerQuestion(answer: AnswerRequest): Promise<boolean> {
		return callApi(api => api.participationApi.postAnswer(participation!.participationId, answer)).then(() => {
			updateSurvey()
			return true
		}).catch(e => {
			uniqueToastError(`Unable to save your answer. Please try answering the question again (${apiErrorToMessage(e)}).`)
			return false
		})
	}

	switch (question.type) {
		case QuestionTypeEnum.Multi: {
			switch (question.multichoiceType) {
				case Api.MultichoiceQuestion.MultichoiceTypeEnum.Multi:
					return <QuestionCheckboxes key={question.id} question={question} section={section} questionIndex={questionIndex} onNext={onNext} onPrev={onPrev} onExit={onExit} onAnswerQuestion={answerQuestion} /> 
				case Api.MultichoiceQuestion.MultichoiceTypeEnum.LongSingle:
					return <QuestionSelect key={question.id} question={question} section={section} questionIndex={questionIndex} onNext={onNext} onPrev={onPrev} onExit={onExit} onAnswerQuestion={answerQuestion} /> 
				case Api.MultichoiceQuestion.MultichoiceTypeEnum.Single:
					return <QuestionRadios key={question.id} question={question} section={section} questionIndex={questionIndex} onNext={onNext} onPrev={onPrev} onExit={onExit} onAnswerQuestion={answerQuestion} />
			}
			throw new Error(`Unsupported free multichoice question type: ${question.multichoiceType}`)
		}
		case QuestionTypeEnum.Scale:
			return <QuestionScale key={question.id} question={question} section={section} questionIndex={questionIndex} onNext={onNext} onPrev={onPrev} onExit={onExit} onAnswerQuestion={answerQuestion}/>
		case QuestionTypeEnum.Matrix:
			return <QuestionMatrix key={question.id} question={question} section={section} questionIndex={questionIndex} onNext={onNext} onPrev={onPrev} onExit={onExit} onAnswerQuestion={answerQuestion} />
		case QuestionTypeEnum.FreeText: {
			switch (question.freeTextType) {
				case Api.FreeTextQuestion.FreeTextTypeEnum.Long:
					return <QuestionTextFree key={question.id} question={question} section={section} questionIndex={questionIndex} onNext={onNext} onPrev={onPrev} onExit={onExit} onAnswerQuestion={answerQuestion} />
				case Api.FreeTextQuestion.FreeTextTypeEnum.Short:
					return <QuestionTextSingle key={question.id} question={question} section={section} questionIndex={questionIndex} onNext={onNext} onPrev={onPrev} onExit={onExit} onAnswerQuestion={answerQuestion} /> 
			}
			throw new Error(`Unsupported free text question type: ${question.freeTextType}`)
		}
	}

	throw new Error(`Unsupported question type: ${question.type}`)
}

export default SurveyQuestion
