import React from 'react'
import TopicList from './TopicList'
import { Api } from 'typescript-fetch-api'
import ArticleList from './ArticleList'

interface Props {
	topic: Api.Topic
}

const Topic: React.FC<Props> = (props) => {
	const { topic } = props

	return <>
		<h2 className="headline-text">{topic.name}</h2>
		<div className="body-text -large">
			<p>{topic.description}</p>
		</div>
		<div className="resource-summaries">
			<div className="summary">
				{topic.articles && 
				<>
					<h3 className="subheading-text">Related articles</h3>
					<div className="articles">
						<ArticleList articles={topic.articles}/>
					</div>
				</>
				}
				{topic.topics.length > 0 && 
					<>
						<h3 className="subheading-text">Subtopics</h3>
						<div className="articles">
							<TopicList topics={topic.topics} titleOnly={true} parent={topic} />
						</div>
					</>
				}
			</div>
		</div>
	</>
} 
	
export default Topic
