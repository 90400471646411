import React, { useCallback } from 'react'
import { withModal, ModalStyle, ModalComponentProps } from 'modules/common/modal-hoc'

interface Props extends ModalComponentProps {
	title: string
	body: string
}

export default withModal<Props>(
	function ReportPopup(props: Props) {
		
		const onClickGoBack = useCallback((function() {
			window.history.back()
		}), [])

		return (
			<div className="modal-layout">
				<div className="article-page">
					<div className="header">
						<div className="width-limit -article">
							<h2 className="icon-text subheading -article">Resource library</h2>
						</div>
					</div>
					<div className="body">
						<div className="width-limit -article">
							<div className="content body-text -hero">
								<h4 className="title -link" onClick={onClickGoBack}>{'< back'}</h4>
								<h1 className="headline-text">{props.title}</h1>
								<div className="body-text" dangerouslySetInnerHTML={{ __html: props.body }} />
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	},

	ModalStyle.Large,

)
