import React from 'react'
import Datetime from 'react-datetime'
import moment from 'moment'
import { Snapshot, useSnapshotController, wrapComponent } from 'formalities'

interface MyProps extends Snapshot<string | undefined> {
	startDate?: string
	disabled?: boolean
}

const DatePicker: React.FC<MyProps> = function(props) {
	const controller = useSnapshotController(props)
	function handleDateChange(date: moment.Moment | string) {
		if (typeof date === 'string') {
			/* The user entered something that was not parseable as a date */
			return
		}
		const time = moment(props.value).format('hh:mma')
		controller.snapshot().setValue(moment(date.format('YYYY-MM-DD') + time, 'YYYY-MM-DDhh:mma').toISOString())
	}

	function clearDateValue() {
		controller.snapshot().setValue(undefined)
	}

	const yesterday = moment().subtract(1, 'day')
	const valid = function(current: moment.Moment) {
		if (props.startDate) {
			return current.isAfter(moment(props.startDate)) && current.isAfter(yesterday)
		} else {
			return current.isAfter(yesterday)
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function renderDay(dayProps: any, currentDate: any) {
		const selectedDate = props.value ? moment(props.value) : undefined
		if (selectedDate && moment(currentDate).isSame(selectedDate, 'day')) {
			return <td {...dayProps} className="rdtDay -selected">{currentDate.date()}</td>
		}
		return <td {...dayProps}>{currentDate.date()}</td>
	}

	return (
		<div className="form-input -date">
			<Datetime
				className="field"
				renderDay={ renderDay }
				timeFormat={false}
				dateFormat={'YYYY-MM-DD'}
				inputProps={{ readOnly: true, placeholder: 'Select a date…', disabled: props.disabled ? true : false }}
				onChange={handleDateChange}
				closeOnSelect={true}
				initialViewDate={props.value ? moment(props.value) : props.startDate ? moment(props.startDate).add(1, 'day') : undefined}
				value={props.value ? moment(props.value).format('D MMM YYYY') : undefined}
				isValidDate={ valid }
			/>
			{props.value && !props.disabled &&
				<span className="clear" onClick={clearDateValue}></span>
			}
		</div>
	)
}

export default wrapComponent(DatePicker)
