import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { useApiEffect } from 'modules/common/hooks'
import LoadingScreen from 'modules/common/components/LoadingScreen'
import ParticipantErrorBoundary from './ParticipantErrorBoundary'
import ErrorScreen from 'modules/common/components/ErrorScreen'
import { useDispatch, useSelector } from 'react-redux'
import { logoutRequest } from 'modules/auth/actions'
import { selectLoggedIn } from 'modules/auth/selectors'

export default function ParticipantSurveyTokenRouter() {
	const dispatch = useDispatch()
	const loggedIn = useSelector(selectLoggedIn)

	/* Ensure we're not signed in when we use a survey token, as we want to create a new session */
	useEffect(function() {
		if (loggedIn) {
			dispatch(logoutRequest({}))
		}
	}, [dispatch, loggedIn])
	
	return (
		<Switch>
			<Route path="/s/:token" render={
				routeProps => (
					<ParticipantErrorBoundary>
						<ParticipantSurveyLoader token={routeProps.match.params.token} />
					</ParticipantErrorBoundary>
				)
			} />
		</Switch>
	)
}

function ParticipantSurveyLoader(props: { token: string }) {
	const startSurveyWithToken = useApiEffect(api => api.participationApi.startSurveyWithToken(props.token), [props.token])
	if (startSurveyWithToken.result) {
		return <Redirect to={`/p/${startSurveyWithToken.result.participationToken}`} />
	} else if (startSurveyWithToken.error) {
		return <ErrorScreen error={startSurveyWithToken.error} />
	} else {
		return <LoadingScreen />
	}
}
