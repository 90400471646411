import * as React from 'react'
import { withModal, ModalStyle, ModalComponentProps } from 'modules/common/modal-hoc'
import { useParams } from 'react-router'

interface OwnProps {
	title: string
	text?: string
	className?: string
	buttonAction: (id?: string) => void
	buttonText: string
}

type Props = ModalComponentProps & OwnProps

export default withModal(
	function BasicActionModal(props: Props) {
		const params = useParams<{ id: string }>()
		
		return (
			<div className="modal-layout">
				<div className={props.className ? props.className : 'article-page'}>
					<div className="body">
						<div className="width-limit -article">
							<div className="content body-text -hero">
								<h1 className="headline-text">{props.title}</h1>
								<p>{props.text}</p>
								<div className="button-group -right">
									<button className="button-link -secondary" onClick={props.onClose}>Cancel</button>
									<input type="submit" className="button-link -action" value={props.buttonText} onClick={() => props.buttonAction(params.id)}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	},

	ModalStyle.Narrow,
)