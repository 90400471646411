import React, { useCallback } from 'react'
import ReactPlayer from 'react-player'
import { Api } from 'typescript-fetch-api'
import ArticleList from './ArticleList'

interface Props {
	article: Api.Article
}

const Article: React.FC<Props> = (props) => {
	const { article } = props
	
	const onClickGoBack = useCallback((function() {
		window.history.back()
	}), [])

	return (
		<>
			<h4 className="title -link" onClick={onClickGoBack}>{'< back'}</h4>
			<h2 className="headline-text">{article.name}</h2>
			{renderArticleImage(article)}
			{article.contents.map(renderArticleContent)}
			{article.relatedArticles && article.relatedArticles?.length > 0 && 
			<>
				<div className="related-articles">
					<h3 className="subheading-text">Related articles</h3>
					<div className="articles">
						<ArticleList articles={article.relatedArticles}/>
					</div>
				</div>
			</>
			}
		</>
	)
}

export function renderArticleImage(article: Api.Article) {
	const { image, image2x } = article
	if (!image) {
		return null
	}
	
	const imageAspect = image.height / image.width
	return (
		<>
			<div className="media">
				<div className={imageAspect > 1 ? 'image -portrait' : 'image'}>
					<img 
						srcSet={image2x ? (image2x.url + ' 640w') : ''}
						src={image.url} 
						sizes="(min-width: 880px) 880w, 100%"
						width={image.width}
						height={image.height}
						alt={image.title || ''} 
					/>
				</div>
			</div>
		</>
	)
}

export function renderArticleContent(content: Api.ArticleContent, index: number) {
	if (content.contentType === 'Html') {
		return (
			<div className="body-text" key={index}> 
				{content.title && <h3>{content.title}</h3>}
				<div dangerouslySetInnerHTML={{ __html: content.html }}/>
			</div>
		)
	}
	if (content.contentType === 'Video') {
		return (
			<div className="media" key={index}>
				{content.title && <h3>{content.title}</h3>}
				<div className="player-wrapper">
					<ReactPlayer className="react-player" controls url={content.url} width="100%" height="100%" />
				</div>
			</div>
		)
	}
	if (content.contentType === 'Download') {
		return (
			<div className="body-text" key={index}>
				<p><a href={content.url} download>{content.title}</a></p>
			</div>
		)
	}
	if (content.contentType === 'Image') {
		const image = content.image
		const imageAspect = image.height / image.width
		return (
			<div className="media" key={index}>
				{content.title && <h3>{content.title}</h3>}
				<div className={imageAspect > 1 ? 'image -portrait' : 'image'}>
					<img
						src={image.url}
						width={image.width}
						height={image.height}
						alt={image.title || ''} 
					/>
				</div>
				{content.caption && <p className="body-text">{content.caption}</p>}
			</div>
		)
	}
	return undefined
}

export default Article
