import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Api } from 'typescript-fetch-api'
import { useHistory, useRouteMatch } from 'react-router'
import { dontScrollToTopLocation } from 'modules/routing'

interface Props {
	articles: Api.ArticleSummary[]
	parent?: Api.ArticleSummary
}

const ArticleList: React.FC<Props> = (props) => {
	const { articles, parent } = props

	const history = useHistory()

	const loadInModal = useCallback(function(evt: React.MouseEvent, articleID: string) {
		evt.preventDefault()
		history.push(dontScrollToTopLocation(`../article/${articleID}`))
	}, [history])

	const reportArticleMatch = useRouteMatch('/*/report/section/:index/article/:articleId')

	return (
		<>
			{articles.map((article) => {
				const articleUri = parent ? `${parent.uri}/${article.slug}` : article.uri

				return (
					<div key={article.id} className="content-teaser -small">
						<div className="media -image">
							{
								article.thumbnail ? (
									<div className="aspect">
										<img src={article.thumbnail.url} className="image" alt={article.thumbnail.title || ''} />
									</div>
								) : (
									<div className="aspect -empty">
										<span className="icon-unit -article"></span>
									</div>
								)
							}
						</div>
						<div className="content body-text">
							{reportArticleMatch 
								? <h4 className="title -link" onClick={(e) => loadInModal(e, article.id)}>{article.name}</h4>
								: <h4 className="title"><Link to={`/knowledge-library/${articleUri}`}>{article.name}</Link></h4>
							}
							<p>{article.teaser}</p>
						</div>
					</div> 
				)
			})
			}
		</>
	)
}

export default ArticleList

