import * as React from 'react'
import Screen from './Screen'

function LoadingScreen() {
	return (
		<Screen title="Loading…" includePageHeader={false} class="">
			<div className="loading-spinner"><p className="spinner">Loading…</p></div>
		</Screen>
	)
}

export default LoadingScreen
