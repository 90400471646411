import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import produce from 'immer'
import { Api } from 'typescript-fetch-api'
import { actionSetParticipantSurvey, actionSetParticipation, actionSetParticipations } from './actions'
import { loggedOut } from 'modules/auth/actions'

export type StoreState = DeepReadonly<MutableStoreState>

interface MutableStoreState {
	survey?: Api.ParticipationSurvey
	participation?: Api.ParticipationInfo
	participations?: Api.ParticipationInfo[]
}

const INITIAL_STATE: StoreState = {
	
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(actionSetParticipation, (state, p) => produce(state, draft => {
	draft.participation = p
}))

reducer.case(actionSetParticipantSurvey, (state, ps) => produce(state, draft => {
	draft.survey = ps
}))

reducer.case(actionSetParticipations, (state, p) => produce(state, draft => {
	draft.participations = p
}))

reducer.case(loggedOut, state => produce(state, draft => INITIAL_STATE))
