import React from 'react'
import Landing from './Landing'
import Complete from './Complete'
import Register from './Register'
import Exiting from './Exiting'
import Exit from './Exit'
import PrivacyStatement from './PrivacyStatement'
import QuestionRadios from './QuestionRadios'
import QuestionCheckboxes from './QuestionCheckboxes'
import QuestionSelect from './QuestionSelect'
import QuestionScale from './QuestionScale'
import QuestionTextSingle from './QuestionTextSingle'
import QuestionTextFree from './QuestionTextFree'
import SurveySection from './SurveySection'
import SurveyQuestion from './SurveyQuestion'
import { Switch, Route, useRouteMatch } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { selectParticipation } from 'modules/participant/selectors'
import { usePromiseEffect, useCallApi } from 'modules/common/hooks'
import LoadingScreen from 'modules/common/components/LoadingScreen'
import ErrorScreen from 'modules/common/components/ErrorScreen'
import { actionSetParticipantSurvey } from 'modules/participant/actions'

export default function ParticipantSurveyRouter() {
	const { path } = useRouteMatch()
	const participation = useSelector(selectParticipation)
	if (!participation) {
		throw new Error('No participation')
	}

	const dispatch = useDispatch()
	const callApi = useCallApi()
	
	const getSurvey = usePromiseEffect(() => async() => {
		const survey = await callApi(api => api.participationApi.getParticipationSurvey(participation.participationId))
		dispatch(actionSetParticipantSurvey(survey))
		return survey
	}, [callApi, dispatch, participation.participationId])

	if (getSurvey.error) {
		return <ErrorScreen error={getSurvey.error} />
	} else if (!getSurvey.result) {
		return <LoadingScreen />
	}

	return (
		<Switch>
			<Route 
				path={`${path}/privacy`} 
				component={PrivacyStatement}
			/>
			<Route 
				path={`${path}/complete`} 
				component={Complete}
			/>
			<Route 
				path={`${path}/register`} 
				component={Register}
			/>
			<Route 
				path={`${path}/exiting`} 
				component={Exiting}
			/>
			<Route 
				path={`${path}/exit`} 
				component={Exit}
			/>
			<Route 
				path={`${path}/question`} 
				component={QuestionRadios}
			/>
			<Route 
				path={`${path}/question-checkboxes`}
				component={QuestionCheckboxes}
			/>
			<Route 
				path={`${path}/question-select`}
				component={QuestionSelect}
			/>
			<Route 
				path={`${path}/question-scale`}
				component={QuestionScale}
			/>
			<Route 
				path={`${path}/question-text-single`}
				component={QuestionTextSingle}
			/>
			<Route 
				path={`${path}/question-text-free`}
				component={QuestionTextFree}
			/>
			<Route
				path={`${path}/:section/:question`}
				component={SurveyQuestion}
			/>
			<Route
				path={`${path}/:section`}
				component={SurveySection}
			/>
			<Route 
				path={`${path}`}
				exact 
				strict
				component={Landing}
			/>
		</Switch>
	)
}
