import * as React from 'react'
import Screen from 'modules/common/components/Screen'
import { useParams } from 'react-router'
import { Api } from 'typescript-fetch-api'
import { questionTypeToName } from 'modules/admin/functions'
import { useCallApi } from 'modules/common/hooks'
import * as _ from 'lodash'
import { Link } from 'react-router-dom'
import { pathToCustomSurveyQuestion, pathToSurveyEdit } from '../navigation'
import DraggableQuestionTable from './DraggableQuestionTable'
import ReactMarkdown from 'react-markdown'
import { uniqueToastError, uniqueToastSuccess } from 'modules/common/functions'

interface Props {
	survey: Api.SurveyDetails
	onChangeSurvey: () => void
}

const EditSection: React.FC<Props> = function(props) {
	const { survey } = props
	const params = useParams<{ sectionId: string }>()
	const section = survey.editableSections.find((s) => s.id === params.sectionId)
	if (!section) {
		throw new Error('No section')
	}
	const [openQuestions, setOpenQuestions] = React.useState<string[] | undefined>()
	const [addedQuestions, setAddedQuestions] = React.useState(section.questions)
	const [availableQuestions, setAvailableQuestions] = React.useState(section.availableQuestions)
	const callApi = useCallApi()

	function toggleOpenQuestion(evt: React.FormEvent, questionId: string) {
		evt.preventDefault()
		if (!openQuestions) {
			setOpenQuestions([questionId])
		} else {
			const questionIndex = openQuestions.indexOf(questionId)
			if (questionIndex !== -1) {
				const newArr = [...openQuestions]
				newArr.splice(questionIndex, 1)
				setOpenQuestions(newArr)
			} else {
				const newArr = [...openQuestions]
				newArr.push(questionId)
				setOpenQuestions(newArr)
			}
		}
	}

	function isOpenQuestion(questionId: string): boolean {
		const questionIndex = openQuestions?.indexOf(questionId)
		if (questionIndex === undefined || questionIndex === -1) {
			return false
		} else {
			return true
		}
	}

	function addQuestionToSurvey(evt: React.FormEvent, question: (Api.ScaleQuestion | Api.MultichoiceQuestion | Api.MatrixSubQuestion | Api.MatrixQuestion | Api.FreeTextQuestion)) {
		evt.preventDefault()
		setAddedQuestions([...addedQuestions, question])
		const questionIndex = availableQuestions.indexOf(question)
		if (questionIndex === -1) {
			return
		} else {
			const newArr = [...availableQuestions]
			newArr.splice(questionIndex, 1)
			setAvailableQuestions(newArr)
		}
	}

	function onRemoveQuestion(evt: React.FormEvent, question: (Api.ScaleQuestion | Api.MultichoiceQuestion | Api.MatrixSubQuestion | Api.MatrixQuestion | Api.FreeTextQuestion)) {
		evt.preventDefault()
		setAvailableQuestions([...availableQuestions, question])
		const questionIndex = addedQuestions.indexOf(question)
		if (questionIndex === -1) {
			return
		} else {
			const newArr = [...addedQuestions]
			newArr.splice(questionIndex, 1)
			setAddedQuestions(newArr)
		}
	}

	async function saveQuestions(evt: React.FormEvent) {
		evt.preventDefault()
		const req = addedQuestions.map(q => q.id)
		try {
			await callApi(api => api.surveyApi.setCustomQuestions(survey.id, section!.id, req))
			uniqueToastSuccess(`Successfully updated ${section!.title} questions`)
			props.onChangeSurvey()
		} catch (error) {
			uniqueToastError(`Failed to update ${section!.title} questions`)
		}
	}

	return (
		<Screen title="Edit" includePageHeader={false} breadcrumbText="edit survey" breadcrumbLink={pathToSurveyEdit(survey)}>
			<div className="content-header">
				<h1 className="headline-text">Editing section: {section.title}</h1>
			</div>
			<div className="content-cols">
				<div className="body">
					<div className="introduction body-text -large">
						<p>Select questions from the list below, or add your own new questions. You can select as many questions as you like, and once selected they can be re-ordered.</p>
					</div>
					<form>
						<DraggableQuestionTable 
							addedQuestions={addedQuestions}
							setAddedQuestions={setAddedQuestions}
							onChangeSurvey={props.onChangeSurvey} 
							onRemoveQuestion={onRemoveQuestion} 
							survey={survey} 
							section={section}
						/>
						<div className="question-table">
							<h2 className="subheading-text">Question library</h2>
							<table className="data-table">
								<thead>
									<tr>
										<th>Name</th>
										<th>Type</th>
										<th className="rightalign"></th>
									</tr>
								</thead>
								<tbody>
									{
										availableQuestions.map((question, index) => (
											<tr key={index}>
												<th scope="row" onClick={(e) => toggleOpenQuestion(e, question.id)}>
													<span className={'question' + (isOpenQuestion(question.id) ? ' -active' : question.custom ? ' -custom' : '')}>
														<ReactMarkdown
															children={question.title}
															components={{
																p: 'span',
															}}
														/>
													</span>
													{
														isOpenQuestion(question.id) &&
															(question.type === Api.QuestionTypeEnum.Multi ? renderMultiQuestion(question) : 
																question.type === Api.QuestionTypeEnum.FreeText ? renderFreeTextQuestion(question) : null)
													}
												</th>
												<td>{questionTypeToName(question.type)}</td>
												<td className="rightalign">
													{
														question.custom && !question.locked &&
														<Link className="icon-unit -edit" to={pathToCustomSurveyQuestion(survey, section.id, question.id)}>Edit</Link>
													}
													<button className="icon-unit -add" onClick={(e) => addQuestionToSurvey(e, question)}>Add</button>
												</td>
											</tr>
										))
									}
								</tbody>
							</table>
						</div>

						<div className="footer-actions -borderless">
							<div className="button-group -left">
								<Link to={pathToCustomSurveyQuestion(survey, section.id)} className="icon-text -add">Add new custom question</Link>
							</div>
							<div className="button-group -right">{
								_.isEqual(section.questions, addedQuestions) ? 
									<Link className="button-link -action" to={pathToSurveyEdit(survey)}>Done</Link> :
									<>
										<Link className="button-link -secondary" to={pathToSurveyEdit(survey)}>Discard changes</Link>
										<input type="submit" className="button-link -action" value="Save changes" onClick={saveQuestions} />
									</> 
							}
							</div>
						</div>
					</form>
				</div>

				<aside className="aside">
					<div className="aside-text">
						<h4 className="icon-text -uppercase -smallicon -help">About</h4>
						<div className="body-text -small">
							<p>Questions added here will be shown in a section above the default questions from the survey type you've selected. You can choose from some pre-defined questions, or create your own.</p>
						</div>
					</div>
				</aside>
			</div>
		</Screen>
	)
}

export function renderMultiQuestion(question: Api.MultichoiceQuestion) {
	return (
		<div className="answer">
			{
				<>
					{question.subtitle && <p className="subheading">{question.subtitle}</p>}
					<ul className={'options ' + (question.multichoiceType === Api.MultichoiceQuestion.MultichoiceTypeEnum.Single ? '-radios' : 
						question.multichoiceType === Api.MultichoiceQuestion.MultichoiceTypeEnum.Multi ? '-checkboxes' : '-select')}>
						{
							question.options.map((option, index) => (
								<li key={index}>{option}</li>
							))
						}
					</ul>
				</>
			}
		</div>
	)
}

export function renderFreeTextQuestion(question: Api.FreeTextQuestion) {
	return (
		<div className="answer">
			{
				<>
					{question.subtitle && <p className="subheading">{question.subtitle}</p>}
					{question.freeTextType === Api.FreeTextQuestion.FreeTextTypeEnum.Long ?
						<div className="options -textbox">This question allows for written answers in a text field</div> :
						<div className="options -textline">This question allows for written answers in a text field</div>}
				</>
			}
		</div>
	)
}

export default EditSection