import React from 'react'
import moment from 'moment'
import { Snapshot, useSnapshotController, wrapComponent } from 'formalities'
import TimeField from 'react-simple-timefield'

interface MyProps extends Snapshot<string | undefined> {
	disabled?: boolean
}

const TimePicker: React.FC<MyProps> = function(props) {
	const controller = useSnapshotController(props)

	function handleTimeChange(evt: React.ChangeEvent<HTMLSelectElement>, time: string) {
		const date = moment(props.value).format('YYYY-MM-DD')
		controller.snapshot().setValue(moment(date + moment(time, 'HH:mm').format('HH:mm'), 'YYYY-MM-DDHH:mm').toISOString())
	}

	return (
		<div className="form-input -time">
			<TimeField
				onChange={(event, value) => handleTimeChange(event, value)}
				value={props.value ? moment(props.value).format('HH:mm') : ''}
				input={<input type="text" className="field" disabled={props.disabled ? true : false}/>}
			/>
		</div>
	)
}

export default wrapComponent(TimePicker)
