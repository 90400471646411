import React from 'react'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router'
import { Link, NavLink } from 'react-router-dom'
import { useApiEffect } from 'modules/common/hooks'
import Screen from 'modules/common/components/Screen'
import Topic from './Topic'
import Article from './Article'
import TopicList from './TopicList'
import LoadingScreen from 'modules/common/components/LoadingScreen'
import ErrorScreen from 'modules/common/components/ErrorScreen'
import { Api } from 'typescript-fetch-api'
import ErrorInline from 'modules/common/components/ErrorInline'
import LoadingInline from 'modules/common/components/LoadingInline'
import { selectKnowledgeLibraryState } from '../selectors'
import { useSelector } from 'react-redux'
import ReportPopup from 'modules/participant/report/components/ReportPopup'

function isKnowledgeLibraryTopic(object: Api.Topic | Api.Article): object is Api.Topic {
	return (object as Api.Article).contents === undefined
}

const KnowledgeLibraryRouter: React.FC = () => {
	const { params: { uri } } = useRouteMatch<{ uri: string }>()

	const lookup = useApiEffect(api => api.knowledgeLibraryApi.findKnowledgeLibraryObject(uri), [uri])
	if (lookup.result) {
		const found = lookup.result
		if (isKnowledgeLibraryTopic(found)) {
			return <Topic topic={found} />
		} else {
			return <Article article={found} />
		}
	} else if (lookup.error) {
		return <ErrorInline error={lookup.error}/>
	} else {
		return <LoadingInline centred large/>
	}
}

const KnowledgeLibrary: React.FC = () => {
	const { path } = useRouteMatch()
	const history = useHistory()

	const knowledgeLibraryResult = useSelector(selectKnowledgeLibraryState)

	if (knowledgeLibraryResult.error) {
		return <ErrorScreen error={knowledgeLibraryResult.error}/> 
	} else if (!knowledgeLibraryResult.knowledgeLibrary) {
		return <LoadingScreen />
	}

	return (
		<Screen title="Knowledge Library">
			{
				knowledgeLibraryResult && knowledgeLibraryResult.knowledgeLibrary && (
					<>
						<div className="content-header">
							<h1 className="subheading-text icon-text -article subheading"><Link to={`${path}/`}>Knowledge Library</Link></h1>
						</div>
						<div className="knowledge-base">
							<div className="side-menu">
								<input className="trigger" type="checkbox" id="knowledge-menustate" />
								<label className="menutrigger icon-text -smallicon -right" htmlFor="knowledge-menustate">Browse by category</label>
								<ul className="menu">
									{
										knowledgeLibraryResult.knowledgeLibrary.topics.map(topic => 
											(
												<li className="menuitem" key={topic.id}>
													<NavLink
														className="link"
														activeClassName="-active"
														to={`${path}/${topic.uri}`}
													>
														{topic.name}
													</NavLink>
													{topic.topics.length > 0 &&
													<Route path={`${path}/${topic.uri}`}>
														<ul>
															{topic.topics.map(subtopic => (
																<li className="menuitem" key={subtopic.id}>
																	<NavLink
																		className="link"
																		activeClassName="-active"
																		to={`${path}/${topic.uri}/${subtopic.slug}`}>
																		{subtopic.name}
																	</NavLink>
																</li>
															))}
														</ul>
													</Route>
													}
												</li>
											),
										)
									}
								</ul>
							</div>
							<div className="content">
								<Switch>
									{knowledgeLibraryResult.knowledgeLibrary.supportArticle && (
										<Route path={`${path}/support`} render={(route) => <ReportPopup
											title='Need Support Now?'
											body={knowledgeLibraryResult.knowledgeLibrary!.supportArticle!}
											onClose={() => history.goBack()}
										/>} />
									)}
									<Route path={`${path}/:uri+`} component={KnowledgeLibraryRouter} />
									<Route path={`${path}/`} exact>
										{
											<>
												<h2 className="headline-text">Welcome to the Knowledge Library</h2>
												{knowledgeLibraryResult.knowledgeLibrary.supportArticle && (
													<div style={{ marginBottom: '1.5em' }}>
														<Link to={`${path}/support`} className="button-link -confirm">Need Support Now?</Link>
													</div>
												)}
												{knowledgeLibraryResult.knowledgeLibrary.introduction && (
													<>
														<div className="body-text -large" dangerouslySetInnerHTML={{ __html: knowledgeLibraryResult.knowledgeLibrary.introduction }} />
													</>
												)}
												{knowledgeLibraryResult.knowledgeLibrary.topics.length > 0 && 
												<div className="resource-summaries">
													<div className="summary">
														<h3 className="subheading-text">Topics</h3>
														<div className="articles">
															<TopicList topics={knowledgeLibraryResult.knowledgeLibrary.topics} />
														</div>
													</div>
												</div>
												}
											</>
										}
									</Route>
								</Switch>
							</div>
						</div>
					</>
				)
			}
		</Screen>
	)
}

export default KnowledgeLibrary
