import React from 'react'
import { ParticipationSurvey } from 'typescript-fetch-api'

interface Props {
	survey: DeepReadonly<ParticipationSurvey>
}

const Logo: React.FC<Props> = function({ survey }) {
	if (survey.account && survey.account.logo) {
		return (
			<div className="logo -survey">
				<div className="image">
					<div className="aspect" style={{ paddingTop: ((survey.account.logo.height / survey.account.logo.width) * 100) + '%' }}>
						<img src={survey.account.logo.url} alt={survey.account.name} />
					</div>
				</div>
			</div>
		)
	} else {
		return null
	}
}

export default Logo
